import * as React from "react";
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../../../../../components/shared/ErrorMessage";
import {
  UseGetOnboardingForTeacher,
  UsePostStage,
  UseUpdateOnboardingForTeacher,
} from "../../../../../../../hooks";

function New({ data }) {
  const postStage = UsePostStage();

  const { data: onboarding } = UseGetOnboardingForTeacher();
  const updateOnboarding = UseUpdateOnboardingForTeacher();
  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (d) => {
    postStage
      .mutateAsync({
        ...d,
        course: data?.info?.courseId,
      })
      .then(() => {
        if (!onboarding?.data?.clickSubmitStage) {
          updateOnboarding.mutateAsync({
            clickSubmitStage: true,
          });
        }
        data?.stage?.refetch();
        data?.setInfo(data?.defaultValues);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>New Stage</DialogTitle>
        <DialogContent>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                margin="dense"
                id="name"
                error={errors?.name}
                label="Name"
                fullWidth
                variant="standard"
              />
            )}
          />
          <ErrorMessage error={errors?.name?.message} />

          <Controller
            name="description"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                id="description"
                label="Description"
                error={errors?.description}
                fullWidth
                multiline
                variant="standard"
              />
            )}
          />
          <ErrorMessage error={errors?.description?.message} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </>
  );
}
export default New;
