import React, { useState } from "react";
import styles from "../login/login.module.scss";
import WhiteLogo from "../../../assets/logo/logo";
import axios from "axios";
import { useForm } from "react-hook-form";
import { UseRegister } from "../../../hooks";
import SuccessLogin from "./success";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { Link } from "react-router-dom";

export default function Signup() {
  const [isSuccess, setIsSuccess] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required().min(6),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password"), null], "Password must match!"),
    role: yup
      .string("Please select one role!")
      .required("Please select one role!")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const serverUrl = process.env.REACT_APP_API_URL;

  const onSubmit = (data) => {
    axios
      .post(`${serverUrl}/user/register`, {
        ...data,
      })
      .then((data) => {
        if (data.status === 201) {
          setIsSuccess(true);
        }
      })
      .catch((err) =>
        setError("responseError", { message: err?.response?.data?.message })
      );
  };

  return isSuccess ? (
    <SuccessLogin />
  ) : (
    <>
      <div className={styles.parentLogin}>
        <div className={styles.loginLeft}>
          <a className={styles.logo} href="https://codekaplan.com">
            <WhiteLogo />
          </a>
        </div>
        <div className={styles.loginRight}>
          <h1>Sign up</h1>
          <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.loginInput} mb-4`}>
              <input
                {...register("email")}
                className={styles.input}
                type="email"
                placeholder="Email"
              />
              {!errors?.responseError && (
                <ErrorMessage error={errors?.email?.message} />
              )}
              <ErrorMessage error={errors?.responseError?.message} />
            </div>

            <div className={`${styles.loginInput} mb-4`}>
              <input
                {...register("password")}
                className={styles.input}
                type="password"
                placeholder="Password"
              />
              <ErrorMessage error={errors?.password?.message} />
            </div>

            <div className={`${styles.loginInput} mb-3`}>
              <input
                {...register("passwordConfirm")}
                className={styles.input}
                type="password"
                placeholder="Confirm Password"
              />
              <ErrorMessage error={errors?.passwordConfirm?.message} />
            </div>

            <div style={{ marginBottom: "25px", width: "100%" }}>
              <div className={styles.labels}>
                <label>
                  Student
                  <input
                    {...register("role")}
                    value="student"
                    type="radio"
                    name="role"
                  />
                </label>

                {/* <label>
                  Parent
                  <input
                    {...register("role")}
                    value="parent"
                    type="radio"
                    name="role"
                  />
                </label> */}

                <label>
                  Teacher
                  <input
                    {...register("role")}
                    value="teacher"
                    type="radio"
                    name="role"
                  />
                </label>
              </div>

              <ErrorMessage error={errors?.role?.message} />
            </div>
            <button className={styles.loginBtn} type="submit">
              Sign Up
            </button>
          </form>

          <div className={`${styles.registerFooter} mt-2`}>
            <p>
              Already have login and password?{" "}
              <Link to="/login" className="text-primary">
                Login
              </Link>
            </p>

            <p>
              By signing up, you agree to Codekaplan’s
              <Link to="/terms"> Terms of Service </Link> and{" "}
              <Link to="/privacy"> Privacy Policy.</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
