import { useMemo } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { UseGetCourse, UseGetEventsByCourse } from "../../../../../hooks";
import Calendar from "./Calendar";

export default function Events() {
  const { id } = useParams();
  const { data: course } = UseGetCourse(id);
  const { data, refetch } = UseGetEventsByCourse(course?.data?._id);
  const events = useMemo(
    () =>
      data?.data?.map((eventCalendar) => {
        if (eventCalendar.repeating) {
          const end = moment(eventCalendar.endTime)
            .add(1, "day")
            .format("YYYY-MM-DD");
          const endRecur = moment(eventCalendar.endDate)
            .add(1, "day")
            .format("YYYY-MM-DD");
          return {
            title: eventCalendar.name,
            start: eventCalendar.startDate,
            end: end,
            description: eventCalendar.description,
            isNewBatch: eventCalendar.isNewBatch,
            location: eventCalendar.location,
            daysOfWeek: eventCalendar?.repeating?.repeatingDays,
            startRecur: eventCalendar.startDate,
            endRecur: endRecur,
            _id: eventCalendar._id,
          };
        } else {
          const end = moment(eventCalendar.endTime)
            .add(1, "day")
            .format("YYYY-MM-DD");

          return {
            title: eventCalendar.name,
            start: eventCalendar.startDate,
            end: end,
            description: eventCalendar.description,
            isNewBatch: eventCalendar.isNewBatch,
            location: eventCalendar.location,
            _id: eventCalendar._id,
          };
        }
      }),
    [data]
  );

  return (
    <>
      <Calendar id={course?.data?._id} events={events} refetch={refetch} />
    </>
  );
}
