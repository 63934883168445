import { useState } from "react";
import { Button } from "react-bootstrap";
// import { MediaUploaderVimeo } from "../../utils/MediaUploaderVimeo";
import LinearProgressWithLabel from "../../LinearProgress";
import axios from "axios";
import { createEmbedFunction } from "../../utils/createEmbedFunction";

export default function InputVideo({ setAction, data }) {
  const [progress, setProgress] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const spotlightr = process.env.REACT_APP_SPOTLIGHTR_VOO_KEY;

  function handleUpload(file) {
    if (file) {
      let formData = new FormData();
      formData.append("vooKey", spotlightr);
      formData.append("file", file);
      formData.append("create", 1);
      formData.append("name", "video");
      formData.append("hls", 1);
      formData.append("videoGroup", "23715");

      const axiosInstance = axios.create({
        baseURL: "https://api.spotlightr.com/api/createVideo",
      });
      setUploading(true);
      axiosInstance
        .post("", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((response) => {
          data?.onSubmit(
            {
              src: createEmbedFunction(response.data),
              lessonId: data.info?.lesson?.id,
              type: "video",
            },
            "post-video"
          );

          setUploading(false);
        })
        .catch((err) => {
          console.log(err);
          setUploading(false);
        });
    }
  }

  return (
    <div>
      {isUploading ? (
        <LinearProgressWithLabel value={progress} />
      ) : (
        <div className="form-group m30">
          <div className="file-upload-wrapper" data-text="Select your file!">
            <input
              name="file-upload-field"
              type="file"
              className="file-upload-field"
              id="input-file"
              accept="video/*"
              onChange={(e) => {
                e.stopPropagation();

                handleUpload(e.target.files[0]);
              }}
            />
            <label
              htmlFor="input-file"
              className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed theme-black-bg"
            >
              <i className="ti-cloud-down large-icon me-3 d-block"></i>
              <span className="js-fileName">Click to upload video</span>
            </label>
          </div>
        </div>
      )}

      <Button
        onClick={() => setAction("type")}
        className="mt-2 ml-2 bg-danger border-0"
        type="submit"
      >
        Back
      </Button>
    </div>
  );
}
