import { UseGetSubscribedUsers } from "../../hooks";
import LoadingPage from "../../components/shared/LoadingPage";
import Table from "./components/Table";
import { useState } from "react";

export default function SubscribedUsers() {
  const { data, isLoading, refetch } = UseGetSubscribedUsers();
  const [loading, setLoading] = useState(false);
  if (isLoading || loading) {
    return <LoadingPage />;
  }

  return <Table setLoading={setLoading} data={data?.data} refetch={refetch} />;
}
