import { Box, Paper, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UsePostPurchase } from "../../../../../../hooks";
import ButtonComponent from "../button";
import ListItem from "../ListItem";

const features = [
  {
    item: "Basic video lessons",
    disabled: false,
  },
  {
    item: "Exercises for practice",
    disabled: true,
  },
  {
    item: "Home work assignments",
    disabled: true,
  },
  {
    item: "Resources to download",
    disabled: true,
  },
  {
    item: "4 times hour mentor live one-on-one sessions per month",
    disabled: true,
  },
];

export default function Basic({ id }) {
  const enrollFreeCourse = UsePostPurchase();
  const history = useHistory();

  const handleMutate = useCallback(() => {
    const data = {
      course: id,
      tariff: "free",
      amount: 0,
    };

    enrollFreeCourse
      .mutateAsync(data)
      .then((d) => {
        return history.push(`/my-courses`);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Paper
      style={{
        padding: "25px 15px",
        minHeight: "530px",
        position: "relative",
        height: "100%",
      }}
    >
      <Box variant="div" className="card-head">
        <Typography variant="h5" fontWeight={700}>
          Basic
        </Typography>

        <Typography color="#3434ff" variant="body1">
          Self learning
        </Typography>
        <Typography color="#3434ff" variant="h4" fontWeight={700}>
          Free
        </Typography>
      </Box>

      <Box variant="div" className="card-body">
        {features.map((f) => {
          return <ListItem disabled={f.disabled} item={f.item} />;
        })}
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: "20px",
          width: "90%",
        }}
      >
        <ButtonComponent onClick={handleMutate}>Start now</ButtonComponent>
      </Box>
    </Paper>
  );
}
