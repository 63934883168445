import { UseGetTeachersWithoutApplication } from "../../hooks";
import LoadingPage from "../../components/shared/LoadingPage";
import Table from "./components/Table";

export default function TeachersWithoutApplication() {
  const { data, isLoading } = UseGetTeachersWithoutApplication();

  if (isLoading) {
    return <LoadingPage />;
  }

  return <Table data={data?.data} />;
}
