import { Breadcrumbs } from "@mui/material";

import { useEffect, useMemo, useReducer } from "react";
import { Link, useParams } from "react-router-dom";
import { UseGetCourse, UseGetModule } from "../../../hooks";
import "./style.scss";
import LoadingPage from "../../../components/shared/LoadingPage";
import List from "./components/List";
import Context from "./utils/Context";
import Lessons from "../lesson-detailed";
import Reducer from "./utils/Reducer";

export default function DetailedModule() {
  const { id, stageId, courseId } = useParams();
  const { data: course } = UseGetCourse(courseId);
  const { data, isLoading } = UseGetModule(
    id + "/" + stageId + "/" + course?.data?._id
  );
  const initialValues = useMemo(
    () => ({
      module: null,
      lesson: null,
      video: null,
    }),
    []
  );
  const [state, dispatch] = useReducer(Reducer, initialValues);

  useEffect(() => {
    dispatch({
      type: "module-lesson",
      payload: { module: data?.data?.selectedModule, lesson: null },
    });
  }, [data?.data]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Context.Provider value={{ data: data?.data, dispatch, state }}>
        <div className="container">
          <Breadcrumbs className="mb-5" aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/courses">
              Courses
            </Link>

            <Link
              underline="hover"
              color="inherit"
              to={`/courses/detailed/${course?.data?._id}`}
            >
              {data?.data?.modules &&
                data?.data?.modules[0] &&
                data?.data?.modules[0].stage?.name}
            </Link>
          </Breadcrumbs>

          <div
            style={{
              height: "512px",
              overflow: "hidden",
              backgroundColor: "#212e3c",
            }}
            className="row mb-5"
          >
            <div
              style={{ height: "100%" }}
              className="col-12 col-md-6 order-2 w-100 order-md-1 p-0"
            >
              <List />
            </div>
            <div className="col-12 col-md-6 order-1 w-100 order-md-2 p-2">
              {state.video && (
                <div
                  id="frame-wrapper"
                  className="h-100 w-100"
                  dangerouslySetInnerHTML={{ __html: state.video }}
                ></div>
              )}
            </div>
          </div>
          <Lessons />
        </div>
      </Context.Provider>
    </>
  );
}
