const Reducer = (state, action) => {
  switch (action.type) {
    case "video":
      return { ...state, video: action.payload };

    case "lesson":
      return { ...state, lesson: action.payload, video: null };

    case "module":
      if (state.module === action.payload) {
        return { ...state, module: null };
      }

      return { ...state, module: action.payload };

    case "module-lesson":
      return {
        ...state,
        module: action.payload.module,
        lesson: action.payload.lesson,
      };

    case "video-module":
      return {
        ...state,
        module: action.payload.module,
        video: action.payload.video,
      };
    default:
      return state;
  }
};
export default Reducer;
