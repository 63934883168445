import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectComponent({
  courses,
  selectedCourse,
  setSelectedCourse,
}) {
  function handleChange(e) {
    setSelectedCourse(e.target.value);
  }

  return (
    <div style={{ padding: "20px" }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select course</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedCourse}
          label="Age"
          onChange={handleChange}
        >
          {courses?.map((c) => (
            <MenuItem value={c?._id}>{c?.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
