import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { useForm, Controller, useWatch } from "react-hook-form";
import {
  UseGetCategories,
  UseGetOnboardingForTeacher,
  UsePostCourse,
  UseUpdateOnboardingForTeacher,
} from "../../../../hooks";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../../components/shared/ErrorMessage";
import styles from "./styles.module.scss";

export default function ({ addNewOpen, setAddNewOpen }) {
  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    category: yup.string().required(),
    link: yup.string().required(),
  });

  const history = useHistory();
  const [defaultValues, setDefaultValues] = useState({
    name: "",
    category: "",
    description: "",
    link: "",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const user = useSelector((data) => data?.auth?.user);
  const postCourse = UsePostCourse();

  const { data: onboarding } = UseGetOnboardingForTeacher();
  const updateOnboarding = UseUpdateOnboardingForTeacher();
  const monthlySubscribe = useWatch({
    control,
    name: "monthlySubscribe",
  });

  useEffect(() => {
    if (!monthlySubscribe) {
      setValue("priceForSubscribe", "");
      clearErrors("priceForSubscribe");
    }
  }, [monthlySubscribe]);

  const onSubmit = (data) => {
    postCourse
      .mutateAsync({
        ...data,
        creator: user?._id,
      })
      .then((data) => {
        history.push(`/edit-course/${data.data?.link}`);
        if (!onboarding?.data?.clickNewCourseButton) {
          updateOnboarding.mutateAsync({
            clickNewCourseButton: true,
          });
        }
      })
      .catch((err) => console.log(err));
    reset();
  };

  const categoryList = UseGetCategories();

  useEffect(() => {
    if (categoryList?.data?.data?.length) {
      setDefaultValues({
        ...defaultValues,
        category: categoryList.data.data[0]._id,
      });
    }
  }, [categoryList.isSuccess]);

  return (
    <div>
      <Modal
        open={addNewOpen}
        onClose={() => setAddNewOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="container px-3 py-4">
          <div className="row">
            <div className="col-lg-12 ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                  <div className="card-body d-block">
                    <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                      Add Course
                    </h4>

                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb30">
                          <label htmlFor="product_sku" className="form-label">
                            Title*
                          </label>

                          <Controller
                            control={control}
                            name="name"
                            render={({ field }) => (
                              <input
                                name="form_name"
                                className="form-control form_control m-0"
                                type="text"
                                placeholder="Course Title *"
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage error={errors?.name?.message} />
                        </div>
                      </div>

                      <div className="col-12 d-flex align-items-center">
                        <div
                          className="form-group  w-100"
                          style={{ postion: "relative" }}
                        >
                          <label htmlFor="categ" className="form-label">
                            Category*
                          </label>
                          <Controller
                            control={control}
                            name="category"
                            render={({ field }) => (
                              <select
                                id="categ"
                                className=" mb-0  form-control form_control select-custom w-100"
                                {...field}
                              >
                                {categoryList?.data?.data?.map((c, id) => (
                                  <option key={c?._id} value={c?._id}>
                                    {c?.name}
                                  </option>
                                ))}
                              </select>
                            )}
                          />
                          <ErrorMessage error={errors?.category?.message} />
                        </div>
                      </div>

                      <div className="col-sm-12 mt-3">
                        <div className="form-group m-0">
                          <label htmlFor="product_sku" className="form-label">
                            Description*
                          </label>
                          <Controller
                            control={control}
                            name="description"
                            render={({ field }) => (
                              <textarea
                                name="form_message"
                                className="form-control h150 mb-0"
                                placeholder="Course Description*"
                                {...field}
                              ></textarea>
                            )}
                          />
                        </div>
                        <ErrorMessage error={errors?.description?.message} />
                      </div>

                      <div className={`col-sm-12 mt-3 ${styles.wrapper}`}>
                        <label htmlFor="link">Link for course *</label>
                        <div className={styles.inside}>
                          https://codekaplan.com/courses/
                          <Controller
                            control={control}
                            name="link"
                            render={({ field }) => (
                              <input
                                name="course.link"
                                className="form-control form_control m-0"
                                type="text"
                                placeholder="Course Link *"
                                id="link"
                                style={{ width: "100%" }}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <ErrorMessage error={errors?.link?.message} />
                      </div>
                      <div className="col-12 d-flex justify-content-between mt-4">
                        <Button onClick={() => setAddNewOpen(false)}>
                          Close
                        </Button>
                        <Button type="submit">Create Course</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
