import { CardActionArea, Grid, Typography } from "@mui/material";
import React from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import NewCourse from "./components/NewCourse";
import Modules from "./components/Modules";
import Stage from "./components/Stage";
import Publish from "./components/Publish";
import { UseGetOnboardingForTeacher } from "../../../hooks";
import LoadingPage from "../../../components/shared/LoadingPage";

export default function TeacherOnboarding() {
  const history = useHistory();
  const [status, setStatus] = React.useState("create");
  const { data, isLoading } = UseGetOnboardingForTeacher();

  function getContentFromStatus() {
    switch (status) {
      case "create":
        return <NewCourse data={data?.data} />;
      case "stage":
        return <Stage data={data?.data} />;

      case "module":
        return <Modules data={data?.data} />;

      case "publish":
        return <Publish data={data?.data} />;
    }
  }

  if (isLoading) {
    <LoadingPage />;
  }

  return (
    <div className="container">
      <Typography variant="h4">Onboarding</Typography>
      <Typography className="my-4" variant="h5">
        How to create a Course
      </Typography>
      <Grid alignItems="center" className="mt-3" spacing={3} container>
        <Grid item sm={6} md={3}>
          <div className="text-center">
            <Card>
              <CardActionArea
                sx={{
                  padding: "20px",
                  textAlign: "center",
                  border: status === "create" ? "1px solid #0075FF" : "none",
                }}
                onClick={() => setStatus("create")}
              >
                <b>Create a new course</b>
              </CardActionArea>
            </Card>
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="text-center">
            <Card>
              <CardActionArea
                onClick={() => setStatus("stage")}
                sx={{
                  padding: "20px",
                  textAlign: "center",
                  border: status === "stage" ? "1px solid #0075FF" : "none",
                }}
              >
                <b>Adding Stages</b>
              </CardActionArea>
            </Card>
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="text-center">
            <Card>
              <CardActionArea
                onClick={() => setStatus("module")}
                sx={{
                  padding: "20px",
                  textAlign: "center",
                  border: status === "module" ? "1px solid #0075FF" : "none",
                }}
              >
                <b>Adding Modules, lessons and materials</b>
              </CardActionArea>
            </Card>
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="text-center">
            <Card>
              <CardActionArea
                onClick={() => setStatus("publish")}
                sx={{
                  padding: "20px",
                  textAlign: "center",
                  border: status === "publish" ? "1px solid #0075FF" : "none",
                }}
              >
                <b>Publish your course</b>
              </CardActionArea>
            </Card>
          </div>
        </Grid>
      </Grid>

      <div>{getContentFromStatus()}</div>
    </div>
  );
}
