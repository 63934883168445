import { LoadingIcon } from "../../../../assets/loadingIcon";

export default function Loading() {
  return (
    <div
      className="col-7 d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <LoadingIcon />
    </div>
  );
}
