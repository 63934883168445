import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function Buy({ courseId }) {
  return (
    <div style={{ height: "100%" }} className="col-7 modules">
      <div
        style={{ height: "100%" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="text-center">
          <p>You did not purchase this stage yet..</p>
          <Link to={`/cart/${courseId}`}>
            <Button variant="contained" color="primary">
              Purchase now
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
