import React from "react";
import Todo from "./Todo";

export default function stage({ data }) {
  return (
    <div style={{ height: "220px" }} className="mt-5">
      <Todo
        text="Go to Stage Settings page"
        number="1"
        isDone={data?.goSettings}
      />
      <Todo
        text="Click the Add Stage button"
        number="2"
        isDone={data?.clickAddStage}
      />
      <Todo
        text="Fill out the name, description, price fields of the form. (if it is free, check the free check box)"
        number="3"
        isDone={data?.clickSubmitStage}
      />
      <Todo
        text="Click the Submit button"
        number="4"
        isDone={data?.clickSubmitStage}
      />
    </div>
  );
}
