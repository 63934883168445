import * as React from "react";
import { Typography } from "@mui/material";
import WYSIWYGEditor from "../../../shared-components/WYSIWYGEditor";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, TextField } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import * as yup from "yup";
import { UsePostBlog } from "../../../hooks";
import { useHistory } from "react-router-dom";
import AddNewImage from "./Image";

export default function Blog() {
  const postBlog = UsePostBlog();
  const history = useHistory();
  const schema = yup.object().shape({
    name: yup.string().required(),
    content: yup.string(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: "",
      content: "",
    },
  });

  const [selectedFile, setSelectedFile] = React.useState();
  const [preview, setPreview] = React.useState();

  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const createFormData = (fData, file) => {
    const formData = new FormData();

    formData.append("file", JSON.stringify(fData));
    file && formData.append("file", file, file?.name);

    return formData;
  };

  const onSubmit = (data) => {
    const createdData = createFormData(data, selectedFile);

    postBlog.mutateAsync(createdData).then(() => history.push("/blog"));
  };

  return (
    <div className="container">
      <Typography component="h1" sx={{ fontSize: "1.5rem", fontWeight: 900 }}>
        New Blog
      </Typography>
      <br />

      <AddNewImage onSelectFile={onSelectFile} preview={preview} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box component="div" className="mb-4">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                className="bg-white"
                error={!!errors?.name}
                {...field}
              />
            )}
          />
          <ErrorMessage error={errors?.name?.message} />
        </Box>

        <Controller
          name="content"
          control={control}
          render={({ field }) => {
            return <WYSIWYGEditor {...field} />;
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            type="submit"
            variant="contained"
            className="mt-3"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
}
