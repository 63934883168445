export function getTariffName(tariff) {
  switch (tariff) {
    case "pro_advanced-purchase":
      return "Pro Self-learning | One-time Full Upfront";

    case "pro_advanced-subscribe":
      return "Pro Self-learning | Monthly Installment";

    case "pro_mentor-subscribe":
      return "Pro Mentor 1on1 | Monthly Installment";

    case "pro_mentor-purchase":
      return "Pro Self-learning | One-time Full Upfront";

    case "pro_live-monthly":
      return "Pro live group | Monthly Installment";

    case "pro_live-full_upfront":
      return "Pro live group | One-time Full Upfront";
  }
}
