export function defaultValues(data) {
  if (!data) {
    return;
  }

  return {
    isFree: data?.data?.isFree,
    pro_advanced: {
      price: data?.data?.pro_advanced?.price,
      monthlyPrice: data?.data?.pro_advanced?.monthlyPrice,
      selected:
        data?.data?.pro_advanced?.price ||
        data?.data?.pro_advanced?.monthlyPrice
          ? true
          : false,
      type: "pro_advanced",
    },

    pro_mentor: {
      price: data?.data?.pro_mentor?.price,
      monthlyPrice: data?.data?.pro_mentor?.monthlyPrice,
      sessions: data?.data?.pro_mentor?.sessions,
      student_count: data?.data?.pro_mentor?.student_count,
      selected:
        data?.data?.pro_mentor?.price || data?.data?.pro_mentor?.monthlyPrice
          ? true
          : false,
      type: "pro_mentor",
    },

    pro_live: {
      full_upfront: {
        type: data?.data?.pro_live?.full_upfront?.status,
        price: data?.data?.pro_live?.full_upfront?.price,
        status: data?.data?.pro_live?.full_upfront?.status,
      },
      monthly: {
        status: data?.data?.pro_live?.monthly?.status,
        monthlyPayment: data?.data?.pro_live?.monthly?.monthlyPayment,
        numberOfMonth: data?.data?.pro_live?.monthly?.numberOfMonth,
      },
      partial_monthly: {
        status: data?.data?.pro_live?.partial_monthly?.status,
        monthlyPayment: data?.data?.pro_live?.partial_monthly?.monthlyPayment,
        numberOfMonth: data?.data?.pro_live?.partial_monthly?.numberOfMonth,
        priceBeforeStart:
          data?.data?.pro_live?.partial_monthly?.priceBeforeStart,
        priceAfterJob: data?.data?.pro_live?.partial_monthly?.priceAfterJob,
        priceBeforeEnd: data?.data?.pro_live?.partial_monthly?.priceBeforeEnd,
      },
      start_job: {
        status: data?.data?.pro_live?.start_job?.status,
        monthlyPayment: data?.data?.pro_live?.start_job?.monthlyPayment,
        numberOfMonth: data?.data?.pro_live?.start_job?.numberOfMonth,
        priceBeforeStart: data?.data?.pro_live?.start_job?.priceBeforeStart,
        priceAfterJob: data?.data?.pro_live?.start_job?.priceAfterJob,
        priceBeforeEnd: data?.data?.pro_live?.start_job?.priceBeforeEnd,
      },
      selected:
        data?.data?.pro_live?.full_upfront?.status ||
        data?.data?.pro_live?.monthly?.status
          ? true
          : false,
    },
  };
}
