import { useParams } from "react-router-dom";
import { UseGetCouponByCourse } from "../../../../../hooks";
import Loading from "../../../../../components/shared/LoadingPage";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Switch } from "@material-ui/core";

export default function Info({ setLocation, id }) {
  const { data, isLoading } = UseGetCouponByCourse(id);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <TableContainer component={Paper}>
        {data?.data?.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Code</TableCell>
                <TableCell align="left">Percentage</TableCell>
                <TableCell align="left">Redemptions</TableCell>
                <TableCell align="left">Start Date</TableCell>
                <TableCell align="left">End Date</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data.map((row, index) => (
                <TableRow
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setLocation({ page: "edit", coupon: row?._id })
                  }
                  key={row.name}
                >
                  <TableCell component="left" scope="row">
                    {++index}
                  </TableCell>
                  <TableCell align="left">{row?.code}</TableCell>
                  <TableCell align="left">{row?.percentage}%</TableCell>
                  <TableCell align="left">{row?.redemptions}</TableCell>
                  <TableCell align="left">
                    {new Date(row?.startDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="left">
                    {row?.endDate
                      ? new Date(row?.endDate).toLocaleDateString()
                      : "-"}
                  </TableCell>
                  <TableCell align="left">
                    <Switch color="primary" checked={row?.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="d-flex justify-content-center p-4">
            No coupon yet...
          </div>
        )}
      </TableContainer>
      <div className="d-flex justify-content-end mt-4">
        <Button
          onClick={() => setLocation((p) => ({ ...p, page: "new" }))}
          variant="contained"
          color="primary"
        >
          Add new
        </Button>
      </div>
    </>
  );
}
