import React from "react";
import DoneIcon from "@mui/icons-material/Done";

export default function ListItem({ item, disabled }) {
  return (
    <div style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
      <DoneIcon
        sx={{ fontSize: "15px" }}
        color={disabled ? "disabled" : "info"}
      />
      <p
        style={{ margin: "0 0 0 12px", color: disabled ? "#00000042" : "#000" }}
      >
        {item}
      </p>
    </div>
  );
}
