import { useParams } from "react-router";
import { UseGetNewBatches } from "../../../../hooks";
import Timer from "../timer";
import styles from "./newBatch.module.scss";

export default function NewBatch({ id }) {
  const { data } = UseGetNewBatches(id);

  if (!data?.data.length) {
    return "";
  }

  return (
    <div className={styles.outer}>
      {data?.data.map((event) => (
        <div className={styles.wrapper}>
          <div className={styles.head}>
            <div className={styles.top}>
              <h3>Upcoming new batches 🎉</h3>
              <p className={styles.startDate}>
                Start date: {new Date(event.startDate).toLocaleDateString()}
              </p>
            </div>
            <h2 className={styles.bottom}>{event.name}</h2>
          </div>
          <hr />
          <div className={styles.display}>
            <h1 className={styles.title}>TIME REMAINING</h1>
            <Timer date={new Date(event?.startDate)} />
          </div>
          <div className={styles.description}>{event.description}</div>
        </div>
      ))}
    </div>
  );
}
