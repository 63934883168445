import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as yup from "yup";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  UseCreateEvent,
  UseDeleteEvent,
  UseGetEventById,
  UseUpdateEvent,
} from "../../../../../hooks";
import { useParams } from "react-router-dom";
import Days from "./Days";
import "./calendar.scss";
import Alert from "./DeleteModal";
import { Button } from "@material-ui/core";
import ErrorMessage from "../../../../../components/shared/ErrorMessage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CalendarModalCreate({ modal, setModal, defaultValues, refetch, id }) {
  const [repeatingDays, setRepeatingDays] = React.useState(new Set());

  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    startDate: yup.date().required(),
    endTime: yup.date().required(),
    isNewBatch: yup.boolean(),
    recurring: yup.boolean(),
    location: yup.string(),
    _id: yup.string(),
    endDate: yup.date().when("recurring", {
      is: true,
      then: yup
        .date()
        .required("End date is required")
        .typeError("End date is required"),
      otherwise: yup.date(),
    }),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      startDate: modal.data.date,
      endTime: modal.data.date,
    },
  });
  const createEvent = UseCreateEvent();

  const endTime = useWatch({
    name: "endTime",
    control,
  });
  const startDate = useWatch({
    name: "startDate",
    control,
  });
  const endDate = useWatch({
    name: "endDate",
    control,
  });
  const recurring = useWatch({
    name: "recurring",
    control,
  });

  const onSubmit = (data) => {
    const arr = Array.from(repeatingDays);

    createEvent
      .mutateAsync({
        ...data,
        startDate: data?.startDate?.toJSON(),
        endTime: data?.endTime?.toJSON(),
        endDate: data?.endDate?.toJSON(),
        courseId: id,
        repeatingDays: arr,
        repeating: recurring,
      })
      .then(() => {
        setModal(defaultValues);
        refetch();
      });
  };

  return (
    <div>
      <Modal
        open={modal.open}
        onClose={() => setModal(defaultValues)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={style}>
            <div className="input-wrapper">
              <label htmlFor="name">Name of event *</label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField fullWidth {...field} id="name" />
                )}
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="description">Description of event *</label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField fullWidth {...field} id="description" />
                )}
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="startDate">Start date *</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setValue("startDate", date);
                }}
                id="startDate"
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="endTime">End *</label>
              <DatePicker
                selected={endTime}
                onChange={(date) => {
                  setValue("endTime", date);
                }}
                id="endTime"
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="location">Location of event</label>
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <TextField id="location" fullWidth {...field} />
                )}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <label htmlFor="isNewBatch">New batch</label>
                <Controller
                  name="isNewBatch"
                  control={control}
                  render={() => (
                    <Checkbox
                      checked={getValues("isNewBatch")}
                      onChange={(e) => setValue("isNewBatch", e.target.checked)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      id="isNewBatch"
                    />
                  )}
                />
              </div>

              <div>
                <label htmlFor="recurring">Recurring</label>
                <Controller
                  name="recurring"
                  control={control}
                  render={() => (
                    <Checkbox
                      checked={getValues("recurring")}
                      onChange={(e) => setValue("recurring", e.target.checked)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      id="recurring"
                    />
                  )}
                />
              </div>
            </div>

            <Days
              repeatingDays={repeatingDays}
              setRepeatingDays={setRepeatingDays}
              recurring={recurring}
            />
            <div className="input-wrapper">
              <label htmlFor="endDate">End of recurring event *</label>
              <DatePicker
                disabled={!recurring}
                selected={endDate}
                onChange={(date) => {
                  setValue("endDate", date);
                }}
                showTimeSelect
                id="endDate"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
              <ErrorMessage error={errors?.endDate?.message} />
            </div>

            <br />
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button type="submit" variant="contained" color="primary">
                Create
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </div>
  );
}

function CalendarModalEdit({ modal, setModal, defaultValues, refetch, id }) {
  const { data, isLoading } = UseGetEventById(
    modal.data.event.extendedProps._id
  );
  const [repeatingDays, setRepeatingDays] = React.useState(new Set());
  const [alert, setAlert] = React.useState(false);

  const updateEvent = UseUpdateEvent(modal.data.event.extendedProps._id);
  const deleteEvent = UseDeleteEvent(modal.data.event.extendedProps._id);

  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    startDate: yup.date().required(),
    endTime: yup.date().required(),
    isNewBatch: yup.boolean(),
    location: yup.string(),
    recurring: yup.boolean(),
    endDate: yup
      .date()
      .nullable()
      .when("recurring", {
        is: true,
        then: yup
          .date()
          .required("End date is required")
          .typeError("End date is required")
          .nullable(),
        otherwise: yup.date().nullable(),
      }),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  React.useEffect(() => {
    setValue("name", data?.data?.name);
    setValue("startDate", new Date(data?.data?.startDate));
    setValue(
      "endDate",
      data?.data?.endDate ? new Date(data?.data?.endDate) : null
    );
    setValue("description", data?.data?.description);
    setValue("isNewBatch", data?.data?.isNewBatch);
    setValue("location", data?.data?.location);
    setValue("endTime", new Date(data?.data?.endTime));
    data?.data?.repeating && setValue("recurring", true);
    data?.data?.repeating &&
      setRepeatingDays(new Set(data?.data?.repeating?.repeatingDays));
  }, [data]);

  const endTime = useWatch({
    name: "endTime",
    control,
  });
  const startDate = useWatch({
    name: "startDate",
    control,
  });
  const endDate = useWatch({
    name: "endDate",
    control,
  });
  const recurring = useWatch({
    name: "recurring",
    control,
  });

  const onSubmit = (data) => {
    const arr = Array.from(repeatingDays);

    updateEvent
      .mutateAsync({
        ...data,
        startDate: data?.startDate?.toJSON(),
        endTime: data?.endTime?.toJSON(),
        endDate: data?.endDate?.toJSON(),
        courseId: id,
        repeatingDays: arr,
        repeating: recurring,
      })
      .then(() => {
        setModal(defaultValues);
        refetch();
      });
  };

  const handleDelete = () => {
    deleteEvent.mutateAsync().then(() => {
      setModal(defaultValues);
      refetch();
      setAlert(false);
    });
  };

  if (isLoading) {
    return <h1> Loading....</h1>;
  }

  return (
    <div>
      <Modal
        open={modal.open}
        onClose={() => setModal(defaultValues)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={style}>
            <div className="input-wrapper">
              <label htmlFor="name">Name of event *</label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField fullWidth {...field} id="name" />
                )}
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="description">Description of event *</label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField fullWidth {...field} id="description" />
                )}
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="startDate">Start date *</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setValue("startDate", date);
                }}
                id="startDate"
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="endTime">End *</label>
              <DatePicker
                selected={endTime}
                onChange={(date) => {
                  setValue("endTime", date);
                }}
                id="endTime"
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="location">Location of event</label>
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <TextField id="location" fullWidth {...field} />
                )}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <label htmlFor="isNewBatch">New batch</label>
                <Controller
                  name="isNewBatch"
                  control={control}
                  render={() => (
                    <Checkbox
                      checked={getValues("isNewBatch")}
                      onChange={(e) => setValue("isNewBatch", e.target.checked)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      id="isNewBatch"
                    />
                  )}
                />
              </div>

              <div>
                <label htmlFor="recurring">Recurring</label>
                <Controller
                  name="recurring"
                  control={control}
                  render={() => (
                    <Checkbox
                      checked={getValues("recurring")}
                      onChange={(e) => setValue("recurring", e.target.checked)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      id="recurring"
                    />
                  )}
                />
              </div>
            </div>

            <Days
              repeatingDays={repeatingDays}
              setRepeatingDays={setRepeatingDays}
              recurring={recurring}
            />
            <div className="input-wrapper">
              <label htmlFor="endDate">End of recurring event *</label>
              <DatePicker
                disabled={!recurring}
                selected={endDate}
                onChange={(date) => {
                  setValue("endDate", date);
                }}
                showTimeSelect
                id="endDate"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
              <ErrorMessage error={errors?.endDate?.message} />
            </div>

            <br />
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
              <Alert
                alert={alert}
                setAlert={setAlert}
                deleteFunc={handleDelete}
              />
              <Button
                variant="contained"
                className="ml-3"
                color="secondary"
                onClick={() => setAlert(true)}
              >
                Delete event
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </div>
  );
}

const Create = React.memo(CalendarModalCreate);
const Edit = React.memo(CalendarModalEdit);

export { Create, Edit };
