import { useSelector } from "react-redux";
import CourseList from "../pages/course/course-list";
import Overview from "../pages/overview";

export default function Provider() {
  const user = useSelector((data) => data?.auth?.user);
  return user.role === "teacher" || user.role === "admin" ? (
    <CourseList />
  ) : (
    <Overview />
  );
}
