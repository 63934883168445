import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function Successfull() {
  return (
    <div className="container">
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          textAlign: "center",
        }}
      >
        <h1>Successfully purchased</h1>
        <Link to="/my-courses">
          <Button variant="contained">Back to my courses</Button>
        </Link>
      </div>
    </div>
  );
}
