import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Button, DialogContent } from "@mui/material";

function Alert({ alert, fn }) {
  return (
    <Dialog className="dialog" open={alert} fullWidth maxWidth="sm">
      <AppBar
        position="static"
        style={{ backgroundColor: "#222e3c" }}
        elevation={0}
      >
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            Approve teacher
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant="h5" color="inherit">
          Are you sure approve this teacher?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          className="bg-danger cursor-pointer p-2 text-white ml-2"
          variant="contained"
          onClick={() => fn("cancel")}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className="bg-info cursor-pointer p-2 text-white ml-2"
          onClick={() => fn("approve")}
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Alert;
