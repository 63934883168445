import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  UseDeleteCourse,
  UseGetCategories,
  UsePatchCourse,
  UsePostFile,
} from "../../../../../hooks";
import ErrorMessage from "../../../../../components/shared/ErrorMessage";
import Alert from "./components/Alert";
import ChangeLink from "./components/ChangeLink";

const EditCourse = ({ data, refetch, setIsDirty }) => {
  const { id: courseId } = useParams();
  const history = useHistory();

  const schema = yup.object().shape({
    course: yup.object().shape({
      name: yup.string().required(),
      description: yup.string().required(),
      category: yup.string().required(),
    }),
  });

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      course: {
        name: data?.name,
        category: data?.category?._id,
        description: data?.description,
      },
    },
  });

  useEffect(() => setIsDirty(isDirty), [isDirty]);

  const deleteCourse = UseDeleteCourse(courseId);
  const [alert, setAlert] = useState(false);
  const postFile = UsePostFile();
  const [image, setImage] = useState(data?.imageUrl);

  const user = useSelector((data) => data?.auth?.user);
  const patchCourse = UsePatchCourse(courseId);

  const deleteFunc = () => {
    deleteCourse.mutateAsync().then(() => {
      setAlert(false);
      history.push("/courses");
    });
  };

  const onSubmit = (data) => {
    patchCourse
      .mutateAsync({
        ...data.course,
        imageUrl: image?._id,
      })
      .then(() => refetch())
      .catch((err) => console.log(err));
    reset();
  };

  const categoryList = UseGetCategories();

  const handleUploadImage = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("file", e.target?.files[0], e.target?.files[0]?.name);

    postFile
      .mutateAsync(formData)
      .then((data) => {
        setImage(data.data);
      })
      .catch((err) => console.log(err));
  };

  const serverUrl = process.env.REACT_APP_API_URL;
  const monthlySubscribe = useWatch({
    control,
    name: "course.monthlySubscribe",
  });

  useEffect(() => {
    if (!monthlySubscribe) {
      setValue("course.priceForSubscribe", "");
      clearErrors("course.priceForSubscribe");
    }
  }, [monthlySubscribe]);

  return (
    <div className="container px-3 py-4">
      <div className="row">
        <div className="col-lg-12 d-flex mb-4">
          <h2 className="text-grey-900 font-md fw-700">Edit Course</h2>
          <i className="ml-auto feather-command font-lg text-grey-500"></i>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card border-0 px-4 py-4 mt-2  rounded-lg admin-form">
            <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
              Course Link
            </h4>
            <ChangeLink id={data?._id} link={data?.link} />
          </div>
        </div>
        <div className="col-lg-12 ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
              <div className="card-body d-block">
                <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                  Course Information
                </h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Title
                      </label>

                      <Controller
                        control={control}
                        name="course.name"
                        render={({ field }) => (
                          <input
                            name="form_name"
                            className="form-control form_control m-0"
                            type="text"
                            placeholder="Course Title *"
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage error={errors?.course?.name?.message} />
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center">
                    <div
                      className="form-group mb30 w-100"
                      style={{ postion: "relative" }}
                    >
                      <label htmlFor="categ" className="form-label">
                        Category
                      </label>

                      <Controller
                        control={control}
                        name="course.category"
                        render={({ field }) => (
                          <select
                            id="categ"
                            className=" mb-0  form-control form_control select-custom w-100"
                            {...field}
                          >
                            {categoryList?.data?.data?.map((c, id) => (
                              <option key={c?._id} value={c?._id}>
                                {c?.name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                    </div>
                    <ErrorMessage error={errors?.course?.category?.message} />
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="product_sku" className="form-label">
                        Description
                      </label>
                      <Controller
                        control={control}
                        name="course.description"
                        render={({ field }) => (
                          <textarea
                            name="form_message"
                            className="form-control h150 mb-0"
                            placeholder="Course Description*"
                            {...field}
                          ></textarea>
                        )}
                      />

                      <ErrorMessage
                        error={errors?.course?.description?.message}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
              <div className="card-body d-block">
                <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                  Cover Image
                </h4>

                <div className="d-flex justify-content-center">
                  {image && (
                    <img
                      style={{ height: "300px", width: "300px" }}
                      src={serverUrl + "/files/" + image.src}
                    />
                  )}
                </div>

                <div className="form-group mb30">
                  <div
                    className="file-upload-wrapper"
                    data-text="Select your file!"
                  >
                    <input
                      name="file-upload-field"
                      type="file"
                      accept="image/gif, image/jpeg,image/jpg, image/png"
                      className="file-upload-field"
                      id="input-file"
                      {...register("file_img")}
                      onChange={handleUploadImage}
                    />
                    <label
                      htmlFor="input-file"
                      className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed theme-black-bg"
                    >
                      <i className="ti-cloud-down large-icon me-3 d-block"></i>
                      <span className="js-fileName">
                        Drag and drop or click to replace
                      </span>
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <Button
                    variant="contained"
                    color="primary"
                    className="bg-danger"
                    onClick={() => reset()}
                  >
                    Clear
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div
                className="row"
                style={{
                  height: "132px",
                }}
              >
                <div className="col-8">
                  <h1>Delete course</h1>
                  <p>
                    Make sure this breakup is for real, because once you delete
                    a course, it cannot be recovered.
                  </p>
                  <p>
                    Note: A course can only be deleted if it has zero
                    enrollments.
                  </p>
                </div>

                <div
                  className="col-4"
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    className="bg-danger text-white"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={() => setAlert(true)}
                    disabled={data?.isEnrolled}
                  >
                    Delete this course
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Alert alert={alert} setAlert={setAlert} deleteFunc={deleteFunc} />
    </div>
  );
};

export default EditCourse;
