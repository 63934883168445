import { Breadcrumbs, Button, Typography } from "@material-ui/core";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingPage from "../../../components/shared/LoadingPage";
import { UseGetCourse, UseGetStage, UseOpenStage } from "../../../hooks";
import Alert from "./components/Alert";
import * as yup from "yup";
import Stages from "./components/Stages";
import "./style.scss";

export default function DetailedCourse() {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = UseGetCourse(id);

  const [stage, setStage] = useState(data?.data?.stages[0]?._id);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (data?.data?.stages?.length) {
      setStage(data?.data?.stages[0]._id);
      setEnabled(true);
    }
  }, [isSuccess]);

  const { data: stageData, isLoading: stageLoading } = UseGetStage(
    stage,
    undefined,
    enabled
  );

  const history = useHistory();

  const handleClick = (lessonId, stageId) => {
    history.push(`/courses/module/${lessonId}/${stageId}/${id}`);
  };

  const schema = yup.object().shape({
    studentId: yup.string().required(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      studentId: "",
    },
  });

  const openStage = UseOpenStage();

  const defaultValues = useMemo(
    () => ({
      open: false,
      selectedStage: "",
    }),
    []
  );
  const [dialog, setDialog] = useState(defaultValues);

  const onSubmit = (data) => {
    openStage
      .mutateAsync({ ...data, stageId: dialog.selectedStage, courseId: id })
      .then(() => setDialog(defaultValues));
  };
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="stage-wrapper">
      <div className="stage-right">
        <Breadcrumbs className="ml-2 mb-2" aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/courses">
            Courses
          </Link>

          <Typography>{data?.data?.name}</Typography>
        </Breadcrumbs>

        {data?.data?.stages?.length ? (
          <Stages
            data={data?.data}
            setStage={setStage}
            setDialog={setDialog}
            stageLoading={stageLoading}
            stageData={stageData}
            handleClick={handleClick}
            id={id}
            stage={stage}
          />
        ) : (
          "stage not found"
        )}
        <FormProvider {...methods} onSubmit={onSubmit}>
          <Alert
            dialog={dialog}
            setDialog={setDialog}
            defaultValues={defaultValues}
          />
        </FormProvider>
      </div>
    </div>
  );
}
