import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Success from "./components/success";
import Expired from "./components/expired";
import BadRequest from "./components/badRequest";
import styles from "../login/login.module.scss";
import WhiteLogo from "../../../assets/logo/logo";

const VerifyEmailLink = () => {
  const { id } = useParams();

  const serverUrl = process.env.REACT_APP_API_URL || "";

  const [status, setStatus] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(serverUrl + `/user/verify/${id}`)
      .then((res) => {
        setStatus(res.status);
        return res.json();
      })
      .then((data) => setData(data))
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className={styles.parentLogin}>
      <div className={styles.loginLeft}>
        <a className={styles.logo} href="https://codekaplan.com">
          <WhiteLogo />
        </a>
      </div>
      <div className={styles.loginRight}>
        {status === 409 ? (
          data?.message === "verified" ? (
            <BadRequest />
          ) : (
            <Expired data={data} />
          )
        ) : (
          <Success />
        )}
      </div>
    </div>
  );
};

export default VerifyEmailLink;
