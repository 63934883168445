import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { AlertMessage } from "../../../../../../components/shared/Alerts";
import ErrorMessage from "../../../../../../components/shared/ErrorMessage";
import { UseCreateCourseLink } from "../../../../../../hooks";
import { useHistory } from "react-router-dom";

export default function ChangeLink({ link, id }) {
  const schema = yup.object().shape({
    link: yup.string().required(),
  });
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { link },
  });
  const createCourseLink = UseCreateCourseLink(id);
  const alert = new AlertMessage();
  const onSubmit = (data) => {
    createCourseLink
      .mutateAsync({ link: data.link })
      .then(() => {
        alert.success("Success!!");
        history.push(`/edit-course/${data.link}`);
      })
      .catch(() =>
        setError("link", { message: "This link is already taken!" })
      );
  };

  return (
    <form
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        height: "37px",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div style={{ width: "100%", height: "100%" }}>
        <Controller
          control={control}
          name="link"
          render={({ field }) => (
            <div style={{ display: "flex", height: "100%", alignItems: "end" }}>
              <label
                style={{
                  cursor: "pointer",
                  margin: 0,
                  fontSize: "18px",
                  marginBottom: "5px",
                }}
                htmlFor="link"
              >
                https://codekaplan.com/courses/
              </label>
              <input
                id="link"
                {...field}
                style={{
                  border: "none",
                  backgroundColor: "#eee",
                  width: "80%",
                  height: "100%",
                  fontSize: "18px",
                }}
              />
            </div>
          )}
        />
        <ErrorMessage error={errors?.link?.message} />
      </div>
      <div className="pl-16">
        <Button
          style={{ minWidth: "155px" }}
          color="primary"
          type="submit"
          variant="contained"
        >
          Check and save
        </Button>
      </div>
    </form>
  );
}
