import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import InputImage from "./inputs/file/Input-image";
import InputText from "./inputs/file/InputText";
import InputVideo from "./inputs/file/Input-video";
import InputPdf from "./inputs/file/Input-pdf";
import InputLink from "./inputs/file/InputLink";

export default function SelectFileType({ data }) {
  const [action, setAction] = useState("type");
  useEffect(() => {
    setAction("type");
  }, []);

  switch (action) {
    case "type":
      return (
        <div>
          <Button onClick={() => setAction("text")} className="m-2">
            Add Text
          </Button>

          <Button onClick={() => setAction("image")} className="m-2">
            Add Image
          </Button>

          <Button onClick={() => setAction("video")} className="m-2">
            Add Video
          </Button>
          <Button onClick={() => setAction("pdf")} className="m-2">
            Add Pdf
          </Button>
          <Button onClick={() => setAction("link")} className="m-2">
            Add Link
          </Button>
        </div>
      );

    case "text":
      return <InputText setAction={setAction} data={data} />;
    case "link":
      return <InputLink setAction={setAction} data={data} />;
    case "image":
      return <InputImage setAction={setAction} data={data} />;
    case "video":
      return <InputVideo setAction={setAction} data={data} />;
    case "pdf":
      return <InputPdf setAction={setAction} data={data} />;
  }
}
