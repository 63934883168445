import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import lesson from "../assets/lesson-icon.png";
import style from "./overview.module.scss";

const SimpleAccordion = ({ stages }) => {
  return (
    <div className="my-4">
      {stages?.map((s, i) => (
        <Accordion key={i}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body1" fontWeight={900}>
              Stage {++i}:{" "}
              <Typography
                sx={{ display: "inline", textTransform: "capitalize" }}
                variant="body1"
                fontWeight={900}
              >
                {s?.name}
              </Typography>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              fontFamily="Montserrat"
              className="mb-3"
              variant="body1"
            >
              {s?.description}
            </Typography>
            {s?.modules.length
              ? s?.modules.map((m, m_i) => (
                  <Accordion key={m_i}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="body1" fontWeight={900}>
                        Module-{++m_i}:{" "}
                        <Typography
                          sx={{
                            display: "inline",
                            textTransform: "capitalize",
                          }}
                          variant="body1"
                          fontWeight={900}
                        >
                          {m?.name}
                        </Typography>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {m?.lessons.length
                        ? m?.lessons.map((l, l_i) => (
                            <Box
                              key={l_i}
                              component="div"
                              className={style.lesson}
                            >
                              <img src={lesson} alt="lesson" />
                              <Typography
                                variant="body1"
                                sx={{
                                  marginLeft: "15px",
                                  display: "inline-block",
                                }}
                              >
                                {l?.name}
                              </Typography>
                            </Box>
                          ))
                        : "No lessons yet..."}
                    </AccordionDetails>
                  </Accordion>
                ))
              : "No modules yet..."}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
export default SimpleAccordion;
