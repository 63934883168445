import * as React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { UseGetEventById } from "../../hooks";
import LoadingPage from "../../components/shared/LoadingPage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ModalComponent({ modal, setModal }) {
  const { data, isLoading } = UseGetEventById(modal.eventId);
  if (isLoading) {
    return (
      <Modal
        open={modal.open}
        onClose={() => setModal({ open: false, eventId: null })}
      >
        <Box sx={style}>
          <LoadingPage />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={modal.open}
      onClose={() => setModal({ open: false, eventId: null })}
    >
      <Box sx={style}>
        <h1>Name : {data?.data?.name}</h1>
        <p>Description: {data?.data?.description}</p>
        <p>Location: {data?.data?.location}</p>
        <p>Start date: {new Date(data?.data?.startDate).toLocaleString()}</p>
        <p>End time: {new Date(data?.data?.endTime).toLocaleString()}</p>
      </Box>
    </Modal>
  );
}

export default ModalComponent;
