import { useState } from "react";

const useButton = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsDisabled(true);
  };

  const open = () => {
    setIsDisabled(false);
  };

  return [isDisabled, handleClick, open];
};

export default useButton;
