import React from "react";
import styles from "../login/login.module.scss";
import style from "./signup.module.scss";
import WhiteLogo from "../../../assets/logo/logo";
import { motion } from "framer-motion";

export default function SuccessLogin() {
  return (
    <>
      <div className={styles.parentLogin}>
        <div className={styles.loginLeft}>
          <a className={styles.logo} href="https://codekaplan.com">
            <WhiteLogo />
          </a>
        </div>

        <div className={styles.loginRight}>
          <motion.div
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <div className="container text-center">
              <h3 className="mb-5">
                “Thank you for signing up with us! <br /> Please verify your
                email address to complete your <br /> registration and activate
                your account!”
              </h3>

              <a className={style.loginBtn} href="/login">
                Back to login
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}
