import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { UseUpdateLessonOrder } from "../../../../../../../hooks";
import dragIcon from "./drag.svg";
import styles from "./lessons.module.scss";

export default function Lessons({ lessons, data }) {
  const [characters, updateCharacters] = useState();
  useEffect(() => {
    updateCharacters(lessons);
  }, [lessons]);
  const updateLessonOrder = UseUpdateLessonOrder();

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCharacters(items);
    const data = items.map((item, index) => ({
      _id: item?._id,
      weight: index,
    }));
    updateLessonOrder.mutateAsync(data);
  }

  if (!characters?.length) return <h1>no lessons</h1>;

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="characters">
        {(provided) => (
          <div
            className={styles.items}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {characters?.map((l, i) => {
              return (
                <Draggable key={l?._id} draggableId={l?._id} index={i}>
                  {(providedInner) => (
                    <div
                      ref={providedInner.innerRef}
                      {...providedInner.draggableProps}
                      onClick={() =>
                        data.setInfo({
                          ...data.defaultValues,
                          lesson: {
                            ...data.info.lesson,
                            id: l._id,
                            courseId: data.courseId,
                          },
                          type: "info-lesson",

                          open: true,
                        })
                      }
                      className={styles.listItem}
                      key={i}
                    >
                      <div
                        style={{ gap: "20px" }}
                        className="d-flex align-items-center"
                      >
                        <span {...providedInner.dragHandleProps}>
                          <img
                            style={{
                              height: "15px",
                              width: "15px",
                              cursor: "move",
                            }}
                            src={dragIcon}
                          />
                        </span>

                        <div>
                          <h3>{l?.name}</h3>
                          <p style={{ fontSize: "12px", margin: 0 }}>
                            {l?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
