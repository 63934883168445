import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { UseDeleteBlog } from "../../../hooks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const buttons = {
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
};

function Delete({ modal, setModal, defaultValues, refetch }) {
  const deleteBlog = UseDeleteBlog(modal.id);
  return (
    <div>
      <Modal
        open={modal.open}
        onClose={() => setModal(defaultValues)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are You sure delete this blog?
          </Typography>
          <Box sx={buttons}>
            <Button
              onClick={() => {
                setModal(defaultValues);
              }}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                deleteBlog.mutateAsync().then(() => {
                  setModal(defaultValues);
                  refetch();
                })
              }
              variant="contained"
              className="bg-danger"
            >
              Yes, delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default React.memo(Delete);
