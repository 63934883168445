import * as React from "react";
import { Typography } from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Box, Button, Card, TextField } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import * as yup from "yup";
import { UsePostCategory } from "../../../hooks";
import { useHistory } from "react-router-dom";
import AddNewImage from "./Image";

export default function AddNewCategory() {
  const postCategory = UsePostCategory();
  const history = useHistory();
  const schema = yup.object().shape({
    name: yup.string().required(),
    color: yup.string(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: "",
      color: "#ffffff",
    },
  });

  const color = useWatch({
    control,
    name: "color",
  });

  const [selectedFile, setSelectedFile] = React.useState();
  const [preview, setPreview] = React.useState();

  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const createFormData = (fData, file) => {
    const formData = new FormData();

    formData.append("file", JSON.stringify(fData));
    file && formData.append("file", file, file?.name);

    return formData;
  };

  const onSubmit = (data) => {
    const createdData = createFormData(data, selectedFile);

    postCategory.mutateAsync(createdData).then(() => history.push("/category"));
  };

  return (
    <div className="container">
      <Typography component="h1" sx={{ fontSize: "1.5rem", fontWeight: 900 }}>
        New Category
      </Typography>
      <br />

      <div className="d-flex justify-content-center mb-5">
        <Card
          style={{
            width: "250px",
            height: "250px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: color,
          }}
        >
          <AddNewImage onSelectFile={onSelectFile} preview={preview} />
        </Card>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="color">Card color:</label>
        <Box component="div" className="mb-4">
          <Controller
            name="color"
            control={control}
            render={({ field }) => (
              <input
                id="color"
                defaultValue="#ffffff"
                type="color"
                {...field}
              />
            )}
          />
        </Box>

        <Box component="div" className="mb-4">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                className="bg-white"
                error={!!errors?.name}
                {...field}
              />
            )}
          />
          <ErrorMessage error={errors?.name?.message} />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            type="submit"
            variant="contained"
            className="mt-3"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
}
