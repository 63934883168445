import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LoadingIcon } from "../../../assets/loadingIcon";
import LoadingPage from "../../../components/shared/LoadingPage";
import { UseGetCourse, UseGetStage } from "../../../hooks";
import "./style.scss";

export default function CourseInfo() {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = UseGetCourse(id);

  const [stage, setStage] = useState(data?.data?.stages[0]?._id);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (data?.data?.stages?.length) {
      setStage(data?.data?.stages[0]._id);
      setEnabled(true);
    }
  }, [isSuccess]);

  const { data: stageData, isLoading: stageLoading } = UseGetStage(
    stage,
    undefined,
    enabled
  );
  const history = useHistory();

  const handleClick = (id) => {
    history.push(`/courses/module/${id}`);
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="row m-0 stage-right__row">
      <div className="col-5 px-2 stage-list">
        {data?.data?.stages?.map((d, i) => (
          <div
            key={i}
            onClick={() => setStage(d?._id)}
            className={`stage-item p-4 ${
              d?._id === stage ? "selected-item" : ""
            }`}
          >
            <h1 className="fw-600">Stage-{i + 1}</h1>
            <h1 className="mb-2 fw-600">{d?.name}</h1>
            <p className="stage-item__text">{d?.description}</p>
          </div>
        ))}
      </div>

      {stageLoading ? (
        <div
          className="col-7 d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <LoadingIcon />
        </div>
      ) : stageData?.data?.modules?.length ? (
        <div key={id} className="col-7 p-3 modules">
          <h1 className="mt-4 fw-600">{stageData?.data?.name}</h1>

          <p className="modules__text">{stageData?.data?.description}</p>
          {stageData?.data?.modules.map((d, id) => (
            <div
              onClick={() => handleClick(d?._id)}
              className="modules__list"
              key={id}
            >
              <div className="modules__list-item">
                <h3 className="fw-600">{d?.name}</h3>
                <i className="ti-arrow-right"></i>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div key={id} className="col-7 p-3 modules">
          <div className="d-flex justify-content-center">
            <p className="mt-5">No modules yet..</p>
          </div>
        </div>
      )}
    </div>
  );
}
