import { Box, CardContent, Button } from "@mui/material";
import styles from "./landing.module.scss";

import videoThumbnail from "./video-thumb.webp";

const AddNewVideo = ({ preview, onSelectFile, data }) => {
  return (
    <CardContent>
      <div className="flex justify-center items-center flex-col mb-5">
        <Box
          className={styles.video}
          component="div"
          style={{ height: (preview || data) && "500px", width: "100%" }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: data,
            }}
            style={{ width: "100%", display: preview ? "none" : "block" }}
          ></div>

          <video
            style={{
              height: "100%",
              width: "100%",
              display: preview ? "block" : "none",
              marginBottom: "2px",
            }}
            src={preview || videoThumbnail}
            alt="video"
            autoPlay
            muted
            controls
            poster={videoThumbnail}
            loop
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="cursor-pointer"
          >
            <label
              className="cursor-pointer"
              style={{ width: "100%", margin: 0 }}
              htmlFor="inputVideo"
            >
              Add preview video
            </label>
          </Button>

          <input
            id="inputVideo"
            style={{ display: "none" }}
            type="file"
            accept="video/*"
            onChange={onSelectFile}
          />
        </Box>
      </div>
    </CardContent>
  );
};
export default AddNewVideo;
