import React from "react";
import "./calendar.scss";

export default function Days({ recurring, repeatingDays, setRepeatingDays }) {
  function checkIsExists(data) {
    return repeatingDays.has(data);
  }

  function addToSet(data) {
    const set = new Set(repeatingDays.values());
    set.add(data);
    setRepeatingDays(set);
  }

  function removeFromSet(data) {
    repeatingDays.delete(data);
    setRepeatingDays((p) => new Set(p.values()));
  }

  function handleAddOrRemoveToSet(data) {
    if (checkIsExists(data)) {
      removeFromSet(data);
    } else {
      addToSet(data);
    }
  }

  return (
    <div className="calendar-button-wrapper">
      <div className="calendar-button-wrapper__buttons-first">
        <button
          type="button"
          disabled={!recurring}
          className={checkIsExists(1) ? "button-selected" : ""}
          onClick={(e) => {
            handleAddOrRemoveToSet(1);
          }}
        >
          mon
        </button>
        <button
          type="button"
          disabled={!recurring}
          className={checkIsExists(2) ? "button-selected" : ""}
          onClick={(e) => {
            handleAddOrRemoveToSet(2);
          }}
        >
          tue
        </button>
        <button
          type="button"
          disabled={!recurring}
          className={checkIsExists(3) ? "button-selected" : ""}
          onClick={(e) => {
            handleAddOrRemoveToSet(3);
          }}
        >
          wed
        </button>
      </div>
      <div className="calendar-button-wrapper__buttons-second">
        <button
          type="button"
          disabled={!recurring}
          className={checkIsExists(4) ? "button-selected" : ""}
          onClick={(e) => {
            handleAddOrRemoveToSet(4);
          }}
        >
          thu
        </button>
        <button
          type="button"
          disabled={!recurring}
          className={checkIsExists(5) ? "button-selected" : ""}
          onClick={(e) => {
            handleAddOrRemoveToSet(5);
          }}
        >
          fri
        </button>
        <button
          type="button"
          disabled={!recurring}
          className={checkIsExists(6) ? "button-selected" : ""}
          onClick={(e) => {
            handleAddOrRemoveToSet(6);
          }}
        >
          sat
        </button>
        <button
          type="button"
          disabled={!recurring}
          className={checkIsExists(0) ? "button-selected" : ""}
          onClick={(e) => {
            handleAddOrRemoveToSet(0);
          }}
        >
          sun
        </button>
      </div>
    </div>
  );
}
