import React from "react";
import styles from "../login/login.module.scss";
import style from "../signup/signup.module.scss";
import WhiteLogo from "../../../assets/logo/logo";
import { useForm } from "react-hook-form";
import { UseRegister } from "../../../hooks";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function Success() {
  return (
    <>
      <div className={styles.parentLogin}>
        <div className={styles.loginLeft}>
          <a className={styles.logo} href="https://codekaplan.com">
            <WhiteLogo />
          </a>
        </div>

        <div className={styles.loginRight}>
          <motion.div
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <h3>We have sent reset link to your email</h3>

            <Link className={style.loginBtn} to="/login">
              Back to login
            </Link>
          </motion.div>
        </div>
      </div>
    </>
  );
}
