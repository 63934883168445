import { Button } from "@mui/material";
import React from "react";

export default function ButtonComponent({ children, disabled, ...rest }) {
  return (
    <Button disabled={disabled} fullWidth variant="outlined" {...rest}>
      {children}
    </Button>
  );
}
