import AddIcon from "@mui/icons-material/Add";
import { Box, CardContent } from "@mui/material";
import styles from "./category.module.scss";
import thumbnail from "./thumbnail.png";

const AddNewImage = ({ preview, onSelectFile, image }) => {
  return (
    <CardContent>
      <div className="d-flex justify-content-center align-items-center">
        <Box component="div" className={styles.wrapper}>
          <label htmlFor="image" className={styles.add}>
            <AddIcon />
          </label>

          <img
            src={preview || thumbnail}
            alt="image"
            className={styles.preview}
          />
          <input
            id="image"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={onSelectFile}
          />
        </Box>

        <input
          type="file"
          accept="image/gif, image/jpeg,image/jpg, image/png"
          name="image"
          id="file"
          style={{ display: "none" }}
          onChange={onSelectFile}
        />
      </div>
    </CardContent>
  );
};
export default AddNewImage;
