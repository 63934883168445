import React from "react";
import Todo from "./Todo";

export default function Publish({ data }) {
  return (
    <div style={{ height: "220px" }} className="mt-5">
      <Todo text="Go to Publish tab" number="1" isDone={data?.goPublish} />
      <Todo
        text="Click the Publish Course button"
        number="2"
        isDone={data?.clickPublish}
      />
    </div>
  );
}
