import { Box, Typography } from "@mui/material";

export default function TextWithLabel({ label, text }) {
  return (
    <Box component="div">
      <Typography variant="h5">{label}:</Typography>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
}
