export function MakePlans(plans) {
  if (!plans?.length) {
    return;
  }

  const array = plans.map((plan) => {
    switch (plan) {
      case "pro_advanced-purchase":
        return {
          label: "Pro Self learning purchase",
          value: plan,
        };
      case "pro_advanced-subscribe":
        return {
          label: "Pro Self learning monthly",
          value: plan,
        };
      case "pro_mentor-purchase":
        return {
          label: "Pro Mentor purchase",
          value: plan,
        };
      case "pro_mentor-subscribe":
        return {
          label: "Pro Mentor monthly",
          value: plan,
        };
      case "pro_live-full_upfront":
        return {
          label: "Pro Live purchase",
          value: plan,
        };
      case "pro_live-monthly":
        return {
          label: "Pro Live monthly",
          value: plan,
        };
    }
  });
  return array;
}
