import { useState } from "react";

const Expired = ({ data }) => {
  const [success, setSuccess] = useState(false);

  function Verify(url) {
    fetch(url).then(() => setSuccess(true));
  }
  const serverUrl = process.env.REACT_APP_API_URL;
  return success ? (
    <div className="forgot_password" style={{ width: "100%" }}>
      <h2 className="title auth-layout__title">Verify your email</h2>
      <p className="context">Please verify your account from email</p>
    </div>
  ) : (
    <div className="wrapper">
      <div className="inner">
        <h2>Your link expired</h2>

        <button
          onClick={() => Verify(`${serverUrl}user/verify/${data?.data}`)}
          className="button-filled auth-layout__button"
          type="submit"
        >
          Resend email
        </button>
      </div>
    </div>
  );
};
export default Expired;
