import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../login/login.module.scss";
import WhiteLogo from "../../../assets/logo/logo";
import { motion } from "framer-motion";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { UseForgotPassword } from "../../../hooks";
import Success from "./success";

export default function ResetPassword() {
  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [success, isSuccess] = useState(false);

  const forgotPassword = UseForgotPassword();
  const onSubmit = (data) => {
    forgotPassword
      .mutateAsync(data)
      .then(() => isSuccess(true))
      .catch((err) =>
        setError("responseError", { message: err?.response?.data?.message })
      );
  };

  return success ? (
    <Success />
  ) : (
    <>
      <div className={styles.parentLogin}>
        <div className={styles.loginLeft}>
          <a className={styles.logo} href="https://codekaplan.com">
            <WhiteLogo />
          </a>
        </div>
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1 }}
          className={styles.loginRight}
        >
          <h1 style={{ marginTop: "30px" }}>Forgot password</h1>
          <p className={styles.paragraph}>
            Please enter your email and we'll send you instructions to reset
            your password.
          </p>
          <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.loginInput}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className={styles.input}
                    type="email"
                    placeholder="Email"
                  />
                )}
              />
              {!errors?.responseError && (
                <ErrorMessage error={errors?.email?.message} />
              )}
              <ErrorMessage error={errors?.responseError?.message} />
            </div>
            <button
              className={`${styles.loginBtn} ${
                !isValid && styles.loginBtnDisabled
              }`}
              type="submit"
            >
              Send reset link
            </button>
          </form>
          <div className={styles.registerFooter}>
            <Link to="/login"> Back to Login</Link>
          </div>
        </motion.div>
      </div>
    </>
  );
}
