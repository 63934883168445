import { UseGetLesson } from "../../../../../../../hooks";
import SelectFileType from "../Select-file-type";
import "./style.css";
import PdfViewer from "../../../../../../../components/shared/PdfViewer";
import { Button } from "@mui/material";
import { useEffect } from "react";

export default function InfoLesson({ data }) {
  const l = UseGetLesson(data.info.lesson.id);
  useEffect(() => {
    if (data.info.lesson.courseId) {
      l.mutateAsync({ courseId: data.info.lesson.courseId });
    }
  }, [data.info.lesson.id]);

  const { data: lesson } = l;
  const serverUrl = process.env.REACT_APP_API_URL;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="texts">
          <h1>{lesson?.data?.name}</h1>
          <p>{lesson?.data?.description}</p>
        </div>
        <i
          onClick={() =>
            data?.setInfo({
              ...data.info,
              lesson: { ...data.info.lesson, data: lesson?.data },
              open: true,
              type: "edit-lesson",
            })
          }
          className="ti-pencil mr-4 cursor-pointer"
        />
      </div>

      <hr />
      <div>
        <h1>Lesson Materials</h1>
        <br />

        {lesson?.data?.files.length ? (
          <>
            {lesson?.data?.files.map((f, id) => {
              if (f?.type === "text") {
                return (
                  <div key={id}>
                    <h1>{f?.title}</h1>
                    <p>{f?.description}</p>
                  </div>
                );
              }
              if (f?.type === "image") {
                return (
                  <div key={id}>
                    <img
                      style={{ maxWidth: "100%" }}
                      src={serverUrl + "/files" + f?.src}
                    />
                  </div>
                );
              }
              if (f?.type === "video") {
                return (
                  <div
                    key={id}
                    id="frame-wrapper"
                    style={{ height: "450px" }}
                    dangerouslySetInnerHTML={{ __html: f.src }}
                  ></div>
                );
              }

              if (f?.type === "pdf") {
                return (
                  <div className=" mb-4" key={id}>
                    <PdfViewer fileUrl={f?.src} />
                    <hr />
                  </div>
                );
              }
              if (f?.type === "link") {
                return (
                  <div className="text-center mb-4" key={id}>
                    <a href={f?.link} target="_blank">
                      <Button variant="contained" color="primary">
                        View external lesson material
                      </Button>
                    </a>
                    <hr />
                  </div>
                );
              }
            })}
          </>
        ) : (
          <p>no materials yet...</p>
        )}

        <SelectFileType data={data} />
      </div>
    </>
  );
}
