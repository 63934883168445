import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { UseGetBlogs } from "../../hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar } from "@mui/material";
import Delete from "./components/Delete";
import styles from "./blog.module.scss";
import Loading from "../../components/shared/LoadingPage";

export default function Blog() {
  const { data, refetch, isFetching, isLoading } = UseGetBlogs();
  const history = useHistory();
  const serverUrl = process.env.REACT_APP_API_URL;
  const defaultValues = React.useMemo(() => ({ open: false, id: "" }), []);

  const [modal, setModal] = React.useState(defaultValues);

  return (
    <React.Fragment>
      {isLoading || isFetching ? (
        <Loading />
      ) : (
        <div className="container">
          <Box
            component="div"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              component="h1"
              sx={{ fontSize: "1.5rem", fontWeight: 900 }}
            >
              Blogs
            </Typography>

            <Button
              onClick={() => history.push("/blog/add-new")}
              variant="contained"
              className="mt-3"
              color="primary"
            >
              Add New
            </Button>
          </Box>
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>image</TableCell>
                  <TableCell align="center">Blog</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.map((d, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell align="center">
                      <Avatar
                        alt={d?.name + " " + d?.surname}
                        src={serverUrl + "/files" + d?.image}
                      />
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{d?.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ maxHeight: "300px", overflow: "auto" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: d?.content,
                            }}
                            style={{ width: "100%" }}
                          ></div>
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>

                    <TableCell component="th" align="center" scope="row">
                      <div
                        onClick={() => history.push(`blog/edit/${d?._id}`)}
                        className={styles.edit}
                      >
                        <EditIcon />
                      </div>
                      <div
                        onClick={() => setModal({ open: true, id: d?._id })}
                        className={styles.delete}
                      >
                        <DeleteIcon />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      <Delete
        defaultValues={defaultValues}
        setModal={setModal}
        modal={modal}
        refetch={refetch}
      />
    </React.Fragment>
  );
}
