import * as React from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { Button, DialogTitle } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../../../components/shared/ErrorMessage";
import { UseGetCourse, UsePatchCourse } from "../../../../../hooks";
import styles from "./pricing.module.scss";
import { AlertMessage } from "../../../../../components/shared/Alerts";
import { schema } from "./schemas/index";
import { defaultValues } from "./schemas/defaultValues";

function PricingPlans({ id }) {
  const { data, isLoading, refetch } = UseGetCourse(id);
  const patchCourse = UsePatchCourse(id);
  const [isSubmited, setIsSubmited] = React.useState(false);

  const alert = new AlertMessage();

  const {
    handleSubmit,
    setValue,
    control,
    trigger,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: defaultValues(data),
  });

  const onSubmit = (d) => {
    const {
      isFree,
      pro_live: { ...live },
      pro_advanced: advanced,
      pro_mentor: mentor,
    } = d;

    patchCourse
      .mutateAsync({
        isFree,
        pro_live: live,
        pro_advanced: advanced,
        pro_mentor: mentor,
      })
      .then(() => alert.success("Success!!"))
      .catch((err) => alert.error(err.message))
      .finally(() => {
        refetch();
        setIsSubmited((p) => !p);
      });
  };
  const [checkboxes, setCheckboxes] = React.useState({
    pro_advanced: !!data?.data?.pro_advanced,
    pro_mentor: !!data?.data?.pro_mentor?.price,
    pro_live:
      data?.data?.pro_live?.full_upfront?.status ||
      data?.data?.pro_live?.monthly?.status ||
      data?.data?.pro_live?.partial_monthly?.status ||
      data?.data?.pro_live?.start_job?.status,
  });

  const [dialogs, setDialogs] = React.useState({
    pro_advanced: false,
    pro_mentor: false,
    pro_live: false,
  });

  return (
    <>
      <form
        style={{ backgroundColor: "#fff", padding: "10px 20px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.wrapper}>
          <Controller
            name="isFree"
            control={control}
            render={({ field }) => (
              <Checkbox
                defaultChecked={data?.data?.isFree}
                style={{ padding: 0 }}
                id="basic_free"
                {...field}
              />
            )}
          />

          <label htmlFor="basic_free">
            <h2>Basic Free</h2>
            Offer free content to your subscribers. Optionally, you can set an
            enrollment duration that will limit the time students have access to
            your content.
            <ul className={styles.list}>
              <li>Basic video lessons</li>
              <li>Exercises for practice</li>
              <li>Home work assignments</li>
              <li>Resources to download</li>
            </ul>
          </label>
        </div>

        <div className={styles.wrapper}>
          <Checkbox checked={checkboxes.pro_advanced} />

          <Dialog open={dialogs.pro_advanced}>
            <DialogTitle>Pro Advanced</DialogTitle>
            <DialogContent>
              <Controller
                name="pro_advanced.price"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="dense"
                    id="name"
                    error={errors?.pro_advanced?.price}
                    label="Price"
                    fullWidth
                    variant="standard"
                    type="number"
                  />
                )}
              />
              <ErrorMessage error={errors?.pro_advanced?.price?.message} />
              <Controller
                name="pro_advanced.monthlyPrice"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="dense"
                    id="name"
                    error={errors?.pro_advanced?.monthlyPrice}
                    label="Monthly Price"
                    fullWidth
                    variant="standard"
                    type="number"
                  />
                )}
              />
              <ErrorMessage
                error={errors?.pro_advanced?.monthlyPrice?.message}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setValue("pro_advanced.selected", false);
                  setValue("pro_advanced.price", undefined);
                  setValue("pro_advanced.monthlyPrice", undefined);
                  setCheckboxes((p) => ({ ...p, pro_advanced: false }));
                  setDialogs((p) => ({ ...p, pro_advanced: false }));
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  const price = await trigger("pro_advanced.price");
                  const monthlyPrice = await trigger(
                    "pro_advanced.monthlyPrice"
                  );
                  setValue("pro_advanced.type", "pro_advanced");

                  if (price && monthlyPrice) {
                    setDialogs((p) => ({
                      ...p,
                      pro_advanced: false,
                    }));
                  }
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <label
            onClick={() => {
              setValue("pro_advanced.selected", true);
              setDialogs((p) => ({ ...p, pro_advanced: true }));
              setCheckboxes((p) => ({ ...p, pro_advanced: true }));
            }}
          >
            <h2>Pro Advanced</h2>
            Offer free content to your subscribers. Optionally, you can set an
            enrollment duration that will limit the time students have access to
            your content.
            <ul className={styles.list}>
              <li>Basic video lessons</li>
              <li>Exercises for practice</li>
              <li>Home work assignments</li>
              <li>Resources to download</li>
            </ul>
          </label>
        </div>

        <div className={styles.wrapper}>
          <Checkbox checked={checkboxes.pro_mentor} />

          <Dialog open={dialogs.pro_mentor}>
            <DialogTitle>Pro Mentor</DialogTitle>
            <DialogContent>
              <Controller
                name="pro_mentor.sessions"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="dense"
                    error={errors?.pro_mentor?.sessions}
                    label="Sessions"
                    fullWidth
                    variant="standard"
                    type="number"
                  />
                )}
              />
              <ErrorMessage error={errors?.pro_mentor?.sessions?.message} />

              <Controller
                name="pro_mentor.student_count"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="dense"
                    error={errors?.pro_mentor?.student_count}
                    label="Student Count"
                    fullWidth
                    variant="standard"
                    type="number"
                  />
                )}
              />
              <ErrorMessage
                error={errors?.pro_mentor?.student_count?.message}
              />

              <Controller
                name="pro_mentor.price"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="dense"
                    error={errors?.pro_mentor?.price}
                    label="Price"
                    fullWidth
                    variant="standard"
                    type="number"
                  />
                )}
              />
              <ErrorMessage error={errors?.pro_mentor?.price?.message} />

              <Controller
                name="pro_mentor.monthlyPrice"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="dense"
                    error={errors?.pro_mentor?.monthlyPrice}
                    label="Monthly price"
                    fullWidth
                    variant="standard"
                    type="number"
                  />
                )}
              />
              <ErrorMessage error={errors?.pro_mentor?.monthlyPrice?.message} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setValue("pro_mentor.selected", false);
                  setValue("pro_mentor.price", undefined);
                  setValue("pro_mentor.monthlyPrice", undefined);
                  setValue("pro_mentor.sessions", undefined);
                  setValue("pro_mentor.student_count", undefined);
                  setCheckboxes((p) => ({ ...p, pro_mentor: false }));
                  setDialogs((p) => ({ ...p, pro_mentor: false }));
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  const price = await trigger("pro_mentor.price");
                  const sessions = await trigger("pro_mentor.sessions");
                  const student_count = await trigger(
                    "pro_mentor.student_count"
                  );
                  const monthlyPrice = await trigger("pro_mentor.monthlyPrice");
                  setValue("pro_mentor.type", "pro_mentor");
                  if (price && sessions && student_count && monthlyPrice) {
                    setDialogs((p) => ({
                      ...p,
                      pro_mentor: false,
                    }));
                  }
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <label
            onClick={() => {
              setValue("pro_mentor.selected", true);
              setDialogs((p) => ({ ...p, pro_mentor: true }));
              setCheckboxes((p) => ({ ...p, pro_mentor: true }));
            }}
          >
            <h2>Pro Mentor</h2>
            Offer free content to your subscribers. Optionally, you can set an
            enrollment duration that will limit the time students have access to
            your content.
            <ul className={styles.list}>
              <li>Basic video lessons</li>
              <li>Exercises for practice</li>
              <li>Home work assignments</li>
              <li>Resources to download</li>
            </ul>
          </label>
        </div>

        <div className={styles.wrapper}>
          <Checkbox checked={checkboxes.pro_live} />

          <Dialog fullWidth maxWidth="md" open={dialogs.pro_live}>
            <DialogTitle>Pro Live</DialogTitle>
            <DialogContent>
              <div>
                <Typography variant="h6">Full upfront</Typography>
                <div className="d-flex align-items-center justify-content-between">
                  <Controller
                    name="pro_live.full_upfront.status"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        className="mr-3"
                        {...field}
                        defaultChecked={getValues(
                          "pro_live.full_upfront.status"
                        )}
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.full_upfront.price"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.full_upfront?.price}
                        label="One Time Price"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />
                </div>

                <ErrorMessage
                  error={errors.pro_live?.full_upfront?.price?.message}
                />
                <br />
              </div>

              <div>
                <Typography variant="h6">Monthly</Typography>
                <div className="d-flex align-items-center">
                  <Controller
                    name="pro_live.monthly.status"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        className="mr-3"
                        {...field}
                        defaultChecked={getValues("pro_live.monthly.status")}
                      />
                    )}
                  />

                  <div className="w-100">
                    <Controller
                      name="pro_live.monthly.monthlyPayment"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          margin="dense"
                          error={
                            errors.pro_live?.monthly?.monthlyPayment?.message
                          }
                          label="Monthly payment"
                          fullWidth
                          variant="standard"
                          type="number"
                        />
                      )}
                    />

                    <ErrorMessage
                      error={errors.pro_live?.monthly?.monthlyPayment?.message}
                    />
                  </div>

                  <div className="w-100">
                    <Controller
                      name="pro_live.monthly.numberOfMonth"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          margin="dense"
                          error={
                            errors.pro_live?.monthly?.numberOfMonth?.message
                          }
                          label="Number of month"
                          fullWidth
                          variant="standard"
                          type="number"
                        />
                      )}
                    />

                    <ErrorMessage
                      error={errors.pro_live?.monthly?.numberOfMonth?.message}
                    />
                  </div>
                </div>
              </div>

              {/* <div>
                <Typography variant="h6">Partial monthly</Typography>
                <div className="d-flex align-items-center">
                  <Controller
                    name="pro_live.partial_monthly.status"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        className="mr-3"
                        {...field}
                        defaultChecked={getValues(
                          "pro_live.partial_monthly.status"
                        )}
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.partial_monthly.monthlyPayment"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.partial_monthly?.monthlyPayment}
                        label="Monthly payment"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.partial_monthly.numberOfMonth"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.partial_monthly?.numberOfMonth}
                        label="Number of month"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.partial_monthly.priceBeforeStart"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={
                          errors.pro_live?.partial_monthly?.priceBeforeStart
                        }
                        label="Tuition before start course"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.partial_monthly.priceAfterJob"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.partial_monthly?.priceAfterJob}
                        label="Tuition after job placement"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.partial_monthly.priceBeforeEnd"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.partial_monthly?.priceBeforeEnd}
                        label="Tuition before end of course"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />
                </div>
              </div> */}

              {/* <div>
                <Typography variant="h6">Start job</Typography>
                <div className="d-flex align-items-center">
                  <Controller
                    name="pro_live.start_job.status"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        className="mr-3"
                        {...field}
                        defaultChecked={getValues("pro_live.start_job.status")}
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.start_job.monthlyPayment"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.start_job?.monthlyPayment}
                        label="Monthly payment"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.start_job.numberOfMonth"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.start_job?.numberOfMonth}
                        label="Number of month"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.start_job.priceBeforeStart"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.start_job?.priceBeforeStart}
                        label="Tuition before start course"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.start_job.priceAfterJob"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.start_job?.priceAfterJob}
                        label="Tuition after job placement"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />

                  <Controller
                    name="pro_live.start_job.priceBeforeEnd"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        error={errors.pro_live?.start_job?.priceBeforeEnd}
                        label="Tuition before end of course"
                        fullWidth
                        variant="standard"
                        type="number"
                      />
                    )}
                  />
                </div>
              </div> */}
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setCheckboxes((p) => ({ ...p, pro_live: false }));
                  setDialogs((p) => ({ ...p, pro_live: false }));
                  setValue("pro_live.selected", false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const live_mP = await trigger("pro_live.full_upfront.price");
                  const full_upfront = getValues(
                    "pro_live.full_upfront.status"
                  );
                  const monthly = getValues("pro_live.monthly.status");

                  const monthly_mP = full_upfront
                    ? await trigger("pro_live.monthly.monthlyPayment")
                    : true;

                  const monthly_NOM = monthly
                    ? await trigger("pro_live.monthly.numberOfMonth")
                    : true;

                  // const partialM_mP = await trigger(
                  //   "pro_live.partial_monthly.monthlyPayment"
                  // );
                  // const partialM_NOM = await trigger(
                  //   "pro_live.partial_monthly.numberOfMonth"
                  // );
                  // const partialM_PBS = await trigger(
                  //   "pro_live.partial_monthly.priceBeforeStart"
                  // );
                  // const partialM_PAJ = await trigger(
                  //   "pro_live.partial_monthly.priceAfterJob"
                  // );
                  // const partialM_PBE = await trigger(
                  //   "pro_live.partial_monthly.priceBeforeEnd"
                  // );

                  // const startJ_mP = await trigger(
                  //   "pro_live.start_job.monthlyPayment"
                  // );
                  // const startJ_NOM = await trigger(
                  //   "pro_live.start_job.numberOfMonth"
                  // );
                  // const startJ_PBS = await trigger(
                  //   "pro_live.start_job.priceBeforeStart"
                  // );
                  // const startJ_PAJ = await trigger(
                  //   "pro_live.start_job.priceAfterJob"
                  // );
                  // const startJ_PBE = await trigger(
                  //   "pro_live.start_job.priceBeforeEnd"
                  // );

                  const isValid = live_mP && monthly_mP && monthly_NOM;
                  // && partialM_mP &&
                  // partialM_NOM &&
                  // partialM_PBS &&
                  // partialM_PAJ &&
                  // partialM_PBE &&
                  // startJ_mP &&
                  // startJ_NOM &&
                  // startJ_PBS &&
                  // startJ_PAJ &&
                  // startJ_PBE;

                  if (isValid) {
                    setDialogs((p) => ({
                      ...p,
                      pro_live: false,
                    }));
                    if (full_upfront || monthly) {
                      setCheckboxes((p) => ({ ...p, pro_live: true }));
                    } else {
                      setCheckboxes((p) => ({ ...p, pro_live: false }));
                    }
                    // }
                  }
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <label
            onClick={() => {
              setValue("pro_live.selected", true);
              setDialogs((p) => ({ ...p, pro_live: true }));
              setCheckboxes((p) => ({ ...p, pro_live: true }));
            }}
          >
            <h2>Pro Live</h2>
            Offer free content to your subscribers. Optionally, you can set an
            enrollment duration that will limit the time students have access to
            your content.
            <ul className={styles.list}>
              <li>Basic video lessons</li>
              <li>Exercises for practice</li>
              <li>Home work assignments</li>
              <li>Resources to download</li>
            </ul>
          </label>
        </div>

        <Button
          disabled={!isDirty}
          variant="contained"
          color="primary"
          type="Save"
          onClick={() => {
            console.log(errors);
          }}
        >
          Save
        </Button>
      </form>
    </>
  );
}

export default React.memo(PricingPlans);
