import { Button, Card, Typography } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../components/shared/LoadingPage";
import PdfViewer from "../../../components/shared/PdfViewer";
import { UseGetCourse, UseGetLesson } from "../../../hooks";
import context from "../module-detailed/utils/Context";
import "./style.scss";

export default function LessonDetailed() {
  const { courseId } = useParams();
  const { state, dispatch } = useContext(context);
  const [isExpired, setIsExpired] = useState({
    condition: false,
    message: null,
  });

  const lessons = UseGetLesson(state?.lesson);
  const { data: course } = UseGetCourse(courseId);

  useEffect(() => {
    dispatch({ type: "video", payload: null });
    setIsExpired(false);
    if (state.lesson) {
      lessons.mutateAsync({ courseId: course?.data?._id }).catch((err) => {
        setIsExpired({
          condition: true,
          message: err?.response?.data?.message,
        });
      });
    }
  }, [state.lesson]);

  const { data, isLoading } = lessons;

  const serverUrl = process.env.REACT_APP_API_URL;

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isExpired) {
    return (
      <Fragment>
        <div className="container">
          <Typography variant="p" color="red">
            {isExpired.message}
          </Typography>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="container">
        <h1 className="fw-600">{data?.data?.name}</h1>
        <p>{data?.data?.description}</p>
        <hr />
        <div className="mt-5">
          {data?.data?.files.map((f, id) => {
            if (f?.type === "text") {
              return (
                <div className="mb-4" key={id}>
                  <h1>{f?.title}</h1>
                  <p>{f?.description}</p>
                  <hr />
                </div>
              );
            }
            if (f?.type === "image") {
              return (
                <div className="w-100  mb-4" key={id}>
                  <img
                    style={{
                      maxWidth: "100%",
                      margin: "0 auto",
                      display: "block",
                    }}
                    src={serverUrl + "/files" + f?.src}
                  />
                  <hr />
                </div>
              );
            }
            if (f?.type === "video") {
              if (!state.video) {
                dispatch({
                  type: "video",
                  payload: f.src,
                });

                return;
              }
              if (state.video === f?.src) {
                return;
              }
              return (
                <div className=" mb-4" key={id}>
                  {/* <video
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px",
                              margin: "0 auto",
                              display: "block",
                            }}
                            src={serverUrl + "/files/stream" + f?.src}
                            controls
                          /> */}

                  <div
                    id="frame-wrapper"
                    dangerouslySetInnerHTML={{ __html: f.src }}
                  ></div>
                  <hr />
                </div>
              );
            }
            if (f?.type === "pdf") {
              return (
                <div className=" mb-4" key={id}>
                  <PdfViewer fileUrl={f?.src} />
                  <hr />
                </div>
              );
            }
            if (f?.type === "link") {
              return (
                <div className="text-center mb-4" key={id}>
                  <Card
                    sx={{
                      width: "416px",
                      padding: "37px 18px",
                      margin: "auto",
                    }}
                    variant="contained"
                    color="primary"
                  >
                    <div className="d-flex text-center align-items-center justify-content-center flex-column">
                      <Typography
                        variant="body1"
                        className="mb-3"
                        letterSpacing={1.5}
                        fontWeight={600}
                      >
                        View Lesson Assignment Guide
                      </Typography>
                      <a href={f?.link} target="_blank">
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#5FD775",
                            padding: "12px 44px",
                          }}
                        >
                          <Typography variant="body2" fontSize="13px">
                            View
                          </Typography>
                        </Button>
                      </a>
                    </div>
                  </Card>

                  <hr />
                </div>
              );
            }
          })}
        </div>
      </div>
    </Fragment>
  );
}
