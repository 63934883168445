import * as React from "react";
import {
  GetOneApplication,
  UseUpdateTeacherApplication,
} from "../../../../hooks";
import Adminsidebar from "../../../../components/Adminsidebar";
import AdminTopnav from "../../../../components/AdminTopnav";
import Adminfooter from "../../../../components/Adminfooter";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";

import Loading from "../../../../components/shared/LoadingPage";
import { Link, useHistory, useParams } from "react-router-dom";
import TextWithLabel from "./components/TextWithLabel";
import { Breadcrumbs, Button } from "@mui/material";
import Alert from "./components/Alert";
import LoadingPage from "../../../../components/shared/LoadingPage";

export default function Single() {
  const { id } = useParams();
  const history = useHistory();
  const { data, isFetching, isLoading } = GetOneApplication(id);
  const [alert, setAlert] = React.useState(false);
  const serverUrl = process.env.REACT_APP_API_URL;
  const updateApplication = UseUpdateTeacherApplication(id);

  if (isLoading || isFetching) {
    return <LoadingPage />;
  }

  const handleAlert = async (type) => {
    if (type === "cancel") {
      return setAlert(false);
    }

    await updateApplication.mutateAsync({
      adminApproved: true,
    });
    setAlert(false);
    history.push("/teacher-application-admin");
  };

  return (
    <div className="container">
      <Breadcrumbs className="mb-5" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/teacher-application-admin">
          Teacher applications
        </Link>

        <Typography color="text.primary">
          {data?.data?.user?.name + " " + data?.data?.user?.surname}
        </Typography>
      </Breadcrumbs>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Box component="div">
            <TextWithLabel label="Name" text={data?.data?.user?.name} />
            <br />
            <TextWithLabel label="Surname" text={data?.data?.user?.surname} />
            <br />
            <TextWithLabel label="About him/her" text={data?.data?.about} />
            <br />
            <TextWithLabel
              label="Experience"
              text={
                data?.data?.experience <= 2
                  ? data?.data.experience + " year"
                  : data?.data.experience + " years"
              }
            />
            <br />
            <TextWithLabel label="Surname" text={data?.data?.user?.surname} />
            <br />

            <Button className="bg-primary cursor-pointer" variant="contained">
              <a
                href={serverUrl + "/files/" + data?.data?.resume?.src}
                download
                className="text-white  p-2 d-inline-block"
              >
                Download resume
              </a>
            </Button>

            <Button
              className="bg-info cursor-pointer p-2 text-white ml-2"
              variant="contained"
              onClick={() => setAlert(true)}
            >
              Approve to platform
            </Button>
          </Box>
        </div>

        <div className="col-sm-12 col-md-6">
          <Typography variant="h5">
            Services which he/she can provide:
          </Typography>
          <Box className="mt-3">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={data?.data?.createVideoLesson} />}
                label="Create pre-recorded video lessons"
              />

              <FormControlLabel
                control={<Checkbox checked={data?.data?.mentorSupport} />}
                label="Provide mentor support"
              />

              <FormControlLabel
                control={<Checkbox checked={data?.data?.liveOneOnOneLesson} />}
                label="Provide live one-on-one lessons"
              />

              <FormControlLabel
                control={<Checkbox checked={data?.data?.groupLesson} />}
                label="Provide live group sessions"
              />
              <FormControlLabel
                control={<Checkbox checked={data?.data?.interviewSupport} />}
                label="Interview preparation support"
              />
              <FormControlLabel
                control={<Checkbox checked={data?.data?.jobMarketSupport} />}
                label="Job Market preparations support"
              />
            </FormGroup>
          </Box>
          <Alert fn={handleAlert} alert={alert} />
        </div>
      </div>
    </div>
  );
}
