import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import LoadingPage from "../../../components/shared/LoadingPage";
import { UseSearchByKeyword } from "../../../hooks";

export default function SearchPage() {
  const location = useLocation();
  const keyword = new URLSearchParams(location.search);
  const serverUrl = process.env.REACT_APP_API_URL;

  const { data, isLoading, refetch } = UseSearchByKeyword(keyword.toString(), {
    search: keyword.get("key"),
  });

  useEffect(() => refetch(), [keyword.get("key")]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="container">
      <h1>
        {data.data?.length} results for "{keyword.get("key")}"
      </h1>
      <div className="row">
        {data.data &&
          data.data.map((d, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-4 col-sm-6 p-4 rounded-0 posr"
            >
              <div
                style={{ height: "350px" }}
                className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card"
              >
                <div
                  style={{ height: "50%" }}
                  className="card-image w-100 mb-3"
                >
                  <Link
                    to={`/courses/detailed/${d?._id}`}
                    className="video-bttn position-relative d-block"
                    style={{ height: "100%" }}
                  >
                    <img
                      src={serverUrl + "/files/" + d?.imageUrl?.src}
                      alt="course"
                      className="w-100"
                      style={{
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                </div>

                <div className="card-body pt-0">
                  <div
                    className={`font-xsssss text-center fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2  ${d?.status}`}
                  >
                    {d?.category?.name}
                  </div>

                  <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                    <Link
                      to={`/courses/detailed/${d?._id}`}
                      className="text-dark text-grey-900"
                    >
                      {d?.name}
                    </Link>
                  </h4>
                  <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                    {d?.stages?.length} Stage
                  </h6>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
