import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import {
  UseGetCourse,
  UsePatchLesson,
  UsePostLesson,
  UsePostLessonFile,
  UsePostLessonFileVimeo,
  UseGetOnboardingForTeacher,
  UseUpdateOnboardingForTeacher,
} from "../../../../../hooks";
import Info from "./components/Info";
import StageList from "./components/stage/StageList";
import CreateStage from "./components/stage/create";

const EditModule = () => {
  const { id } = useParams();
  const { data, refetch } = UseGetCourse(id);

  const [selectedStage, setSelectedStage] = useState();

  useEffect(() => {
    setSelectedStage(data?.data?.stages[0]?._id);
  }, [data]);

  const {
    reset: resetLesson,
    control: controlLesson,
    handleSubmit: handleSubmitLesson,
  } = useForm({
    defaultValues: {
      description: "",
      name: "",
      status: "",
      plans: [],
    },
  });

  const {
    reset: resetFile,
    register: registerFile,
    handleSubmit: handleSubmitFile,
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const defaultValues = {
    open: false,
    type: "",
    renameModule: { title: "", moduleId: "" },
    lesson: { modulelId: "", id: "", data: {}, courseId: "" },
    courseId: id,
  };
  const [info, setInfo] = useState(defaultValues);

  const postLesson = UsePostLesson();
  const patchLesson = UsePatchLesson(info?.lesson?.id);
  const postLessonFile = UsePostLessonFile();
  const postLessonFileVimeo = UsePostLessonFileVimeo();
  const updateOnboarding = UseUpdateOnboardingForTeacher();

  const { data: onboarding } = UseGetOnboardingForTeacher();

  useEffect(() => {
    if (!onboarding?.data?.goModules) {
      updateOnboarding.mutateAsync({
        goModules: true,
      });
    }
  }, []);

  const onSubmit = (formData, type) => {
    switch (type) {
      case "create-lesson":
        postLesson
          .mutateAsync({
            ...formData,
            module: info?.lesson?.modulelId,
            plans: formData?.plans?.map((p) => p?.value),
          })
          .then((data) => {
            refetch();
            setInfo({
              ...defaultValues,
              lesson: {
                ...info.lesson,
                id: data?._id,
                courseId: data.courseId,
              },
              type: "info-lesson",

              open: true,
            });
            // window.location.reload();
            setInfo(defaultValues);
            refetch();
          })
          .catch((err) => {
            alert(err);
          });
        break;

      case "edit-lesson":
        patchLesson
          .mutateAsync({
            ...formData,
            plans: formData?.plans?.map((p) => p?.value),
          })
          .then(() => {
            setInfo(defaultValues);
            refetch();
          })
          .catch((err) => {
            alert(err);
          });
        break;

      case "new-lesson-file":
        postLessonFile
          .mutateAsync({ ...formData, lessonId: info?.lesson?.id })
          .then(() => {
            refetch();
            setInfo(defaultValues);
          })
          .catch((err) => {
            alert(err);
          });
        break;

      case "new-lesson-link":
        postLessonFile
          .mutateAsync({ ...formData, lessonId: info?.lesson?.id })
          .then(() => {
            refetch();
            setInfo(defaultValues);
          })
          .catch((err) => {
            alert(err);
          });
        break;

      case "post-image":
        postLessonFile
          .mutateAsync(formData)
          .then(() => {
            refetch();
            setInfo(defaultValues);
          })
          .catch((err) => {
            alert(err);
          });
        break;

      case "post-pdf":
        postLessonFile
          .mutateAsync(formData)
          .then(() => {
            refetch();
            setInfo(defaultValues);
          })
          .catch((err) => {
            alert(err);
          });
        break;

      case "post-video":
        postLessonFileVimeo
          .mutateAsync(formData)
          .then(() => {
            refetch();
            setInfo(defaultValues);
            window.location.reload();
          })
          .catch((err) => {
            alert(err);
          });
        break;
    }
  };

  if (!data?.data?.stages?.length) {
    return (
      <CreateStage
        data={{
          info: {
            courseId: data?.data?._id,
          },
          stage: {
            refetch,
          },
          defaultValues,
          setInfo,
        }}
      />
    );
  }

  return (
    <div className="container px-3 py-4">
      <Box sx={{ flexGrow: 1 }}>
        <Grid alignItems="top" container spacing={2}>
          <Grid item xs={12} md={4}>
            <StageList
              data={{
                defaultValues,
                info,
                setInfo,
                stage: data?.data,
                resetLesson,
                courseId: data?.data?._id,
                onboarding,
                refetch,
                setSelectedStage,
              }}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            {info.open && (
              <Info
                data={{
                  info,
                  onSubmit,
                  setInfo,
                  defaultValues,
                  module: {
                    setInfo,
                  },
                  lesson: {
                    resetLesson,
                    control: controlLesson,
                    handleSubmitLesson,
                  },
                  file: {
                    resetFile,
                    registerFile,
                    handleSubmitFile,
                  },
                  stage: {
                    refetch,
                    selectedStage,
                  },
                  onboarding,
                }}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <div style={{ gap: "10px" }} className="d-flex">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (!onboarding?.data?.clickAddModule) {
                    updateOnboarding.mutateAsync({
                      clickAddModule: true,
                    });
                  }
                  setInfo({
                    info,
                    open: true,
                    type: "create-stage",
                    courseId: data?.data?._id,
                  });
                }}
                className=" border-0"
              >
                Add stage
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default EditModule;
