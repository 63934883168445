export const CARD_STYLE = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#222e3c",
      color: "#222e3c",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe Ui, sans-serif",
      fontSize: "20px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#000" },
      "::placeholder": { color: "#222e3c" },
    },
    invalid: {
      iconColor: "#ffc7eee",
      color: "#ffc7eee",
    },
  },
};
