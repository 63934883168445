import { useEffect } from "react";
import { Button } from "react-bootstrap";

export default function InputText({ setAction, data }) {
  useEffect(() => {
    data.file.resetFile({ type: "text" });
  }, []);
  return (
    <div>
      <h1>Add new material:</h1>
      <form
        onSubmit={data?.file?.handleSubmitFile((d) => {
          data?.onSubmit(d, "new-lesson-file");
          data?.file.resetFile();
        })}
      >
        <label htmlFor="description" className="form-label">
          Title
        </label>
        <input
          name="form_name"
          className="form-control form_control"
          type="text"
          placeholder="Property Title *"
          {...data?.file?.registerFile("title", {
            required: true,
          })}
        />

        <label htmlFor="description" className="form-label">
          Description
        </label>
        <textarea
          name="form_message"
          className="form-control h150"
          placeholder="Stage Description *"
          {...data?.file?.registerFile("description")}
        />

        <Button className="mt-2 border-0 bg-success" type="submit">
          Add Material
        </Button>
        <Button
          onClick={() => {
            data?.file.resetFile();
            setAction("type");
          }}
          className="mt-2 ml-2 bg-danger border-0 "
          type="submit"
        >
          Back
        </Button>
      </form>
    </div>
  );
}
