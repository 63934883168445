import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Lessons from "./Lessons";
import styles from "./lessons.module.scss";
import dragIcon from "./drag.svg";
import { UseUpdateModuleOrder } from "../../../../../../../hooks";

export default function ModuleList({ data }) {
  const [characters, updateCharacters] = useState();
  useEffect(() => {
    updateCharacters(data?.modules);
  }, [data]);
  const updateModuleOrder = UseUpdateModuleOrder();
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCharacters(items);
    const data = items.map((item, index) => ({
      _id: item?._id,
      weight: index,
    }));

    updateModuleOrder.mutateAsync(data);
  }

  if (!characters?.length) return "No modules yet ";

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="characters">
        {(provided) => (
          <div
            className={styles.items}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {characters?.map((item, i) => {
              return (
                <Draggable key={item?._id} draggableId={item?._id} index={i}>
                  {(providedInner) => (
                    <Accordion
                      ref={providedInner.innerRef}
                      {...providedInner.draggableProps}
                      key={i}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          onClick={() => {
                            data?.setInfo({
                              ...data.info,
                              open: true,
                              type: "rename-module",
                              renameModule: {
                                moduleId: item._id,
                                title: item.name,
                              },
                            });
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: "15px",
                            }}
                            {...providedInner.dragHandleProps}
                          >
                            <img
                              style={{
                                height: "15px",
                                width: "15px",
                                cursor: "move",
                              }}
                              src={dragIcon}
                            />
                          </span>
                          {item.name}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails style={{ flexDirection: "column" }}>
                        <Lessons lessons={item?.lessons} data={data} />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            data.setInfo({
                              ...data.defaultValues,
                              lesson: {
                                ...data.info.lesson,
                                modulelId: item?._id,
                              },
                              open: true,
                              type: "create-lesson",
                            });
                            data.resetLesson();
                          }}
                          className=" border-0"
                        >
                          Add Lesson
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Draggable>
              );
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
