import React from "react";
import Todo from "./Todo";

export default function Modules({ data }) {
  return (
    <div style={{ height: "220px" }} className="mt-5">
      <Todo
        text="Go to Modules, Lessons page"
        number="1"
        isDone={data?.goModules}
      />
      <Todo
        text="Click the Add Module button"
        number="2"
        isDone={data?.clickAddModule}
      />
      <Todo
        text="Write the name of the module"
        number="3"
        isDone={data?.clickCreateModule}
      />
      <Todo
        text="Click the Create button"
        number="4"
        isDone={data?.clickCreateModule}
      />
    </div>
  );
}
