import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, DialogActions, DialogContent } from "@mui/material";

function Alert({ alertOpen, setAlertOpen }) {
  return (
    <Dialog className="dialog" open={alertOpen} maxWidth="sm">
      <div style={{ padding: "15px", width: "400px" }}>
        <DialogContent>
          <div style={{ fontSize: "30px", marginBottom: "20px" }}>
            <CheckCircleOutlineIcon
              sx={{ transform: "scale(3)" }}
              color="success"
            />
          </div>

          <Typography variant="body1" color="inherit">
            Thank you for submitting your application! We will review it within
            5 business days and send you an email about the status of your
            application!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={() => setAlertOpen(false)}
            variant="contained"
            color="success"
          >
            Ok, understand!
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default Alert;
