import React, { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { UsePostDemo } from "../../../../../hooks";
import { useHistory, useParams } from "react-router-dom";
import ErrorMessage from "../../../../../components/shared/ErrorMessage";
import WYSIWYGEditor from "../../../../../shared-components/WYSIWYGEditor";
import EditWYSIWYG from "./components/EditWYSIWG";
import Alert from "./components/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AddNewImage from "./components/Image";
import AddNewVideo from "./components/Video";
import { VimeoFileUploader } from "../../../../../components/shared/VimeoFileUploader";
import { AlertMessage } from "../../../../../components/shared/Alerts";

const LandingPage = ({ data, refetch }) => {
  const { id: courseId } = useParams();
  const history = useHistory();
  const { handleUploadFile, isUploading, progress } = VimeoFileUploader();

  const schema = yup.object().shape({
    level: yup.string().required(),
    language: yup.string().required(),
    aboutCourse: yup.string(),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("level", data?.demo?.level || "beginner");
    setValue("language", data?.demo?.language || "english");
    setValue("aboutCourse", data?.demo?.aboutCourse);
  }, []);

  const [alert, setAlert] = useState(false);

  const [selectedImage, setSelectedImage] = React.useState();
  const [previewImage, setPreviewImage] = React.useState();
  const [selectedVideo, setSelectedVideo] = React.useState();
  const [previewVideo, setPreviewVideo] = React.useState();

  React.useEffect(() => {
    if (!selectedImage) {
      setPreviewImage(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewImage(objectUrl);

    () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  React.useEffect(() => {
    if (!selectedVideo) {
      setPreviewVideo(undefined);
      return;
    }
    const videoUrl = URL.createObjectURL(selectedVideo);

    setPreviewVideo(videoUrl);

    () => URL.revokeObjectURL(videoUrl);
  }, [selectedVideo]);

  const onSelectVideo = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedVideo(undefined);
      return;
    }

    setSelectedVideo(e.target.files[0]);
  };

  const onSelectImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    setSelectedImage(e.target.files[0]);
  };
  const postDemo = UsePostDemo();
  const alertMessage = new AlertMessage();

  const onSubmit = (data) => {
    const formData = new FormData();

    selectedImage &&
      formData.append("file", selectedImage, selectedImage?.name);

    if (selectedVideo) {
      handleUploadFile(selectedVideo)
        .then((res) => {
          formData.append(
            "content",
            JSON.stringify({ ...data, videoLink: res, courseId })
          );
          postDemo
            .mutateAsync(formData)
            .then(() => {
              alertMessage.success("Successfully updated!!");
              refetch();
            })
            .catch((error) => alertMessage.error(error));
        })
        .catch((error) => alertMessage.error(error));
    } else {
      formData.append("content", JSON.stringify({ ...data, courseId }));
      postDemo
        .mutateAsync(formData)
        .then(() => {
          alertMessage.success("Successfully updated!!");
          refetch();
        })
        .catch((error) => alertMessage.error(error));
    }
  };

  const serverUrl = process.env.REACT_APP_API_URL;

  return (
    <div className="container px-3 py-4">
      <div className="row">
        <div className="col-lg-12 d-flex mb-4">
          <h2 className="text-grey-900 font-md fw-700">
            Course Landing Page Information
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
              <div className="card-body d-block">
                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <div
                      className="form-group mb30 w-100"
                      style={{ postion: "relative" }}
                    >
                      <label htmlFor="level" className="form-label">
                        Level
                      </label>

                      <Controller
                        control={control}
                        name="level"
                        render={({ field }) => (
                          <select
                            id="level"
                            className=" mb-0  form-control form_control select-custom w-100"
                            {...field}
                          >
                            <option value={"beginner"}>Beginner</option>

                            <option value={"intermediate"}>Intermediate</option>

                            <option value={"advanced"}>Advanced</option>
                          </select>
                        )}
                      />
                    </div>
                    <ErrorMessage error={errors?.course?.category?.message} />
                  </div>

                  <div className="col-md-6 d-flex align-items-center">
                    <div
                      className="form-group mb30 w-100"
                      style={{ postion: "relative" }}
                    >
                      <label htmlFor="language" className="form-label">
                        Language
                      </label>

                      <Controller
                        control={control}
                        name="language"
                        render={({ field }) => (
                          <select
                            id="language"
                            className=" mb-0  form-control form_control select-custom w-100"
                            {...field}
                          >
                            <option defaultChecked disabled>
                              Select language
                            </option>
                            <option value={"english"}>English</option>

                            <option value={"russian"}>Russian</option>

                            <option value={"uzbek"}>Uzbek</option>
                          </select>
                        )}
                      />
                    </div>
                    <ErrorMessage error={errors?.language?.message} />
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="aboutCourse" className="form-label">
                        What is this Course about?
                      </label>

                      <Controller
                        name="aboutCourse"
                        control={control}
                        render={({ field }) => {
                          return data?.demo?.aboutCourse ? (
                            <EditWYSIWYG
                              textData={data?.demo?.aboutCourse}
                              {...field}
                            />
                          ) : (
                            <WYSIWYGEditor {...field} />
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="d-block col-12">
                    <div className="form-group mb30">
                      <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                        Cover Image
                      </h4>
                      <AddNewImage
                        onSelectFile={onSelectImage}
                        preview={
                          previewImage ||
                          (data?.demo?.photo?.src
                            ? serverUrl + "/files/" + data?.demo?.photo?.src
                            : undefined)
                        }
                      />

                      <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                        Promo video
                      </h4>
                      <AddNewVideo
                        onSelectFile={onSelectVideo}
                        preview={previewVideo}
                        data={data?.demo?.videoLink}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-content-end">
                      <div>
                        <Button
                          variant="contained"
                          className="bg-danger text-white mr-2"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Alert alert={isUploading} progress={progress} />
    </div>
  );
};

export default LandingPage;
