import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Controller, useFormContext } from "react-hook-form";

import { UseGetStudents } from "../../../../hooks/index";

function Alert({ dialog, setDialog, defaultValues }) {
  const { data } = UseGetStudents();

  const { control, handleSubmit, onSubmit, setValue } = useFormContext();

  return (
    <Dialog className="dialog" open={dialog.open} fullWidth maxWidth="sm">
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        <AppBar
          position="static"
          style={{ backgroundColor: "#222e3c" }}
          elevation={0}
        >
          <Toolbar className="flex w-full">
            <Typography variant="subtitle1" color="inherit">
              Open stage for student
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Student</InputLabel>
            <Controller
              control={control}
              name="studentId"
              render={({ field }) => (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  {...field}
                >
                  {data?.data.map((d, id) => (
                    <MenuItem key={id} value={d?._id}>
                      {(d?.name || "") +
                        " " +
                        (d?.surname || "") +
                        " -- " +
                        d?.email}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <div className="pl-16">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setDialog(defaultValues);
                setValue("studentId", "");
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </div>

          <div className="pl-16">
            <Button type="submit" variant="contained">
              Confirm
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Alert;
