import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import {
  UseGetCourse,
  UseGetOnboardingForTeacher,
  UsePatchCourse,
  UseUpdateOnboardingForTeacher,
} from "../../../../../hooks";

const PublishCourse = () => {
  const { id } = useParams();
  const {
    data: courseData,
    isLoading,
    refetch: courseRefetch,
  } = UseGetCourse(id);
  const patchCourse = UsePatchCourse(id);

  const handlePublish = () => {
    patchCourse
      .mutateAsync({ isPublished: true, status: "staging" })
      .then(() => {
        courseRefetch();
      });
  };

  const updateOnboarding = UseUpdateOnboardingForTeacher();

  const { data: onboarding } = UseGetOnboardingForTeacher();

  React.useEffect(() => {
    if (!onboarding?.data?.goPublish) {
      updateOnboarding.mutateAsync({
        goPublish: true,
      });
    }
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="d-flex justify-content-between align-items-center mt-3">
      {!courseData?.data?.isPublished ||
      courseData?.data?.status === "rejected" ? (
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              handlePublish();
              if (!onboarding?.data?.clickPublish) {
                updateOnboarding.mutateAsync({
                  clickPublish: true,
                });
              }
            }}
            variant="contained"
            color="primary"
          >
            Publish course
          </Button>
        </Box>
      ) : (
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <h1>
            Thank you for publishing your course! We will review your publishing
            request as soon as possible!
          </h1>
        </Box>
      )}
    </div>
  );
};
export default PublishCourse;
