import { forwardRef } from "react";
import { useParams } from "react-router-dom";
import { InputAdornment, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { UseCreateCoupon } from "../../../../../hooks";
import { generateCouponCode } from "../../../../../utils/couponCodeGenerator";
import ErrorMessage from "../../../../../components/shared/ErrorMessage";

import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@material-ui/core";
import { AlertMessage } from "../../../../../components/shared/Alerts";

const endDate = new Date();
endDate.setDate(endDate.getDate() + 30);
endDate.setHours(0, 0, 0);

const startDate = new Date();

startDate.setHours(0, 0, 0);

export default function Create({ setLocation, defaultValues, id }) {
  const schema = yup.object().shape({
    code: yup.string().required(),
    percentage: yup.number().required(),
    startDate: yup.date(),
    endDate: yup.date(),
    redemptions: yup.string(),
  });

  const createCoupon = UseCreateCoupon();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      code: generateCouponCode(20),
      startDate,
      endDate,
    },
  });

  const StartDate = forwardRef(({ value, onClick }, ref) => (
    <TextField
      fullWidth
      label="Start date *"
      variant="outlined"
      value={value}
      onClick={onClick}
      ref={ref}
    />
  ));

  const EndDate = forwardRef(({ value, onClick }, ref) => (
    <TextField
      fullWidth
      label="End date *"
      variant="outlined"
      value={value}
      onClick={onClick}
      ref={ref}
    />
  ));
  const alert = new AlertMessage();

  const onSubmit = (data) => {
    createCoupon
      .mutateAsync({
        ...data,
        course: id,
      })
      .then(() => {
        alert.success("Successfully Created!");
        setLocation(defaultValues);
      })
      .catch((err) => alert.error(err.message));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col col-md-6">
          <Controller
            name="code"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  label="Code *"
                  variant="outlined"
                  {...field}
                />
              );
            }}
          />
          <ErrorMessage error={errors?.code?.message} />
        </div>
        <div className="col col-md-6">
          <Controller
            name="percentage"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  label="Percentage *"
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 0, max: 100 }}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              );
            }}
          />
          <ErrorMessage error={errors?.percentage?.message} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col col-6">
          <Controller
            name="redemptions"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                type="number"
                inputProps={{ min: 0, max: 100 }}
                label="Redemptions"
                variant="outlined"
                {...field}
              />
            )}
          />
          <ErrorMessage error={errors?.redemptions?.message} />
        </div>
        <div className="col col-md-6">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, ...rest } }) => {
              return (
                <DatePicker
                  customInput={<StartDate />}
                  selected={value}
                  {...rest}
                />
              );
            }}
          />
          <ErrorMessage error={errors?.startDate?.message} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col col-md-6">
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value, ...rest } }) => {
              return (
                <DatePicker
                  customInput={<EndDate />}
                  selected={value}
                  {...rest}
                />
              );
            }}
          />
          <ErrorMessage error={errors?.endDate?.message} />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <Button
          onClick={() => setLocation(defaultValues)}
          className="mt-3"
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>

        <Button
          className="mt-3"
          variant="contained"
          color="primary"
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  );
}
