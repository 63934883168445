import { useHistory } from "react-router-dom";
import useSearchParams from "./useSearchParams";

function usePaginationWithUrl() {
  const history = useHistory();
  const searchParams = useSearchParams();
  const rowsPerPage = +searchParams.get("rowsPerPage") || 5;
  const page = +searchParams?.get("page") || 1;
  const setPage = (val) => {
    searchParams.set("page", val);
    history.push(`${history.location.pathname}?${searchParams.toString()}`);
  };

  const setRowsPerPage = (val) => {
    searchParams.set("rowsPerPage", val);
    history.push(`${history.location.pathname}?${searchParams.toString()}`);
  };

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  };
}

export default usePaginationWithUrl;
