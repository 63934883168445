import * as React from "react";
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../../../../../components/shared/ErrorMessage";
import { UsePatchStage } from "../../../../../../../hooks";

function EditStage({ data }) {
  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
  });
  const editStage = UsePatchStage(data?.info?.stageForEdit?._id);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  React.useEffect(() => {
    setValue("name", data?.info?.stageForEdit?.name);
    setValue("description", data?.info?.stageForEdit?.description);
  }, [data]);

  const onSubmit = (d) => {
    editStage
      .mutateAsync({
        ...d,
      })
      .then(() => {
        data?.stage?.refetch();
        data?.setInfo(data?.defaultValues);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Stage</DialogTitle>
        <DialogContent>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                margin="dense"
                id="name"
                error={errors?.name}
                label="Name"
                fullWidth
                variant="standard"
              />
            )}
          />
          <ErrorMessage error={errors?.name?.message} />

          <Controller
            name="description"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                id="description"
                label="Description"
                error={errors?.description}
                fullWidth
                multiline
                variant="standard"
              />
            )}
          />
          <ErrorMessage error={errors?.description?.message} />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => closeModal()}>Cancel</Button> */}
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </>
  );
}
export default React.memo(EditStage);
