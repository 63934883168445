import React, { useEffect, useMemo } from "react";
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Alert from "./Alert";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { UseChangeSubscription } from "../../../hooks";
import { AlertMessage } from "../../../components/shared/Alerts";

export default function TableComponent({ data, refetch, setLoading }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const defaultValues = useMemo(() => ({
    open: false,
    selectedUser: null,
    status: null,
  }));

  const [alert, setAlert] = React.useState(defaultValues);

  const useChangeSubscription = UseChangeSubscription(
    alert?.selectedUser?.subscriptionId
  );

  useEffect(() => {
    if (useChangeSubscription.isLoading) {
      setLoading(true);
    }
  }, [useChangeSubscription.isLoading]);

  const handleAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const getStatus = (status) => {
    switch (status) {
      case "suspended":
        return (
          <button type="button" class="btn btn-danger">
            Suspended
          </button>
        );

      default:
        return (
          <button type="button" class="btn btn-success">
            Active
          </button>
        );
    }
  };

  const alertMessage = new AlertMessage();
  const handleAlert = (type) => {
    switch (type) {
      case "activate": {
        useChangeSubscription
          .mutateAsync({
            status: "active",
            monthlyBill: alert.selectedUser?.monthlyBill,
            customerId: alert.selectedUser?.customerId,
          })
          .then(() => {
            alertMessage.success("Successfully updated");
            refetch();
            setLoading(false);
          })
          .catch((err) => {
            alertMessage.error(err.message);
            refetch();
            setLoading(false);
          });
        return setAlert(false);
      }

      case "suspend":
        useChangeSubscription
          .mutateAsync({
            status: "suspended",
            monthlyBill: alert.selectedUser?.monthlyBill,
            customerId: alert.selectedUser?.customerId,
          })
          .then(() => {
            alertMessage.success("Successfully updated");
            refetch();
            setLoading(false);
          })
          .catch((err) => {
            alertMessage.error(err.message);
            refetch();
            setLoading(false);
          });
        return setAlert(false);

      case "cancel":
        return setAlert(false);

      default:
        return;
    }
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Next Bill</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((d, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>

                <TableCell align="center">
                  <Typography>{d?.user?.email}</Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography>
                    {new Date(d?.nextBill).toLocaleDateString()}
                  </Typography>
                </TableCell>
                <TableCell align="center">{getStatus(d?.status)}</TableCell>

                <TableCell align="center">
                  <li
                    onClick={() => {
                      setAlert({
                        open: true,
                        selectedUser: d,
                        status: d?.status,
                      });
                      handleClose();
                    }}
                    class="list-group-item cursor-pointer"
                  >
                    {d?.status === "suspended" ? "Activate" : "Suspend"}
                  </li>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Alert alert={alert} fn={handleAlert} />
    </>
  );
}
