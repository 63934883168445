import { useState } from "react";
import axios from "axios";
import * as tus from "tus-js-client";

class MediaUploaderVimeo {
  constructor() {
    this.serverUrl = process.env.REACT_APP_API_URL;
  }
  async upload(file, onProgress) {
    const presignedLinkResponse = await axios.post(
      `${this.serverUrl}/lesson/vimeo`,
      {
        size: file.size,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    const uploadURI = presignedLinkResponse.data.upload.upload_link;

    return new Promise((resolve, reject) => {
      const uploader = new tus.Upload(file, {
        uploadUrl: uploadURI,
        endpoint: uploadURI,
        onError: (error) => {
          reject(error);
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          onProgress?.(bytesUploaded, bytesTotal);
        },
        onSuccess: () => {
          resolve({
            data: presignedLinkResponse.data,
          });
        },
      });
      uploader.start();
    });
  }
}

export function VimeoFileUploader() {
  const [progress, setProgress] = useState(0);
  const [isUploading, setUploading] = useState(false);

  const handleUploadFile = async (file) => {
    if (file) {
      setProgress(0);
      setUploading(true);
      const uploader = new MediaUploaderVimeo();

      const videoMeta = await uploader.upload(
        file,
        (bytesUploaded, bytesTotal) => {
          setProgress((bytesUploaded * 100) / bytesTotal);
        }
      );

      setUploading(false);
      return videoMeta?.data?.embed?.html;
    }
  };
  return { handleUploadFile, progress, isUploading };
}
