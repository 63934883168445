import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

function Alert({ alert, setAlert, deleteFunc }) {
  return (
    <Dialog className="dialog" open={alert} fullWidth maxWidth="sm">
      <AppBar
        position="static"
        style={{ backgroundColor: "#222e3c" }}
        elevation={0}
      >
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            Are you sure to deleteCourse this course?
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogActions>
        <div className="pl-16">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setAlert(false);
            }}
            variant="contained"
          >
            Cancel
          </Button>
        </div>

        <div className="pl-16">
          <Button onClick={() => deleteFunc()} variant="contained">
            Yes delete
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default Alert;
