export function createEmbedFunction(videoLink) {
  return ` <div
    style="
      position: relative;
      width: 1px;
      min-width: 100%;
      padding-bottom: 56.25%;
    "
  >
    <iframe
      allow="autoplay"
      class="spotlightr"
      allowtransparency="true"
      style="width: 1px; min-width: 100%; height: 100%; position: absolute"
      allowfullscreen="true"
      src="${videoLink}?fallback=true"
      frameborder="0"
      scrolling="no"
      name="videoPlayer"
    >
    </iframe>
  </div>`;
}
