import axios from "axios";

const getAccessToken = () => {
  return `Bearer ${localStorage.getItem("access_token")}`;
};

const MainRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: {
      Authorization: getAccessToken(),
    },
  },
});

MainRequest.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response?.status === 401) {
      window.location.reload();
    }
    throw error;
  }
);

export default MainRequest;
