import { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// pages
import CourseList from "../pages/course/course-list";
import Blog from "../pages/blog/index";
import AddNewBlog from "../pages/blog/components/AddNewBlog";
import EditBlog from "../pages/blog/components/EditBlog";
import DetailedCourse from "../pages/course/detailed-course";
import CourseInfo from "../pages/course/course-info";
import OverviewInfo from "../pages/overview/student/overview-info";
import EditCourse from "../pages/course/edit-course";
import DetailedModule from "../pages/my-courses/module-detailed";
import LessonDetailed from "../pages/my-courses/lesson-detailed";
import { Purchase } from "../pages/purchase";
import Overview from "../pages/overview";
import MyCourses from "../pages/course/my-courses";
import Successfull from "../pages/purchase/success";
import Provide from "../utils/Provider";
import StudentsList from "../pages/overview/admin/StudentsList";
import TeachersList from "../pages/overview/admin/TeachersList";
import PurchasesList from "../pages/overview/admin/PurchasesList";
import Profile from "../pages/settings/profile";
import Onboarding from "../pages/onboarding";
import Category from "../pages/categories";
import AddNewCategory from "../pages/categories/components/AddNewCategory";
import EditCategory from "../pages/categories/components/EditCategory";
import Defaultsettings from "../pages/settings/default-settings/Defaultsettings";
import Pricingplans from "../pages/settings/pricing-plans/Pricingplans";
import Password from "../pages/settings/password/Password";
import MyCards from "../pages/settings/my-cards/MyCards";
import AdminTeacherApplication from "../pages/teacher-application/admin";
import SingleApplication from "../pages/teacher-application/admin/single";
import NotFound from "../pages/not-found";
import Adminsidebar from "../components/Adminsidebar";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";

// style
import styles from "./layout.module.scss";
import TeachersWithoutApplication from "../pages/teachers-without-application";
import SearchPage from "../pages/course/search-page";
import SubscribedUsers from "../pages/users";
import Schedule from "../pages/schedule";

export default function Layout() {
  return (
    <Fragment>
      <div id="wrapper">
        <Adminsidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />
            <div className={styles["main-content"]}>
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={Provide}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/schedule`}
                  component={Schedule}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/purchase/:id`}
                  component={Purchase}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/success`}
                  component={Successfull}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/courses`}
                  component={CourseList}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/my-courses`}
                  component={MyCourses}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/courses/detailed/:id`}
                  component={DetailedCourse}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/courses/info/:id`}
                  component={CourseInfo}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/overview/info/:id`}
                  component={OverviewInfo}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/courses/lesson/:id/:moduleId/:courseId`}
                  component={LessonDetailed}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/courses/module/:id/:stageId/:courseId`}
                  component={DetailedModule}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/courses/search`}
                  component={SearchPage}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/edit-course/:id`}
                  component={EditCourse}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/overview`}
                  component={Overview}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/students`}
                  component={StudentsList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/teachers`}
                  component={TeachersList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/purchases`}
                  component={PurchasesList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/profile`}
                  component={Profile}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog`}
                  component={Blog}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/add-new`}
                  component={AddNewBlog}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/edit/:id`}
                  component={EditBlog}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/onboarding`}
                  component={Onboarding}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/category`}
                  component={Category}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/category/add-new`}
                  component={AddNewCategory}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/settings`}
                  component={Defaultsettings}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/category/edit/:id`}
                  component={EditCategory}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/settings/account-information`}
                  component={Profile}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/settings/pricing-plans`}
                  component={Pricingplans}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/settings/password`}
                  component={Password}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/settings/cards`}
                  component={MyCards}
                />

                <Route
                  exact
                  path="/teacher-application-admin/:id"
                  component={SingleApplication}
                />

                <Route
                  exact
                  path="/teacher-application-admin"
                  component={AdminTeacherApplication}
                />
                <Route
                  exact
                  path="/teachers-without-app-admin"
                  component={TeachersWithoutApplication}
                />
                <Route exact path="/users" component={SubscribedUsers} />
                <Route path="*" component={NotFound} />
              </Switch>
            </div>
            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
