import AddIcon from "@mui/icons-material/Add";
import { Box, CardContent } from "@mui/material";
import styles from "./landing.module.scss";
import thumbnail from "./thumbnail.png";

const AddNewImage = ({ preview, onSelectFile, image }) => {
  return (
    <CardContent>
      <div className="flex justify-center items-center flex-col">
        <Box component="div" className={styles.wrapper}>
          <label htmlFor="image" className={styles.add}>
            <AddIcon />
          </label>

          <img
            src={preview || thumbnail}
            alt="image"
            className={styles.preview}
          />
          <input
            id="image"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={onSelectFile}
          />
        </Box>
      </div>
    </CardContent>
  );
};
export default AddNewImage;
