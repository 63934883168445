import SummarizeIcon from "@mui/icons-material/Summarize";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  Box,
  Button,
} from "@mui/material";

import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { UseCreateTeacherApplication } from "../../../hooks";

import { useHistory } from "react-router-dom";

export default function Create() {
  const schema = yup.object().shape({
    name: yup.string().required(),
    surname: yup.string().required(),
    experience: yup.number().positive().required().typeError(""),
    createVideoLesson: yup.boolean(),
    mentorSupport: yup.boolean(),
    liveOneOnOneLesson: yup.boolean(),
    groupLesson: yup.boolean(),
    interviewSupport: yup.boolean(),
    jobMarketSupport: yup.boolean(),
    phoneNumber: yup.string(),
    about: yup.string().required("about yourself is required field"),
    resume: yup
      .mixed()
      .test("file", "Resume is required", (value) => {
        if (!value) {
          return false;
        } else {
          return true;
        }
      })
      .test("fileSize", "The file is too large", (value) => {
        if (value) {
          return value[0].size <= 4000000; // max file size  4mb
        }
      }),
  });
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const createTeacherApplication = UseCreateTeacherApplication();

  const onSubmit = (d) => {
    const formData = new FormData();
    formData.append("file", d.resume[0], d.resume[0].name);
    delete d.resume;
    formData.append("file", JSON.stringify(d));
    createTeacherApplication
      .mutateAsync(formData)
      .then(() => {
        history.push("/teacher-application-success");
        setAlertOpen(true);
      })
      .catch((err) => console.log(err));
  };

  const file = useWatch({
    control,
    name: "resume",
  });

  return (
    <div>
      <div className="container">
        <Typography
          sx={{ textAlign: "center", marginTop: "30px" }}
          variant="h3"
        >
          Teacher application
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    label="Name *"
                    className="mt-4"
                    error={errors?.name}
                  />
                )}
              />
              <ErrorMessage error={errors?.name?.message} />
            </div>

            <div className="col-sm-12 col-md-6">
              <Controller
                control={control}
                name="surname"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    className="mt-4"
                    {...field}
                    label="Surname *"
                    error={errors?.surname}
                  />
                )}
              />
              <ErrorMessage error={errors?.surname?.message} />
            </div>

            <div className="col-sm-12 col-md-6">
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="text"
                    className="mt-4"
                    {...field}
                    label="Phone number"
                    error={errors?.phoneNumber}
                  />
                )}
              />
              <ErrorMessage error={errors?.phoneNumber?.message} />
            </div>

            <div className="col-sm-12 col-md-6">
              <Controller
                control={control}
                name="experience"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="number"
                    className="mt-4"
                    {...field}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    label="Experience *"
                    error={errors?.surname}
                  />
                )}
              />
              <ErrorMessage error={errors?.experience?.message} />
            </div>

            <div className="col-12">
              <Controller
                control={control}
                name="about"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="text"
                    className="mt-4"
                    {...field}
                    label="About youself *"
                    error={errors?.about}
                    multiline
                    rows={4}
                  />
                )}
              />
              <ErrorMessage error={errors?.about?.message} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6">
              <Typography sx={{ marginTop: "20px" }} variant="h5">
                What kind of trainings and/or services can you provide?
              </Typography>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="createVideoLesson"
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="Create pre-recorded video lessons"
                  />

                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="mentorSupport"
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="Provide mentor support"
                  />

                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="liveOneOnOneLesson"
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="Provide live one-on-one lessons"
                  />

                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="groupLesson"
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="Provide live group sessions"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="interviewSupport"
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="Interview preparation support"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="jobMarketSupport"
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="Job Market preparations support"
                  />
                </FormGroup>
              </Box>
            </div>

            <div
              style={{ margin: "20px 0" }}
              className="col-sm-12 col-md-6 d-flex flex-column justify-content-center"
            >
              <Typography variant="h5">Your resume: *</Typography>
              <br />
              <label style={{ height: "100%" }} htmlFor="resume">
                <div
                  style={{
                    border: "1px dashed #000",
                    minHeight: "200px",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    padding: "20px",
                    gap: "10px",
                  }}
                >
                  {file && file[0] ? (
                    <>
                      <SummarizeIcon />
                      {file && file[0] && file[0].name}
                    </>
                  ) : (
                    <div>
                      <Typography variant="body1">
                        Upload your resume
                      </Typography>

                      <Typography variant="subtitle1">
                        Max file size: 4mb
                      </Typography>
                    </div>
                  )}
                </div>
              </label>
              <Controller
                name="resume"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    id="resume"
                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                  />
                )}
              />

              <ErrorMessage error={errors?.resume?.message} />

              <Button
                sx={{ marginTop: "30px", width: "max-content" }}
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
