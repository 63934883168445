import React from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import BigNumber from "bignumber.js";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { CARD_STYLE } from "./card-style";
import { UsePaymentIntent } from "../../../../hooks";
import countries from "./countries.json";
import ErrorMessage from "../../../../components/shared/ErrorMessage";
import { AlertMessage } from "../../../../components/shared/Alerts";
import CouponInput from "./Coupon";
import useButton from "../../../../hooks/UseButton";
import LoadingPage from "../../../../components/shared/LoadingPage";
import { getTariffName } from "./getTariffName";

export default function PaymentForm({ data, isError, refetch }) {
  const PaymentIntent = UsePaymentIntent();
  const stripe = useStripe();
  const elements = useElements();
  const alert = new AlertMessage();

  const [isDisabled, handleClick, open] = useButton();

  const schema = yup.object().shape({
    cardName: yup.string(),
    country: yup.string(),
    postal_code: yup.number().typeError(""),
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string(),
    phone: yup.string(),
    address: yup.string(),
    town: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const user = useSelector((data) => data?.auth?.user);
  const history = useHistory();

  const submitPurchase = async (d) => {
    handleClick();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: d.name,
        address: {
          country: d.country,
          postal_code: d?.postal_code,
        },
      },
    });
    if (!error && !isError) {
      const amount = new BigNumber(data?.amountWithCoupon);
      const usdToCent = new BigNumber(100);

      const { id } = paymentMethod;
      PaymentIntent.mutateAsync({
        payment_method: id,
        description: `payment from ${user?.email}`,
        name: d.name,
        email: user?.email,
        type: data?.tariff,
        course: data?.course?._id,
        amount: Number.isFinite(data?.amountWithCoupon)
          ? amount.times(usdToCent).toNumber()
          : data?.amount * 100,
      })
        .then(() => {
          alert.success("Successfully purchased!");
          history.push(`/courses/detailed/${data?.course?.link}`);
        })
        .catch((err) => {
          open();
          alert.error(err.message);
        });
    } else {
      open();
      console.error(error?.message || "Course is not found");
    }
  };

  if (PaymentIntent?.isLoading) {
    return <LoadingPage />;
  }

  return (
    <div style={{ height: "100%" }}>
      <form
        style={{ height: "inherit" }}
        onSubmit={handleSubmit(submitPurchase)}
      >
        <Grid spacing={3} container>
          <Grid sm={12} md={6} item>
            <div className="billing">
              <h4 className="mont-font fw-600 font-md mb-5">Billing address</h4>
              <form action="#">
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-500 font-xsss">
                        First Name
                      </label>
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                          <input
                            type="text"
                            name="comment-name"
                            className="form-control"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-500 font-xsss">
                        Last Name
                      </label>
                      <Controller
                        control={control}
                        name="lastName"
                        render={({ field }) => (
                          <input
                            type="text"
                            name="comment-name"
                            className="form-control"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-500 font-xsss">
                        Email
                      </label>
                      <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                          <input
                            type="text"
                            name="comment-name"
                            className="form-control"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-500 font-xsss">
                        Phone
                      </label>
                      <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                          <input
                            type="text"
                            name="comment-name"
                            className="form-control"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 mb-5">
                    <div className="form-gorup">
                      <FormControl className="mt-3" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel>
                        <Controller
                          control={control}
                          name="country"
                          render={({ field }) => (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Country"
                              fullWidth
                              {...field}
                            >
                              {countries.map((c, id) => (
                                <MenuItem key={id} value={c.code}>
                                  {c.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                      <ErrorMessage error={errors?.country?.message} />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-500 font-xsss">
                        Address
                      </label>
                      <Controller
                        control={control}
                        name="address"
                        render={({ field }) => (
                          <input
                            type="text"
                            name="comment-name"
                            className="form-control"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-500 font-xsss">
                        Town / City
                      </label>

                      <Controller
                        control={control}
                        name="town"
                        render={({ field }) => (
                          <input
                            type="text"
                            name="comment-name"
                            className="form-control"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-500 font-xsss">
                        Postcode
                      </label>
                      <Controller
                        control={control}
                        name="postal_code"
                        render={({ field }) => (
                          <input
                            type="number"
                            name="comment-name"
                            className="form-control"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Grid>

          <Grid sm={12} md={6} item>
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <div className="title">
                  <Typography className="mb-2" variant="h5">
                    Order Details
                  </Typography>
                </div>
                <Card sx={{ minWidth: 275 }} className="order-details">
                  <CardContent>
                    <div
                      style={{ borderBottom: "1px solid #cecccc" }}
                      className="d-flex justify-content-between align-items-center mt-2"
                    >
                      <Typography variant="h6">Course</Typography>
                      <Typography variant="h6">Plan</Typography>
                    </div>

                    <div
                      style={{ borderBottom: "1px solid #cecccc" }}
                      className="d-flex justify-content-between align-items-center mt-2"
                    >
                      <Typography variant="p">{data?.course?.name}</Typography>

                      <Typography variant="p">
                        {getTariffName(data?.tariff)}
                      </Typography>
                    </div>

                    {Number.isFinite(data?.amountWithCoupon) ? (
                      <>
                        <div className="d-flex justify-content-between align-items-center mt-5">
                          <Typography variant="h6">Subtotal:</Typography>
                          <Typography variant="h6">
                            <strike>{data?.amount}$</strike>
                          </Typography>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <Typography variant="h6">Discount:</Typography>
                          <Typography variant="h6">
                            -{data?.couponPercentage}%
                          </Typography>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <Typography variant="h6">Total:</Typography>
                          <Typography variant="h6">
                            {data?.amountWithCoupon}$
                          </Typography>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-between align-items-center mt-5">
                        <Typography variant="h6">Total:</Typography>
                        <Typography variant="h6">{data?.amount}$</Typography>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>

              <CouponInput refetch={refetch} />

              <div>
                <Typography className="mb-2" variant="h5">
                  Payment method
                </Typography>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Controller
                      control={control}
                      name="cardName"
                      render={({ field }) => (
                        <TextField
                          id="outlined-basic"
                          label="Name on Card"
                          fullWidth
                          variant="outlined"
                          className="mt-4"
                          {...field}
                        />
                      )}
                    />
                    <ErrorMessage error={errors?.name?.message} />

                    {/* <Controller
              control={control}
              name="postal_code"
              render={({ field }) => (
                <TextField
                  className="my-4"
                  id="outlined-basic"
                  label="Postal code"
                  fullWidth
                  type="number"
                  variant="outlined"
                  {...field}
                />
              )}
            /> 
            <ErrorMessage error={errors?.postal_code?.message} />*/}
                    <br />
                    <CardElement options={CARD_STYLE} />
                    <Button
                      className="mt-4"
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isDisabled}
                    >
                      Complete Checkout
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
