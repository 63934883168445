import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { AlertMessage } from "../../../../components/shared/Alerts";
import ErrorMessage from "../../../../components/shared/ErrorMessage";
import { UseUpdateCartWithCoupon } from "../../../../hooks";
import useButton from "../../../../hooks/UseButton";

export default function CouponInput({ refetch }) {
  const schema = yup.object().shape({
    code: yup.string().required(),
  });
  const [isDisabled, handleClick, open] = useButton();

  const { id } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const updateCartWithCoupon = UseUpdateCartWithCoupon(id);
  const alert = new AlertMessage();

  function onSubmit(data) {
    handleClick();
    updateCartWithCoupon
      .mutateAsync(data)
      .then(() => {
        refetch();
        alert.success("Coupon successfully added!");
      })
      .catch((err) => {
        alert.error(err?.response?.data?.message);
        open();
      });
  }

  return (
    <div className="my-3">
      <div className="title">
        <Typography className="mb-2" variant="h5">
          Coupon
        </Typography>
      </div>
      <Card sx={{ minWidth: 275 }} className="order-details">
        <CardContent>
          <form onSubmitCapture={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Controller
                control={control}
                name="code"
                render={({ field }) => (
                  <TextField label="Coupon code" fullWidth {...field} />
                )}
              />
            </div>
            <ErrorMessage error={errors?.code?.message} />
            <div className="d-flex justify-content-end">
              <Button
                disabled={isDisabled}
                type="submit"
                variant="contained"
                color="primary"
              >
                Check
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
