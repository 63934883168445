import { LoadingIcon } from "../../assets/loadingIcon";

export default function LoadingPage() {
  return (
    <div
      style={{ height: "70vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <LoadingIcon />
    </div>
  );
}
