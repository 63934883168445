import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  UseDeleteLesson,
  UsePostLessonFileVimeo,
  UseRemoveLessonFile,
  UseUpdateLessonFile,
} from "../../../../../../../hooks";
import { AlertMessage } from "../../../../../../../components/shared/Alerts";
import LinearProgressWithLabel from "../LinearProgress";
import { createEmbedFunction } from "../utils/createEmbedFunction";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const buttons = {
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
};

function ModalComponent({
  modal,
  setModal,
  defaultValues,
  refetch,
  handleCloseAlert,
}) {
  const alert = new AlertMessage();
  const postLessonFileVimeo = UsePostLessonFileVimeo();

  const [progress, setProgress] = React.useState(0);
  const [isUploading, setUploading] = React.useState(false);
  const dyntube = process.env.REACT_APP_DYNTUBE;
  const dyntubeVideoGroup = process.env.REACT_APP_DYNTUBE_VIDEO_GROUP;

  const updateLessonFile = UseUpdateLessonFile(modal?.id?.file);

  function handleUpload(file) {
    if (file) {
      let formData = new FormData();

      formData.append("file", file);
      formData.append("projectId", dyntubeVideoGroup);

      const axiosInstance = axios.create({
        baseURL: "https://upload.dyntube.com/v1/videos",
        headers: {
          Authorization: `Bearer ${dyntube}`,
        },
      });
      setUploading(true);
      axiosInstance
        .post("", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((response) => {
          postLessonFileVimeo
            .mutateAsync({
              src: createEmbedFunction(response.data?.iframeLink),
              lessonId: modal.id.lesson,
              videoId: response.data?.videoId,
              type: "video",
              onlyUpload: true,
            })
            .then((data) => {
              updateLessonFile
                .mutateAsync({ newFileId: data.data?._id, type: "video" })
                .then(() => setModal(defaultValues));
            });

          setUploading(false);
          refetch();
        })
        .catch((err) => {
          alert.error("Error in uploading!!");
          setUploading(false);
        });
    }
  }

  switch (modal.type) {
    case "lesson":
      const deleteLesson = UseDeleteLesson(modal.id);
      return (
        <div>
          <Modal
            open={modal.open}
            onClose={() => setModal(defaultValues)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are You sure delete this lesson?
              </Typography>
              <Box sx={buttons}>
                <Button
                  onClick={() => {
                    setModal(defaultValues);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    deleteLesson
                      .mutateAsync()
                      .then(() => {
                        setModal(defaultValues);
                        refetch();
                        alert.success("Deleted successfully!!");
                        handleCloseAlert();
                      })
                      .catch(() => alert.error("Error in delete!!"))
                  }
                  variant="contained"
                  className="bg-danger"
                >
                  Yes, delete
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      );

    case "delete-video":
      const deleteLessonFile = UseRemoveLessonFile(modal.id);
      return (
        <div>
          <Modal
            open={modal.open}
            onClose={() => setModal(defaultValues)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are You sure delete this lesson's file?
              </Typography>
              <Box sx={buttons}>
                <Button
                  onClick={() => {
                    setModal(defaultValues);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    deleteLessonFile
                      .mutateAsync()
                      .then(() => {
                        setModal(defaultValues);
                        refetch();
                        alert.success("Deleted successfully!!");
                        handleCloseAlert();
                      })
                      .catch(() => alert.error("Error in delete!!"))
                  }
                  variant="contained"
                  className="bg-danger"
                >
                  Yes, delete
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      );

    case "update-video":
      return (
        <Modal
          open={modal.open}
          onClose={() => setModal(defaultValues)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {isUploading ? (
              <LinearProgressWithLabel value={progress} />
            ) : (
              <div className="form-group m30">
                <div
                  className="file-upload-wrapper"
                  data-text="Select your file!"
                >
                  <input
                    name="file-upload-field"
                    type="file"
                    className="file-upload-field"
                    id="input-file"
                    accept="video/*"
                    onChange={(e) => {
                      e.stopPropagation();

                      handleUpload(e.target.files[0]);
                    }}
                  />
                  <label
                    htmlFor="input-file"
                    className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed theme-black-bg"
                  >
                    <i className="ti-cloud-down large-icon me-3 d-block"></i>
                    <span className="js-fileName">Click to upload video</span>
                  </label>
                </div>
              </div>
            )}

            <Button
              onClick={() => setModal(defaultValues)}
              // className="mt-2 ml-2 bg-danger border-0"
              variant="contained"
              color="secondary"
              type="submit"
            >
              Back
            </Button>
          </Box>
        </Modal>
      );
  }
}
export default React.memo(ModalComponent);
