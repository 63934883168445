import React, { useMemo, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import { Create, Edit } from "./Modal";

export default function Calendar({ events, refetch, id }) {
  const defaultValues = useMemo(
    () => ({
      open: false,
      type: null,
      data: null,
    }),
    []
  );
  const [modal, setModal] = useState(defaultValues);

  const handleEventDrop = ({ event, start, end }) => {
    // const updatedEvent = { ...event, start, end };
    // const updatedEvents = events.map((e) =>
    //   e.id === event.id ? updatedEvent : e
    // );
    // setEvents(updatedEvents);
  };

  const calendarOptions = {
    // events: store.events.length ? store.events : [],
    events,
    /*
          Enable dragging and resizing event
          ? Docs: https://fullcalendar.io/docs/editable
        */
    // editable: true,
    // eventDidMount: (info) => {
    //   const end = moment(info.event.end)
    //     .subtract(1, "day")
    //     .format("YYYY-MM-DD");
    //   const endEl = info.el.querySelectorAll(".fc-end");
    //   endEl.forEach((el) => el.setAttribute("data-date", end));
    //   info.el.classList.add("include-end");
    // },
    /*
          Enable resizing event from start
          ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
        */
    // eventResizableFromStart: true,

    /*
          Automatically scroll the scroll-containers during event drag-and-drop and date selecting
          ? Docs: https://fullcalendar.io/docs/dragScroll
        */
    // dragScroll: true,

    /*
          Max number of events within a given day
          ? Docs: https://fullcalendar.io/docs/dayMaxEvents
        */
    dayMaxEvents: 2,
    droppable: true,
    eventDrop: handleEventDrop,
    eventClick: handleEventClick,
    dateClick: handleDateClick,
  };

  function handleEventClick(info) {
    setModal((p) => ({ ...p, open: true, data: info, type: "edit" }));
  }

  function handleDateClick(info) {
    setModal((p) => ({ ...p, open: true, data: info, type: "create" }));
  }

  return (
    <>
      <FullCalendar
        height={600}
        headerToolbar={{
          end: "today prev,next",
          start: "title",
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        {...calendarOptions}
      />

      {modal.open && modal.type === "create" && (
        <Create
          id={id}
          refetch={refetch}
          modal={modal}
          setModal={setModal}
          defaultValues={defaultValues}
        />
      )}

      {modal.open && modal.type === "edit" && (
        <Edit
          id={id}
          refetch={refetch}
          modal={modal}
          setModal={setModal}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
}
