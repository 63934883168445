import React, { useEffect, useMemo, useState } from "react";
import { Pagination, Button, ButtonGroup } from "@mui/material";
import { useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import {
  GetCourses,
  UseGetOnboardingForTeacher,
  UseUpdateOnboardingForTeacher,
} from "../../../hooks/index";
import MainRequest from "../../../hooks/main-request/index";
import LoadingPage from "../../../components/shared/LoadingPage";
import EmptyCourse from "./components/Empty-course";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddCourseModal from "./add-course";
import usePaginationWithUrl from "../../../hooks/usePagination/usePagination";
import { UseCreateLinkForCourse } from "./hooks/useCreateLink";

const CourseList = (props) => {
  const user = useSelector((data) => data?.auth?.user);
  const history = useHistory();

  const { page, setPage, rowsPerPage } = usePaginationWithUrl();

  useEffect(() => {
    if (user.role === "student") {
      history.push("/my-courses");
    } else {
      history.push("/courses");
    }
  }, []);

  const [state, setState] = React.useState({ location: false });
  const [selectedCourse, setSelectedCourse] = useState(null);

  const { data, isLoading, refetch, isFetching } = GetCourses({
    size: rowsPerPage,
    page,
  });
  const [component, openModal] = UseCreateLinkForCourse({ refetch });
  const serverUrl = process.env.REACT_APP_API_URL;

  function handleModel(type) {
    if (type === "delete") {
      MainRequest.delete(serverUrl + "/course/" + selectedCourse?.link)
        .then((d) => {
          refetch();
        })
        .catch((err) => console.log(err));
      setSelectedCourse(null);
    }
    setState({ location: !state.location });
  }

  const handleChange = (e, p) => {
    setPage(p);
  };

  const defaultValues = useMemo(
    () => ({
      open: false,
      course: "",
    }),
    []
  );

  const [dialog, setDialog] = useState(defaultValues);
  const [addNewOpen, setAddNewOpen] = useState(false);

  const schema = yup.object().shape({
    comment: yup.string().required(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      comment: "",
    },
  });

  const { data: onboarding } = UseGetOnboardingForTeacher();
  const updateOnboarding = UseUpdateOnboardingForTeacher();

  useEffect(() => {
    if (!onboarding?.data?.goCourse) {
      updateOnboarding.mutateAsync({
        goCourse: true,
      });
    }
  }, []);

  const handlePublish = (id, data) => {
    MainRequest.patch(serverUrl + "/course/" + id, data)
      .then((d) => {
        refetch();
        setDialog(defaultValues);
        methods.setValue("comment", "");
      })
      .catch((err) => console.log(err));
  };

  if (isLoading || isFetching) {
    return <LoadingPage />;
  }

  return (
    <div className="container px-3 py-4">
      <div className="row">
        <div className="col-lg-12 mt-4">
          <>
            <div className="card border-0 mt-2 rounded-10">
              <div className="card-body row justify-content-between align-items-center px-4 pb-0 pt-4">
                <h4 className="font-xss col-4 col-md-3 text-grey-800 mt-3 fw-700">
                  Course List
                </h4>

                {user.role === "teacher" && (
                  <Button
                    onClick={() => {
                      updateOnboarding.mutateAsync({
                        clickNewCourse: true,
                      });
                      setAddNewOpen(true);
                    }}
                    variant="contained"
                    className=" border-0"
                  >
                    Add Course
                  </Button>
                )}
              </div>

              <div className="card-body p-4 w-100">
                <div className="table-responsive w-100">
                  {data?.data?.data?.length ? (
                    <table className="table table-admin mb-0 w-100">
                      <thead className="bg-greylight rounded-10 ovh">
                        <tr>
                          <th className="border-0" scope="col">
                            №
                          </th>
                          <th className="border-0" scope="col">
                            Name
                          </th>
                          <th className="border-0" scope="col">
                            Category
                          </th>
                          <th className="border-0" scope="col">
                            Status
                          </th>

                          {user.role === "admin" && (
                            <th scope="col" className="text-center border-0">
                              Course publish
                            </th>
                          )}
                          {(user.role === "teacher" ||
                            user.role === "admin") && (
                            <th scope="col" className="text-center  border-0">
                              Action
                            </th>
                          )}
                        </tr>
                      </thead>

                      <tbody className="w-100 ">
                        {data?.data?.data?.map((value, index) => (
                          <tr key={index}>
                            <td className="product-thumbnail text-start ps-0">
                              {++index}.
                            </td>

                            <td className="product-thumbnail text-start ps-0">
                              <b
                                onClick={() => {
                                  if (!value?.link) {
                                    openModal(value?._id);
                                  } else {
                                    history.push(
                                      `/courses/detailed/${value.link}`
                                    );
                                  }
                                }}
                                className="text-primary cursor-pointer"
                              >
                                {value.name}
                              </b>
                            </td>

                            <td>
                              <span
                                className={`font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 d-inline-block mr-1 ${value.category.color}`}
                              >
                                {value?.category?.name}
                              </span>
                            </td>

                            <td>
                              <span
                                className={`font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 d-inline-block mr-1 ${
                                  value?.status === "staging"
                                    ? "alert-warning"
                                    : value?.status === "rejected"
                                    ? "alert-danger"
                                    : "alert-success"
                                }`}
                              >
                                <b>{value?.status}</b>
                              </span>
                            </td>

                            {user.role == "admin" &&
                            value?.status === "staging" ? (
                              <td className="product-remove text-right">
                                <ButtonGroup>
                                  <Button
                                    onClick={() => {
                                      handlePublish(value?.link, {
                                        status: "published",
                                      });
                                    }}
                                  >
                                    Publish
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setDialog({
                                        open: true,
                                        course: value?._id,
                                      });
                                    }}
                                    color="error"
                                  >
                                    Reject
                                  </Button>
                                </ButtonGroup>
                              </td>
                            ) : user.role == "teacher" ? (
                              ""
                            ) : (
                              <td className="product-remove text-right"></td>
                            )}

                            {user.role === "admin" ||
                            user.role === "teacher" ? (
                              <td className="product-remove d-flex align-items-center justify-content-center">
                                <i
                                  onClick={() => {
                                    if (!value?.link) {
                                      openModal(value?._id);
                                    } else {
                                      history.push(
                                        `/edit-course/${value.link}`
                                      );
                                    }
                                  }}
                                  className="feather-edit mr-1 font-xs text-grey-500 cursor-pointer"
                                ></i>

                                {value?.status !== "published" && (
                                  <>
                                    <Button
                                      className="bg-transparent border-0"
                                      onClick={() => {
                                        setSelectedCourse(value);
                                        handleModel();
                                      }}
                                    >
                                      <i className="ti-trash  font-xs text-danger"></i>
                                    </Button>
                                    <Modal
                                      {...props}
                                      size="sm"
                                      aria-labelledby="contained-modal-title-vcenter"
                                      centered
                                      show={state.location}
                                    >
                                      <Button
                                        onClick={() => {
                                          handleModel();
                                        }}
                                        className="btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss"
                                      ></Button>
                                      <Modal.Body className="text-center p-4">
                                        <i className="ti-info-alt text-warning display4-size"></i>
                                        <p className="text-grey-500 font-xsss mt-3 mb-4">
                                          Are you sure you want to delete this
                                          course?
                                        </p>

                                        <Button
                                          onClick={() => {
                                            handleModel("delete");
                                          }}
                                          className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-white bg-danger font-xssss text-uppercase fw-600 ls-3"
                                        >
                                          Yes, delete!
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            handleModel();
                                          }}
                                          className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-grey-600 bg-greylight font-xssss text-uppercase fw-600 ls-3 ms-1"
                                        >
                                          No, cancel!
                                        </Button>
                                      </Modal.Body>
                                    </Modal>
                                  </>
                                )}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center">
                      <EmptyCourse />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="pt-5 d-flex justify-content-center">
              <Pagination
                onChange={handleChange}
                color="primary"
                count={
                  data?.data?.count / rowsPerPage < 1
                    ? 1
                    : Math.ceil(data?.data?.count / rowsPerPage)
                }
                page={page}
              />
            </div>
          </>
        </div>
      </div>
      {component}
      <AddCourseModal addNewOpen={addNewOpen} setAddNewOpen={setAddNewOpen} />
    </div>
  );
};

export default CourseList;
