import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "../module/lessons.module.scss";
import dragIcon from "./drag.svg";
import {
  UseUpdateOnboardingForTeacher,
  UseUpdateStageOrder,
} from "../../../../../../../hooks";
import ModuleList from "../module/ModuleList";

export default function StageList({ data }) {
  const [characters, updateCharacters] = useState(data?.stage?.stages);
  const updateStageOrder = UseUpdateStageOrder();
  const updateOnboarding = UseUpdateOnboardingForTeacher();

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCharacters(items);
  }

  useEffect(() => {
    const newList = characters?.map((item, index) => ({
      _id: item?._id,
      weight: index,
    }));
    updateStageOrder.mutateAsync(newList);
    data?.refetch();
  }, [characters]);

  useEffect(() => {
    updateCharacters(data?.stage?.stages);
  }, [data]);

  if (!characters?.length) return "No modules yet ";
  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <div
              className={styles.items}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {characters?.map((item, i) => {
                return (
                  <Draggable key={item?._id} draggableId={item?._id} index={i}>
                    {(providedInner) => (
                      <Accordion
                        ref={providedInner.innerRef}
                        {...providedInner.draggableProps}
                        key={i}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            onClick={() =>
                              data?.setInfo({
                                ...data?.defaultValues,
                                // info: data?.info,
                                open: true,
                                type: "edit-stage",
                                stageForEdit: item,
                              })
                            }
                          >
                            <span
                              style={{
                                display: "inline-block",
                                marginRight: "15px",
                              }}
                              {...providedInner.dragHandleProps}
                            >
                              <img
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  cursor: "move",
                                }}
                                src={dragIcon}
                              />
                            </span>
                            {`Stage-${i + 1}: ${item.name}`}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{ flexDirection: "column" }}>
                          <ModuleList
                            data={{
                              ...data,
                              modules: item?.modules,
                            }}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              if (!data?.onboarding?.data?.clickAddModule) {
                                updateOnboarding.mutateAsync({
                                  clickAddModule: true,
                                });
                              }
                              data?.setInfo((p) => ({
                                ...p,
                                open: true,
                                type: "new-module",
                                renameModule: {
                                  moduleId: "",
                                  title: "",
                                },
                              }));
                              data?.setSelectedStage(item?._id);
                            }}
                            className=" border-0"
                          >
                            Add Module
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Draggable>
                );
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
