import useQuery from "./use-query";
import useMutation from "./use-mutation";

const route = process.env.REACT_APP_API_URL;

const routes = {
  courses: `${route}/course`,
  login: `${route}/user/login`,
  user: `${route}/user`,
  register: `${route}/user/register`,
  forgotPassword: `${route}/user/forgot-password`,
  category: `${route}/category`,
  lessonFiles: `${route}/files/lesson`,
  lessonFilesVimeo: `${route}/files/lesson/vimeo`,
  files: `${route}/files`,
  course: `${route}/course`,
  stage: `${route}/stage`,
  module: `${route}/module`,
  lesson: `${route}/lesson`,
  purchase: `${route}/purchase`,
  customers: `${route}/customers`,
  admin: `${route}/admin`,
  blog: `${route}/blog`,
  statistics: `${route}/statistics`,
  onboarding: `${route}/onboarding`,
  category: `${route}/category`,
  cart: `${route}/cart`,
  teacherApplication: `${route}/teacher-application`,
  coupon: `${route}/coupon`,
  event: `${route}/event`,
  subscribe: `${route}/subscribe`,
};

//course
export const GetCourses = (params, options) => {
  return useQuery(routes.courses, params, { cacheTime: 0 });
};
export const GetOwnCourses = () => {
  return useQuery(routes.courses + "/my-courses");
};

export const UseGetCourse = (id, params) => {
  return useQuery(`${routes.course}/${id}`, params, {
    cacheTime: 0,
    notifyOnNetworkStatusChange: true,
  });
};
export const UsePostCourse = () => {
  return useMutation("post", routes.course);
};
export const UsePatchCourse = (id) => {
  return useMutation("patch", routes.course + "/" + id);
};

export const UseDeleteCourse = (id) => {
  return useMutation("delete", routes.course + "/" + id);
};

export const UseSelectCourse = () => {
  return useMutation("post", routes.course + "/select");
};
export const UsePostDemo = () => {
  return useMutation("post", routes.course + "/demo");
};
export const UseCreateCourseLink = (id) => {
  return useMutation("put", routes.course + "/" + id);
};

//auth
export const UseLogin = () => {
  return useMutation("post", routes.login);
};
export const UseRegister = () => {
  return useMutation("post", routes.register);
};
export const UseForgotPassword = () => {
  return useMutation("post", routes.forgotPassword);
};

//categories
export const UseGetCategories = () => {
  return useQuery(routes.category);
};

//file
export const UsePostFile = () => {
  return useMutation("post", routes.files);
};

export const UseRemoveLessonFile = (id) => {
  return useMutation("delete", routes.files + `/lesson/${id}`);
};

//lessonFile
export const UsePostLessonFile = () => {
  return useMutation("post", routes.lessonFiles);
};
export const UsePostLessonFileVimeo = () => {
  return useMutation("post", routes.lessonFilesVimeo);
};

export const UseUpdateLessonFile = (id) => {
  return useMutation("put", routes.lessonFiles + "/" + id);
};
//stage

export const UsePostStage = () => {
  return useMutation("post", routes.stage);
};

export const UseGetStage = (id, params, enabled) => {
  return useQuery(`${routes.stage}/${id}`, params, {
    cacheTime: 0,
    enabled,
    retry: 0,
  });
};

export const UseUpdateStageOrder = () => {
  return useMutation("put", routes.stage);
};

export const UsePatchStage = (id) => {
  return useMutation("patch", routes.stage + "/" + id);
};
// module
export const UsePatchModule = (id) => {
  return useMutation("patch", routes.module + "/" + id);
};

export const UsePostModule = () => {
  return useMutation("post", routes.module);
};

export const UseGetModule = (id, params) => {
  return useQuery(`${routes.module}/${id}`, params, {
    cacheTime: 0,
  });
};

export const UseUpdateModuleOrder = () => {
  return useMutation("put", routes.module);
};

// LESSON
export const UsePostLesson = () => {
  return useMutation("post", routes.lesson);
};
export const UsePatchLesson = (id) => {
  return useMutation("patch", routes.lesson + "/" + id);
};
export const UseUpdateLessonOrder = () => {
  return useMutation("put", routes.lesson);
};

export const UseGetLesson = (id) => {
  return useMutation("post", routes.lesson + "/get/" + id);
};

export const UseDeleteLesson = (id) => {
  return useMutation("delete", routes.lesson + "/" + id);
};

//PURCHASE
export const UsePostPurchase = () => {
  return useMutation("post", routes.purchase);
};

export const UseDeletePurchase = () => {
  return useMutation("delete", routes.purchase);
};

export const UseGetPurchaseConfigs = (id, params) => {
  return useQuery(`${routes.purchase}/checkout/${id}`, params, {
    cacheTime: 0,
    retry: 0,
  });
};

export const UseGetPurchasedCourses = () => {
  return useQuery(`${routes.purchase}/purchased-courses`);
};

// CUSTOMERS

export const UsePaymentIntent = () => {
  return useMutation("post", routes.customers);
};

export default routes;

// ADMIN

export const UseOpenStage = () => {
  return useMutation("post", routes.admin);
};
export const UseGetStatistics = () => {
  return useQuery(`${routes.admin}/statistics`);
};
export const UseGetTeachers = () => {
  return useQuery(`${routes.admin}/teachers`);
};
export const UseGetStudents = () => {
  return useQuery(`${routes.admin}/students`);
};
export const UseGetPurchases = () => {
  return useQuery(`${routes.admin}/purchases`);
};

// USER
export const UsePatchUser = (id) => {
  return useMutation("patch", routes.user + "/" + id);
};

export const useChangePassword = () => {
  return useMutation("patch", routes.user + "/change-password");
};

export const UseDeleteUser = (id) => {
  return useMutation("delete", routes.user + "/" + id);
};

export const UseGetUser = (id, params, enabled) => {
  return useQuery(routes.user + "/" + id, params, {
    enabled,
  });
};

export const UseGetSubscribedUsers = () => {
  return useQuery(routes.admin + "/subscribed-users");
};

export const UseChangeSubscription = (id) => {
  return useMutation("post", routes.admin + "/change-subscription/" + id);
};

// STATISTICS

export const UseGetStatisticsForTeacher = () => {
  return useQuery(`${routes.statistics}/teacher`);
};

// BLOG
export const UsePostBlog = () => {
  return useMutation("post", routes.blog);
};

export const UsePatchBlog = (id) => {
  return useMutation("patch", routes.blog + "/" + id);
};

export const UseDeleteBlog = (id) => {
  return useMutation("delete", routes.blog + "/" + id);
};

export const UseGetBlogs = (params, options) => {
  return useQuery(routes.blog, params, { cacheTime: 0 });
};

export const UseGetBlog = (id, params) => {
  return useQuery(routes.blog + "/" + id, params, {
    cacheTime: 0,
    retry: 0,
  });
};

// ONBOARDING
export const UseGetOnboardingForTeacher = () => {
  return useQuery(`${routes.onboarding}/teacher`);
};
export const UseUpdateOnboardingForTeacher = () => {
  return useMutation("patch", `${routes.onboarding}/teacher`);
};

// CATEGORY
export const UsePostCategory = () => {
  return useMutation("post", routes.category);
};
export const UsePatchCategory = (id) => {
  return useMutation("patch", routes.category + "/" + id);
};
export const UseGetCategory = (id, params) => {
  return useQuery(`${routes.category}/${id}`, params, {
    cacheTime: 0,
  });
};
export const UseGetAllCategories = (params) => {
  return useQuery(routes.category, params, {
    cacheTime: 0,
  });
};

export const UseDeleteCategory = (id) => {
  return useMutation("delete", routes.category + "/" + id);
};

// CART
export const UsePostCart = () => {
  return useMutation("post", routes.cart);
};

export const UseGetCart = (id, params) => {
  return useQuery(`${routes.cart}/${id}`, params, {
    cacheTime: 0,
  });
};

export const UseUpdateCartWithCoupon = (id) => {
  return useMutation("patch", routes.cart + "/" + id);
};
//TEACHER-APPLICATION
export const UseCreateTeacherApplication = () => {
  return useMutation("post", routes.teacherApplication);
};

export const GetAdminNotApprovedApplications = () => {
  return useQuery(routes.teacherApplication);
};
export const GetOneApplication = (id) => {
  return useQuery(routes.teacherApplication + "/" + id);
};

export const UseUpdateTeacherApplication = (id) => {
  return useMutation("patch", routes.teacherApplication + "/" + id);
};

export const UseGetTeachersWithoutApplication = () => {
  return useQuery(routes.teacherApplication + "/without");
};

//SEARCH
export const UseSearchByKeyword = (word, params) => {
  return useQuery(`${routes.course}/by-keyword`, params);
};

// COUPON

export const UseGetCouponByCourse = (id) => {
  return useQuery(`${routes.coupon}/by-course/${id}`, undefined, {
    retry: 0,
  });
};

export const UseGetCouponById = (id) => {
  return useQuery(`${routes.coupon}/${id}`, undefined, {
    retry: 0,
    cacheTime: 0,
  });
};

export const UseCreateCoupon = () => {
  return useMutation("post", routes.coupon);
};

export const UseUpdateCoupon = (id) => {
  return useMutation("patch", routes.coupon + "/" + id);
};

//EVENT

export const UseGetEventsByCourse = (id, enabled) => {
  return useQuery(`${routes.event}/by-course/${id}`, undefined, { enabled });
};

export const UseGetEventById = (id) => {
  return useQuery(`${routes.event}/${id}`);
};

export const UseCreateEvent = () => {
  return useMutation("post", routes.event);
};

export const UseUpdateEvent = (id) => {
  return useMutation("patch", routes.event + "/" + id);
};

export const UseDeleteEvent = (id) => {
  return useMutation("delete", routes.event + "/" + id);
};

//NEW BATCHES
export const UseGetNewBatches = (id) => {
  return useQuery(`${routes.event}/new-batch/${id}`);
};

//SUBSCRIBE
export const UseCheckSubscribtion = () => {
  return useQuery(`${routes.subscribe}/check-subscription`);
};
