import React, { useEffect } from "react";
import Sidebartoggle from "../Sidebartoggle";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Input,
  Paper,
  Popover,
  Popper,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import DarkMode from "../Darkbutton";
import SearchIcon from "@mui/icons-material/Search";
import userImg from "../../assets/icons/user.png";
import useDebounce from "../shared/useDebounce";
import { searchContent, searchInput } from "./ids";
import styles from "./navbar.module.scss";
import MainRequest from "../../hooks/main-request";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const AdminTopnav = () => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const user = useSelector((data) => data?.auth?.user);
  const [searchWindowOpen, setSearchWindowOpen] = React.useState(false);
  const [foundCourses, setFoundCourses] = React.useState([]);

  const location = useLocation();
  const keyword = new URLSearchParams(location?.search);

  const handleEventClick = (e) => {
    if (e.target.id === searchContent || e.target.id === searchInput) {
      return;
    }
    setSearchWindowOpen(false);
  };

  React.useEffect(() => {
    document.addEventListener("click", handleEventClick);
    return () => {
      document.removeEventListener("click", handleEventClick);
    };
  }, [searchWindowOpen]);

  const handleSearch = async (data) => {
    if (!data) {
      setSearchWindowOpen(false);
      return;
    }

    MainRequest.get(serverUrl + "/course/by-keyword/", {
      params: {
        search: data,
      },
    }).then((data) => {
      setFoundCourses(data?.data);
    });
  };

  React.useEffect(() => {
    if (!foundCourses.length) {
      return setSearchWindowOpen(false);
    }

    setSearchWindowOpen(true);
  }, [foundCourses]);

  const schema = yup.object().shape({
    search: yup.string(),
  });

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => setValue("search", keyword.get("key")), [keyword.get("key")]);

  const onSubmit = (data) => {
    history.push(`/courses/search?key=${data.search}`);
  };

  const debounce = useDebounce(handleSearch, 300);

  const history = useHistory();
  const searchWindow = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    handleClose();
    localStorage.removeItem("access_token");
    history.location.state = {
      redirectUrl: "/login",
    };
    history.push("/login");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const searchRef = React.useRef(null);

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow-xs">
      <Sidebartoggle />
      <Paper
        component="form"
        className="d-flex m-auto px-3"
        style={{ width: "500px" }}
        ref={searchWindow}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="search"
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <Input
              placeholder="Search..."
              className="flex flex-1 mx-8"
              disableUnderline
              fullWidth
              onChange={(e) => {
                onChange(e);
                debounce(e.target.value);
              }}
              inputProps={{
                "aria-label": "Search",
              }}
              id={searchInput}
              ref={searchRef}
              {...field}
            />
          )}
        />
        <SearchIcon />

        <Popper
          placement="bottom-start"
          anchorEl={searchWindow.current}
          open={searchWindowOpen}
        >
          <div id={searchContent} className={styles["search-content"]}>
            {searchWindowOpen &&
              foundCourses.map((f, i) => {
                return (
                  <div
                    onClick={() => {
                      searchRef.current.value = "";
                      history.push(`/courses/detailed/${f?._id}`);
                    }}
                    className={styles["search-item"]}
                  >
                    <img
                      className={styles["search-item-image"]}
                      src={serverUrl + "/files/" + f?.imageUrl?.src}
                    />
                    <div>
                      <Typography variant="h6">{f?.name}</Typography>
                      <Typography
                        className={styles["search-item-description"]}
                        variant="body2"
                      >
                        {f?.description}
                      </Typography>
                    </div>
                  </div>
                );
              })}
          </div>
        </Popper>
      </Paper>

      <div
        className="col-md-auto"
        onClick={handleAnchor}
        style={{
          height: "40px",
          width: "40px",
          marginLeft: "auto",
          cursor: "pointer",
          overflow: "hidden",
          backgroundImage: `url(${
            user?.avatar?.src
              ? serverUrl + "/files/" + user?.avatar?.src
              : userImg
          })`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: "50%",
        }}
      ></div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="container py-3">
          <p style={{ fontSize: "13px", margin: "0", color: "#a5a5a5" }}>
            Email:
          </p>
          <p>{user?.email}</p>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p style={{ fontSize: "13px", margin: "0", color: "#a5a5a5" }}>
                Role:
              </p>
              <p>{user?.role}</p>
            </div>
            <DarkMode />
          </div>

          <Button
            onClick={() => history.push("/profile")}
            variant="contained"
            color="primary"
            startIcon={<SettingsIcon />}
          >
            Profile
          </Button>
          <br />
          <br />

          <Button
            onClick={handleClick}
            variant="contained"
            color="primary"
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        </div>
      </Popover>
    </nav>
  );
};

export default AdminTopnav;
