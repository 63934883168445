import { motion } from "framer-motion";

export default function ErrorMessage({ error, fontSize = "0.8em" }) {
  return (
    <div style={{ position: "relative" }}>
      {error ? (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <p
            className="text-danger m-0"
            style={{ fontSize, position: "absolute" }}
          >
            {error}
          </p>
        </motion.div>
      ) : (
        ""
      )}
    </div>
  );
}
