import CreateLesson from "./lesson/Create-or-Delete-lesson";
import Rename from "./inputs/Rename.js";
import InfoLesson from "./lesson/Info-lesson";
import EditStage from "./stage/edit";
import CreateStage from "./stage/create";

export default function Info({ data }) {
  switch (data?.info?.type) {
    case "rename-module":
      return <Rename data={data} />;

    case "new-module":
      return <Rename data={data} />;

    case "create-lesson":
      return <CreateLesson data={data} />;

    case "info-lesson":
      return <InfoLesson data={data} />;

    case "edit-lesson":
      return <CreateLesson data={data} />;

    case "edit-stage":
      return <EditStage data={data} />;

    case "create-stage":
      return <CreateStage data={data} />;
  }
}
