import { Link, useHistory } from "react-router-dom";
import { GetCourses, UsePostCart } from "../../../hooks";
import { useSelector } from "react-redux";
import LoadingPage from "../../../components/shared/LoadingPage";
import Cookies from "js-cookie";

export default function OverviewStudent() {
  const { data, isLoading } = GetCourses();
  const history = useHistory();
  const postCart = UsePostCart();

  const isRedirect = Cookies.get("redirect");
  if (isRedirect) {
    const [course, tariff, amount] = getRedirectInformation(isRedirect);
    Cookies.remove("redirect");
    if (tariff === "free") {
      history.push(`/overview/info/${course}`);
    } else {
      postCart
        .mutateAsync({ course, tariff, amount })
        .then((d) => {
          return history.push(`/purchase/${d?.data?._id}`);
        })
        .catch((err) => console.log(err));
    }
  }

  const serverUrl = process.env.REACT_APP_API_URL;
  const user = useSelector((data) => data?.auth?.user);
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="container px-3 py-4">
      <div className="row">
        <div className="col-lg-12 mt-4">
          <div className="col-lg-12 card border-0 mt-2 rounded-10">
            <h4 className="font-xss text-grey-800 mt-3 fw-700">
              {user?.name && user?.surname
                ? "Hello, " +
                  (user?.name || "") +
                  " " +
                  (user?.surname + "!" || "")
                : "Hello!"}
            </h4>
            <p>Let’s learn something new today!</p>
          </div>
          <div className="card border-0 mt-2 rounded-10">
            <div className="card-body d-flex pt-4 px-4 pb-0">
              <h4 className="font-xss text-grey-800 mt-3 fw-700">
                Recommended courses
              </h4>
            </div>
            <div className="card-body p-0">
              <div className="row  rounded-6 m-0">
                {data?.data.map((d, index) => (
                  // Start Single Demo
                  <div
                    key={index}
                    className="col-lg-3 col-md-4 col-sm-6 p-4 rounded-0 posr"
                  >
                    <div
                      style={{ height: "350px" }}
                      className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card"
                    >
                      <div
                        style={{ height: "50%" }}
                        className="card-image w-100 mb-3"
                      >
                        <Link
                          to={`/overview/info/${d?.link}`}
                          className="video-bttn position-relative d-block"
                          style={{ height: "100%" }}
                        >
                          <img
                            src={serverUrl + "/files/" + d?.imageUrl?.src}
                            alt="course"
                            className="w-100"
                            style={{
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </Link>
                      </div>

                      <div className="card-body pt-0">
                        <div
                          className={`font-xsssss text-center fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2  ${d?.status}`}
                        >
                          {d?.category?.name}
                        </div>

                        <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                          <Link
                            to={`/overview/info/${d?.link}`}
                            className="text-dark text-grey-900"
                          >
                            {d?.name}
                          </Link>
                        </h4>
                        <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                          {d?.stages?.length} Stage
                        </h6>
                      </div>
                    </div>
                  </div>

                  // End Single Demo
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getRedirectInformation(data) {
  const param = new URLSearchParams(data);
  return [param.get("course"), param.get("type"), param.get("amount")];
}
