import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Create from "./components/Create";
import Success from "./components/Success";

export default function TeacherApplication() {
  const location = useLocation();
  const history = useHistory();

  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!location.state) {
      axios
        .get(process.env.REACT_APP_API_URL + "/user/login/access", {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "access_token"
            )}`,
          },
        })
        .catch((err) => {
          setStatus(err?.response?.data?.error);
          setLoading(false);
        });
    } else {
      setStatus(location?.state?.status);
      setLoading(false);
    }
  }, []);

  function getComponent() {
    if (status === "not-created") {
      return <Create />;
    } else if (status === "not-approved") {
      return <Success />;
    } else {
      history.goBack();
      return <div></div>;
    }
  }

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return getComponent();
}
