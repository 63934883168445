import React from "react";
import Privacy from "./pages/terms-and-privacy/Privacy";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Login from "../src/pages/auth/login";
import Signup from "../src/pages/auth/signup";
import ResetPassword from "../src/pages/auth/resetpassword";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./main.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import Auth from "./auth/Auth";
import VerifyEmailLink from "./pages/auth/verify-email-link";
import Terms from "./pages/terms-and-privacy/Terms";
import { ToastContainer } from "react-toastify";
import TeacherApplication from "./pages/teacher-application";
import Success from "./pages/teacher-application/components/Success";
import Layout from "./layout";

import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <Provider store={store}>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/signup`}
              component={Signup}
            />

            <Route path="/verify-email/:id" component={VerifyEmailLink} />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/reset-password`}
              component={ResetPassword}
            />
            <Route
              exact
              path={"https://codekaplan.com/terms"}
              component={Terms}
            />
            <Route
              exact
              path={"https://codekaplan.com/privacy"}
              component={Privacy}
            />
            <Route
              exact
              path="/teacher-application"
              component={TeacherApplication}
            />
            <Route
              exact
              path="/teacher-application-success"
              component={Success}
            />

            <Auth>
              <Layout />
            </Auth>
          </Switch>
        </Provider>
        <ToastContainer />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
