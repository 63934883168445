import { UseGetStatistics } from "../../../hooks";
import { Card, CardActionArea, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoadingPage from "../../../components/shared/LoadingPage";

export default function OverviewAdmin() {
  const history = useHistory();
  const { data, isLoading } = UseGetStatistics();

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="container px-3 py-4">
      <div className="row">
        <div className="col-lg-12 mt-4">
          <i className="ml-auto feather-command font-sm text-grey-500"> BETA</i>
          <br />
          <br />
          <h2>
            <b>Statistics for admin:</b>
          </h2>
          <br />
          <Grid spacing={3} container>
            <Grid item sm={6} md={4}>
              <Card>
                <CardActionArea
                  onClick={() => history.push("/courses")}
                  sx={{ padding: "20px", textAlign: "center" }}
                >
                  <b>Courses:</b> {data?.data?.courses}
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item sm={6} md={4}>
              <Card>
                <CardActionArea
                  onClick={() => history.push("/admin/teachers")}
                  sx={{ padding: "20px", textAlign: "center" }}
                >
                  <b>Teachers:</b> {data?.data?.teachers}
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item sm={6} md={4}>
              <Card>
                <CardActionArea
                  onClick={() => history.push("/admin/students")}
                  sx={{ padding: "20px", textAlign: "center" }}
                >
                  <b>Students:</b> {data?.data?.students}
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item sm={6} md={4}>
              <Card>
                <CardActionArea
                  onClick={() => history.push("/admin/purchases")}
                  sx={{ padding: "20px", textAlign: "center" }}
                >
                  <b>All Purchases:</b> {data?.data?.allPurchases / 100}$
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
