import { Grid } from "@mui/material";
import React from "react";
import Basic from "./basic";
import ProLive from "./pro-live";
import ProMentor from "./pro-mentor";
import SelfLearing from "./self-learning";

function Card({ data }) {
  return (
    <Grid gap="12px" className="mb-5" container>
      <Grid item xs={12} md={5}>
        <Basic id={data?._id} />
      </Grid>
      <Grid item xs={12} md={5}>
        <SelfLearing id={data?._id} />
      </Grid>
      {/* <Grid item xs={12} md={5}>
        <ProSelfLearing plan={data?.pro_advanced} />
      </Grid> */}
      <Grid item xs={12} md={5}>
        <ProMentor plan={data?.pro_mentor} />
      </Grid>
      <Grid item xs={12} md={5}>
        <ProLive
          plan={{
            monthlyPrice: data?.pro_live?.monthly?.monthlyPayment,
            price: data?.pro_live?.full_upfront?.total,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default Card;
