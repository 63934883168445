import { Controller, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { Button, TextField } from "@mui/material";
import { useChangePassword } from "../../../hooks";

export default function Password() {
  const changePassword = useChangePassword();
  const history = useHistory();
  const schema = yup.object().shape({
    oldPassword: yup.string().required("Old password is required field"),
    newPassword: yup
      .string()
      .required("New password is required field!")
      .min(6),
    newPasswordConfirm: yup
      .string()
      .required("Confirm new password is required field")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match!"),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
  });

  const onSubmit = (data) => {
    changePassword
      .mutateAsync(data)
      .then(() => {
        localStorage.removeItem("access_token");
        history.location.state = {
          redirectUrl: "/login",
        };
        history.push("/login");
      })
      .catch((err) => {
        setError("oldPassword", {
          message: err?.response?.data?.message,
        });
      });
  };

  return (
    <div className="middle-sidebar-bottom bg-lightblue theme-dark-bg">
      <div className="middle-sidebar-left">
        <div className="middle-wrap">
          <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
            <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-lg">
              <Link to="/settings" className="d-inline-block mt-2">
                <i className="ti-arrow-left font-sm text-white"></i>
              </Link>
              <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
                Change Password
              </h4>
            </div>
            <div className="card-body p-lg-5 p-4 w-100 border-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-600 font-xssss">
                        Current Password
                      </label>
                      <Controller
                        name="oldPassword"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="password"
                            name="comment-name"
                            className="form-control my-2"
                            error={errors.oldPassword}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <ErrorMessage error={errors?.oldPassword?.message} />
                  </div>

                  <div className="col-lg-12 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-600 font-xssss">
                        Change Password
                      </label>
                      <Controller
                        name="newPassword"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="password"
                            error={errors?.newPassword}
                            name="comment-name"
                            className="form-control my-2"
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <ErrorMessage error={errors?.newPassword?.message} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-gorup">
                      <label className="mont-font fw-600 font-xssss">
                        Confirm Change Password
                      </label>
                      <Controller
                        name="newPasswordConfirm"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="password"
                            name="comment-name"
                            className="form-control my-2"
                            error={errors?.newPasswordConfirm}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <ErrorMessage error={errors?.newPasswordConfirm?.message} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-0">
                    <Button
                      variant="contained"
                      className="bg-current text-center text-white font-xsss mt-3 fw-600 p-3 w175 rounded-lg d-inline-block"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-sidebar-right scroll-bar">
        <div className="middle-sidebar-right-content"></div>
      </div>
    </div>
  );
}
