import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import Modal from "./Alert";
import styles from "./lesson.module.scss";
import ReactSelect from "react-select";
import { MakePlans } from "../utils/make-plans-object";

export default function CreateOrEditLesson({ data }) {
  useEffect(() => {
    if (data.info.type === "edit-lesson") {
      data.lesson.resetLesson({
        name: data.info?.lesson?.data?.name,
        description: data?.info?.lesson?.data?.description,
        status: data.info?.lesson?.data?.status,
        plans: MakePlans(data?.info?.lesson?.data?.plans),
      });
    } else {
      data.lesson.resetLesson({
        name: "",
        description: "",
        status: "",
      });
    }
    return () =>
      data.lesson.resetLesson({ name: "", description: "", status: "" });
  }, []);
  const defaultValues = useMemo(() => ({ open: false, id: "", type: "" }), []);

  const [modal, setModal] = useState(defaultValues);
  const handleCloseAlert = () => {
    data.setInfo(data.defaultValues);
  };

  const options = useMemo(() => {
    return [
      {
        label: "Pro Self learning purchase",
        value: "pro_advanced-purchase",
      },

      {
        label: "Pro Self learning monthly",
        value: "pro_advanced-subscribe",
      },

      {
        label: "Pro Mentor purchase",
        value: "pro_mentor-purchase",
      },
      {
        label: "Pro Mentor monthly",
        value: "pro_mentor-subscribe",
      },
      {
        label: "Pro Live purchase",
        value: "pro_live-full_upfront",
      },
      {
        label: "Pro Live monthly",
        value: "pro_live-monthly",
      },
    ];
  }, []);

  return (
    <>
      <h1>
        {data.info.type === "create-lesson" ? "New lesson" : "Edit lesson"}
      </h1>
      <form
        onSubmit={data.lesson.handleSubmitLesson((submitData) =>
          data.onSubmit(submitData, data.info.type)
        )}
      >
        <label className="mt-5" htmlFor="lesson_name">
          Name*
        </label>

        <Controller
          name="name"
          control={data.lesson.control}
          render={({ field }) => (
            <input
              id="lesson_name"
              className="form-control form_control"
              {...field}
            />
          )}
        />

        <label className="mt-4" htmlFor="lesson_description">
          Description
        </label>
        <Controller
          name="description"
          control={data.lesson.control}
          render={({ field }) => (
            <textarea
              id="lesson_description"
              className="form-control form_control"
              {...field}
            />
          )}
        />

        {data?.info?.lesson?.data?.files?.map((f, id) => {
          if (f?.type === "video") {
            return (
              <div className={styles["video-wrapper"]}>
                <div
                  key={id}
                  id="frame-wrapper"
                  style={{ height: "450px" }}
                  dangerouslySetInnerHTML={{ __html: f.src }}
                ></div>
                <div className="d-flex mt-2 align-items-center  justify-content-center">
                  <Button
                    onClick={() =>
                      setModal({
                        open: true,
                        id: {
                          file: f._id,
                          lesson: data?.info?.lesson?.data?._id,
                        },
                        type: "update-video",
                      })
                    }
                    className="border-0 mr-2"
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() =>
                      setModal({
                        open: true,
                        id: f._id,
                        type: "delete-video",
                      })
                    }
                    className="border-0 bg-danger"
                  >
                    Delete
                  </Button>
                </div>
              </div>
            );
          }
        })}

        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px 0px",
          }}
        >
          <FormControl fullWidth>
            <FormLabel id="demo-radio-buttons-group-label">Plan</FormLabel>

            <Controller
              name="status"
              control={data.lesson.control}
              render={({ field }) => (
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  {...field}
                >
                  <Box
                    component="div"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <FormControlLabel
                      value="free"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                      value="paid"
                      control={<Radio />}
                      label="Paid"
                    />
                  </Box>
                </RadioGroup>
              )}
            />
          </FormControl>

          {/* <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={null}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={personName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Box>

        <Controller
          name="plans"
          control={data.lesson.control}
          render={({ field: { onChange, ...rest } }) => (
            <ReactSelect
              isMulti={true}
              name="plans"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              {...rest}
              onChange={(choices) => {
                onChange(choices);
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                }),
              }}
            />
          )}
        />

        <div className="d-flex align-items-center justify-content-between">
          <Button className="mt-2 border-0" type="submit">
            {data.info.type === "create-lesson" ? "Create" : "Save"}
          </Button>

          {data.info.type === "edit-lesson" && (
            <Button
              onClick={() =>
                setModal({
                  open: true,
                  id: data.info.lesson.id,
                  type: "lesson",
                })
              }
              className="mt-2 border-0 bg-danger"
            >
              Delete lesson
            </Button>
          )}
        </div>
      </form>
      {modal.open && (
        <Modal
          defaultValues={defaultValues}
          setModal={setModal}
          modal={modal}
          refetch={data.stage.refetch}
          handleCloseAlert={handleCloseAlert}
        />
      )}
    </>
  );
}
