export default function StageInfo({ stageData, handleClick }) {
  return (
    <div className="col-7 p-3 modules">
      <h1 className="mt-4 fw-600">{stageData?.data?.name}</h1>

      <p className="modules__text">{stageData?.data?.description}</p>
      {stageData?.data?.modules.map((d, id) => (
        <div
          onClick={() => handleClick(d?._id, stageData?.data?._id)}
          className="modules__list"
          key={id}
        >
          <div className="modules__list-item">
            <h3 className="fw-600">{d?.name}</h3>
            <i className="ti-arrow-right"></i>
          </div>
        </div>
      ))}
    </div>
  );
}
