import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import Loading from "./Loading";
import Buy from "./Buy";
import StageInfo from "./Stage-info";
import NoModule from "./No-module";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";

export default function Stages({
  data,
  setStage,
  stage,
  setDialog,
  stageLoading,
  stageData,
  id,
  handleClick,
}) {
  const user = useSelector((data) => data?.auth?.user);
  return (
    <div className="row m-0 stage-right__row">
      <div className="col-5 px-2 stage-list">
        {data?.stages?.map((d, i) => (
          <div
            key={i}
            onClick={() => setStage(d?._id)}
            className={`stage-item p-4 ${
              d?._id === stage ? "selected-item" : ""
            }`}
          >
            <div className="d-flex justify-content-between">
              <h1 className="fw-600">Stage-{i + 1}</h1>
            </div>
            <h1 className="mb-2 fw-600">{d?.name}</h1>
            <p className="stage-item__text">{d?.description}</p>
            {user.role === "admin" ? (
              <Button
                onClick={() =>
                  setDialog({
                    open: true,
                    selectedStage: d?._id,
                  })
                }
                variant="contained"
                color="primary"
              >
                Open this stage for student
              </Button>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>

      {stageLoading ? (
        <Loading />
      ) : !stageData ? (
        <Buy courseId={id} />
      ) : stageData?.data?.modules?.length ? (
        <StageInfo handleClick={handleClick} stageData={stageData} />
      ) : (
        <NoModule />
      )}
    </div>
  );
}
