import React from "react";

import done from "../../images/done.png";
import notDone from "../../images/notDone.png";

export default function Todo({ number, text, isDone }) {
  return (
    <div
      style={{ borderBottom: "2px solid #969696", padding: "0 10px 0 10px" }}
      className="d-flex justify-content-between align-items-center mb-4"
    >
      <h4 style={{ fontWeight: "600" }}>
        {number}. {text}
      </h4>
      {isDone ? (
        <img style={{ height: "20px", width: "20px" }} src={done} alt="done" />
      ) : (
        <img
          style={{ height: "20px", width: "20px" }}
          src={notDone}
          alt="done"
        />
      )}
    </div>
  );
}
