import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { UseGetCourse } from "../../../../hooks";
import style from "./overview.module.scss";
import LoadingPage from "../../../../components/shared/LoadingPage";
import Accordion from "./accordion";
import NewBatch from "./newBatch";
import coursePicture from "../../../../assets/course-picture.png";
import Card from "./cards";

export default function OverviewInfo() {
  const { id } = useParams();
  const { data, isLoading } = UseGetCourse(id);

  const serverUrl = process.env.REACT_APP_API_URL;
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Fragment>
      <Breadcrumbs className="ml-2 mb-2" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/overview">
          Overview
        </Link>

        <Typography color="text.primary">{data?.data?.name}</Typography>
      </Breadcrumbs>

      <div className="container">
        <motion.h1
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          className="text-center fw-900"
        >
          {data?.data?.name}
        </motion.h1>

        <div className={style["detailed-course-wrapper"]}>
          <div className={style["main-page"]}>
            <div className={style["main"]}>
              <img
                className={style["main-image"]}
                src={
                  data?.data?.imageUrl?.src
                    ? `${serverUrl}/files/${data?.data?.imageUrl?.src}`
                    : data?.data?.imageUrl?.src || coursePicture
                }
              />
            </div>

            <div className={style["about-course"]}>
              <Box
                component="div"
                dangerouslySetInnerHTML={{
                  __html: data?.data?.demo?.aboutCourse,
                }}
              />

              <Accordion stages={data?.data?.stages} />
            </div>

            <div className={style["instructor"]}>
              <Typography
                variant="h4"
                className="my-3 text-center"
                fontWeight={900}
              >
                Instructor of course
              </Typography>
              <div className={style["inside"]}>
                <img
                  className={style["avatar"]}
                  src={`${serverUrl}/files/${data?.data?.creator?.avatar?.src}`}
                />
                <Typography
                  variant="h5"
                  className="my-3 text-center"
                  fontWeight={600}
                >
                  {`${data?.data?.creator?.name} ${data?.data?.creator?.surname}`}
                </Typography>
              </div>
            </div>

            <Typography
              className="text-center my-4"
              variant="h4"
              fontWeight={700}
            >
              Choose Your Plan
            </Typography>

            <Typography
              style={{ textAlign: "center", display: "block" }}
              className="text-center my-4"
              variant="p"
            >
              We offer a range of payment plans designed to meet your needs and
              budget. Each plan comes with a unique set of features and
              benefits, so you can choose either the one time payment or monthly
              installment whichever works best for you.
            </Typography>

            <NewBatch id={data?.data?._id} />

            <Card data={data?.data} />
          </div>
          <div className={style["card-sticky"]}>
            <div className={style["price-card"]}>
              <Box
                component="div"
                className={style["video-frame"]}
                dangerouslySetInnerHTML={{
                  __html: data?.data?.demo?.videoLink,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
