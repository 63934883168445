import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import { useContext } from "react";
import context from "../utils/Context";

export default function List() {
  const { data, dispatch, state } = useContext(context);
  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        padding: "10px 0",
      }}
    >
      {data?.modules?.map((m) => (
        <Accordion
          sx={{ width: "100%", backgroundColor: "#304256" }}
          expanded={m._id === state?.module}
        >
          <AccordionSummary
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: "module",
                payload: m._id,
              });
            }}
            expandIcon={<ExpandMoreIcon color="warning" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color="#fff">{m?.name}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            {m?.lessons?.map((l, li) => {
              if (
                !state.lesson &&
                m._id === state?.module &&
                li === 0 &&
                l?._id !== state.lesson
              ) {
                dispatch({ type: "lesson", payload: l?._id });
              }

              return (
                <Card
                  sx={{
                    padding: "15px",
                    cursor: "pointer",
                    backgroundColor: state.lesson === l?._id ? "#fff" : "#eee",
                    border:
                      state.lesson === l?._id
                        ? "2px solid #bd8b2b"
                        : "2px solid #fff",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (l?._id !== state.lesson) {
                      dispatch({ type: "lesson", payload: l?._id });
                    }
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    {l?.name}
                    {!l?.access && <LockIcon />}
                  </div>
                </Card>
              );
            })}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
