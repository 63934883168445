import { Box, FormControl, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  UseCheckSubscribtion,
  UseGetCourse,
  UsePostCart,
  UseSelectCourse,
} from "../../../../../../hooks";
import ButtonComponent from "../button";
import ListItem from "../ListItem";

const features = [
  {
    item: "All courses advanced lessons",
    disabled: false,
  },
  {
    item: "Exercises for practice",
    disabled: false,
  },
  {
    item: "Home work assignments",
    disabled: false,
  },
  {
    item: "Resources to download",
    disabled: false,
  },
  {
    item: "4 times hour mentor live one-on-one sessions per month",
    disabled: true,
  },
  {
    item: "Live group lessons",
    disabled: true,
  },
];
const amount = 19;

export default function ProSelfLearing() {
  const history = useHistory();

  const { id } = useParams();
  const { data } = UseCheckSubscribtion();
  const postCart = UsePostCart();
  const selectCourse = UseSelectCourse();

  const { data: course } = UseGetCourse(id);

  const handleMutate = (type) => {
    if (type === "buy") {
      const data = {
        course: id,
        tariff: "self_learning-general",
        amount,
      };

      postCart
        .mutateAsync(data)
        .then((d) => {
          return history.push(`/purchase/${d?.data?._id}`);
        })
        .catch((err) => console.log(err));
    } else {
      selectCourse.mutateAsync({ course: course?.data?._id }).then(() => {
        return history.push(`/courses/detailed/${id}`);
      });
    }
  };

  return (
    <Paper
      style={{ padding: "25px 15px", minHeight: "530px", position: "relative" }}
    >
      <Box variant="div" className="card-head">
        <Typography variant="h5" fontWeight={700}>
          Self-learning
        </Typography>

        <FormControl fullWidth>
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            disabled
            value="Monthly Installment"
          />
        </FormControl>

        <Typography color="#3434ff" variant="h4" fontWeight={700}>
          ${amount}
        </Typography>
      </Box>

      <Box variant="div" className="card-body">
        {features.map((f, index) => {
          return <ListItem key={index} disabled={f.disabled} item={f.item} />;
        })}
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: "20px",
          width: "90%",
        }}
      >
        {data?.data?.subscribes ? (
          <ButtonComponent onClick={() => handleMutate("start")}>
            Start now
          </ButtonComponent>
        ) : (
          <ButtonComponent onClick={() => handleMutate("buy")}>
            Buy now
          </ButtonComponent>
        )}
      </Box>
    </Paper>
  );
}
