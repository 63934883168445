import { useState, useMemo } from "react";
import Create from "./Create";
import Info from "./Info";
import Edit from "./Edit";
import { useParams } from "react-router-dom";
import { UseGetCourse } from "../../../../../hooks";

export default function Coupon() {
  const defaultValues = useMemo(
    () => ({
      page: "info",
      coupon: null,
    }),
    []
  );

  const { id } = useParams();
  const { data } = UseGetCourse(id);
  const [location, setLocation] = useState(defaultValues);

  switch (location.page) {
    case "edit":
      return (
        <Edit
          setLocation={setLocation}
          location={location}
          defaultValues={defaultValues}
        />
      );
    case "new":
      return (
        <Create
          id={data?.data?._id}
          setLocation={setLocation}
          defaultValues={defaultValues}
        />
      );
    default:
      return (
        <Info
          id={data?.data?._id}
          setLocation={setLocation}
          defaultValues={defaultValues}
        />
      );
  }
}
