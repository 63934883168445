import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import notFoundImage from "./assets/not-found.png";

class Notfound extends Component {
  render() {
    return (
      <div
        style={{ height: "100%" }}
        className="card border-0 text-center d-block"
      >
        <img
          src={notFoundImage}
          alt="icon"
          className="w200 mb-4 ml-auto mr-auto "
        />
        <h1 className="fw-700 text-grey-900 display4-size display4-md-size">
          Oops! It looks like you're lost.
        </h1>
        <p className="text-grey-500 font-xss">
          The page you're looking for isn't available. Try to search again or
          use the go to.
        </p>
        <Link
          to="/"
          className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3"
        >
          Home Page
        </Link>
      </div>
    );
  }
}

export default Notfound;
