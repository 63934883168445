import React from "react";

const Success = () => {
  return (
    <div>
      <h1>Your email is successfully verified!</h1>
      <a href="/login">back to login</a>
    </div>
  );
};
export default Success;
