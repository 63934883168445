import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { CircularProgressWithLabel } from "../../../../../../components/shared/CircularPercentage";

function Alert({ alert, progress }) {
  return (
    <Dialog className="dialog" open={alert} fullWidth maxWidth="sm">
      <AppBar
        position="static"
        style={{ backgroundColor: "#222e3c" }}
        elevation={0}
      >
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            Loading....
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="d-flex align-items-center justify-content-center my-3">
        <CircularProgressWithLabel value={progress} />
      </div>
    </Dialog>
  );
}

export default Alert;
