import React from "react";
import { useTimer } from "react-timer-hook";
import Time from "./time";
import styles from "./timer.module.scss";

function MyTimer({ expiryTimestamp }) {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
  });

  return (
    <div className={styles.wrapper}>
      <Time time={days} />:
      <Time time={hours} />:
      <Time time={minutes} />:
      <Time time={seconds} />
    </div>
  );
}

export default function Timer({ date }) {
  return (
    <div>
      <MyTimer expiryTimestamp={date} />
    </div>
  );
}
