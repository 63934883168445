import React, { useEffect } from "react";
import styles from "./login.module.scss";
import WhiteLogo from "../../../assets/logo/logo";
import { Link } from "react-router-dom";
import { UseLogin } from "../../../hooks";
import { useForm } from "react-hook-form";
import { authenticate } from "../../../redux/auth/authSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import ErrorMessage from "../../../components/shared/ErrorMessage";

export default function Login() {
  const history = useHistory();
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/user/login/access", {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "access_token"
          )}`,
        },
      })
      .then((data) => {
        history.push("/");
      });
  }, []);

  const login = UseLogin();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required().min(6),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const serverUrl = process.env.REACT_APP_API_URL;

  async function onSubmit(data) {
    axios
      .post(`${serverUrl}/user/login`, {
        ...data,
      })
      .then((data) => {
        localStorage.setItem("access_token", data?.data?.access_token);
        dispatch(authenticate(data?.data?.user));
        window.location.reload();
      })
      .catch((err) => {
        if (
          err?.response?.data?.error === "not-created" ||
          err?.response?.data?.error === "not-approved"
        ) {
          window.localStorage.setItem(
            "access_token",
            err.response?.data?.access_token
          );

          history.push("/teacher-application", {
            user: err?.response?.data.user,
            status: err?.response?.data?.error,
          });
          window.location.reload();
        }
        setError("responseError", { message: err?.response?.data?.message });
      });
  }

  return (
    <>
      <div className={styles.parentLogin}>
        <div className={styles.loginLeft}>
          <a className={styles.logo} href="https://codekaplan.com">
            <WhiteLogo />
          </a>
        </div>
        <div className={styles.loginRight}>
          <h1>Log in</h1>
          <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className={styles.loginInput}>
                <input
                  className={styles.input}
                  {...register("email")}
                  type="email"
                  placeholder="Email"
                />
              </div>
              <ErrorMessage error={errors?.email?.message} />
            </div>

            <div>
              <div className={styles.loginInput}>
                <input
                  className={styles.input}
                  {...register("password")}
                  type="password"
                  placeholder="Password"
                />
              </div>
              <div style={{ position: "relative" }}>
                {!errors?.responseError?.message && (
                  <ErrorMessage error={errors?.password?.message} />
                )}
                <ErrorMessage error={errors?.responseError?.message} />
              </div>
            </div>

            <motion.div
              initial={{ opacity: 0, scale: 0.6 }}
              animate={{ opacity: 1, scale: 1 }}
            >
              <button
                className={`${styles.loginBtn} ${
                  !isValid && styles.loginBtnDisabled
                }`}
                type="submit"
              >
                Log In
              </button>
            </motion.div>
          </form>
          <Link to="/reset-password">Forgot password?</Link>
          <p>
            Don’t have an account yet? <Link to="/signup">Sign Up</Link>
          </p>
        </div>
      </div>
    </>
  );
}
