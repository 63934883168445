import { useMemo, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { GetOwnCourses, UseGetEventsByCourse } from "../../hooks";
import Select from "./select";
import Modal from "./modal";

export default function Schedule() {
  const { data: request } = GetOwnCourses(false);
  const [modal, setModal] = useState({ open: false, eventId: null });

  const [selectedCourse, setSelectedCourse] = useState(null);

  const { data } = UseGetEventsByCourse(selectedCourse, !!selectedCourse);

  const courses = useMemo(() => {
    if (request?.data) {
      return [
        ...request.data?.enrolledCourses,
        ...request.data?.purchased,
        ...request.data?.subscribed,
      ];
    }
  }, [request]);

  const events = useMemo(
    () =>
      data?.data?.map((eventCalendar) => {
        if (eventCalendar.repeating) {
          const end = moment(eventCalendar.endTime)
            .add(1, "day")
            .format("YYYY-MM-DD");
          const endRecur = moment(eventCalendar.endDate)
            .add(1, "day")
            .format("YYYY-MM-DD");
          return {
            title: eventCalendar.name,
            start: eventCalendar.startDate,
            end: end,
            description: eventCalendar.description,
            isNewBatch: eventCalendar.isNewBatch,
            location: eventCalendar.location,
            daysOfWeek: eventCalendar?.repeating?.repeatingDays,
            startRecur: eventCalendar.startDate,
            endRecur: endRecur,
            _id: eventCalendar._id,
          };
        } else {
          const end = moment(eventCalendar.endTime)
            .add(1, "day")
            .format("YYYY-MM-DD");

          return {
            title: eventCalendar.name,
            start: eventCalendar.startDate,
            end: end,
            description: eventCalendar.description,
            isNewBatch: eventCalendar.isNewBatch,
            location: eventCalendar.location,
            _id: eventCalendar._id,
          };
        }
      }),
    [data]
  );

  const calendarOptions = {
    // events: store.events.length ? store.events : [],
    events,
    /*
          Enable dragging and resizing event
          ? Docs: https://fullcalendar.io/docs/editable
        */
    // editable: true,
    // eventDidMount: (info) => {
    //   const end = moment(info.event.end)
    //     .subtract(1, "day")
    //     .format("YYYY-MM-DD");
    //   const endEl = info.el.querySelectorAll(".fc-end");
    //   endEl.forEach((el) => el.setAttribute("data-date", end));
    //   info.el.classList.add("include-end");
    // },
    /*
          Enable resizing event from start
          ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
        */
    // eventResizableFromStart: true,

    /*
          Automatically scroll the scroll-containers during event drag-and-drop and date selecting
          ? Docs: https://fullcalendar.io/docs/dragScroll
        */
    // dragScroll: true,

    /*
          Max number of events within a given day
          ? Docs: https://fullcalendar.io/docs/dayMaxEvents
        */
    dayMaxEvents: 2,
    droppable: true,
    // eventDrop: handleEventDrop,
    eventClick: handleEventClick,
    // dateClick: handleDateClick,
  };

  function handleEventClick(info) {
    setModal({ open: true, eventId: info.event.extendedProps._id });
  }

  return (
    <>
      <Select
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        courses={courses}
      />
      <FullCalendar
        height={600}
        headerToolbar={{
          end: "today prev,next",
          start: "title",
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        {...calendarOptions}
      />
      {modal.open && <Modal modal={modal} setModal={setModal} />}
    </>
  );
}
