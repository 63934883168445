import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Defaultsettings = () => {
  const user = useSelector((data) => data?.auth?.user);

  return (
    <div className="middle-sidebar-bottom theme-dark-bg h-100">
      <div className="middle-sidebar-left">
        <div className="middle-wrap">
          <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
            <div className="card-body p-lg-5 p-4 w-100 border-0">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="mb-4 font-lg fw-700 mont-font mb-5">
                    Settings
                  </h4>
                  <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">
                    Account
                  </div>
                  <ul className="list-inline mb-4">
                    <li className="list-inline-item d-block border-bottom mr-0">
                      <Link
                        to="/settings/account-information"
                        className="pt-2 pb-2 d-flex"
                      >
                        <i className="btn-round-md bg-primary-gradiant text-white feather-home font-md mr-3"></i>
                        <h4 className="fw-600 font-xssss mb-0 mt-3">
                          Profile Details
                        </h4>
                        <i className="ti-angle-right font-xsss text-grey-500 ml-auto mt-3"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item d-block  mr-0">
                      <Link
                        to="/settings/password"
                        className="pt-2 pb-2 d-flex"
                      >
                        <i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md mr-3"></i>{" "}
                        <h4 className="fw-600 font-xssss mb-0 mt-3">
                          Password
                        </h4>
                        <i className="ti-angle-right font-xsss text-grey-500 ml-auto mt-3"></i>
                      </Link>
                    </li>
                  </ul>
                  {user?.role === "student" && (
                    <>
                      <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">
                        Payment
                      </div>
                      <ul className="list-inline mb-4">
                        <li className="list-inline-item d-block border-bottom mr-0">
                          <Link
                            to="/settings/cards"
                            className="pt-2 pb-2 d-flex"
                          >
                            <i className="btn-round-md bg-mini-gradiant text-white feather-credit-card font-md mr-3"></i>{" "}
                            <h4 className="fw-600 font-xssss mb-0 mt-3">
                              My Cards
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ml-auto mt-3"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block border-bottom mr-0">
                          <Link
                            to="/settings/pricing-plans"
                            className="pt-2 pb-2 d-flex"
                          >
                            <i className="btn-round-md bg-gold-gradiant text-white feather-credit-card font-md mr-3"></i>{" "}
                            <h4 className="fw-600 font-xssss mb-0 mt-3">
                              Pricing plans
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ml-auto mt-3"></i>
                          </Link>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-sidebar-right scroll-bar">
        <div className="middle-sidebar-right-content"></div>
      </div>
    </div>
  );
};

export default Defaultsettings;
