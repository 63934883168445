import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { UseGetPurchases } from "../../../hooks";
import { Typography } from "@mui/material";

export default function PurchasesList() {
  const { data } = UseGetPurchases();

  return (
    <div className="container">
      <Typography component="h1" sx={{ fontSize: "1.5rem" }}>
        Purchases list:
      </Typography>
      <br />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Surname</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((d, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="center">{d?.user?.name || "-"}</TableCell>
                <TableCell align="center">{d?.user?.surname || "-"}</TableCell>
                <TableCell align="center">{d?.user?.email}</TableCell>
                <TableCell align="center">{d?.amount / 100 || "-"}$</TableCell>
                <TableCell align="center">
                  {new Date(d?.date).toLocaleDateString() +
                    " " +
                    new Date(d?.date).toLocaleTimeString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
