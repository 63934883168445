import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { GetOwnCourses } from "../../../hooks";
import LoadingPage from "../../../components/shared/LoadingPage";

export default function MyCourses() {
  const { data, isLoading, refetch, isFetching } = GetOwnCourses();

  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading || isFetching) {
    return <LoadingPage />;
  }

  return (
    <Fragment>
      <div className="container px-3 py-4">
        <div className="row">
          <div className="col-lg-12 mt-4">
            <i className="ml-auto feather-command font-sm text-grey-500">
              {" "}
              BETA
            </i>
            <div className="card border-0 mt-2 rounded-10">
              <div className="card-body d-flex pt-4 px-4 pb-0">
                <h4 className="font-xss text-grey-800 mt-3 fw-700">
                  Course List
                </h4>
              </div>
              <div className="card-body p-0">
                {/* <h1>Subscribed</h1> */}
                <div className="row  rounded-6 m-0">
                  {data?.data?.subscribed?.map((d, index) => (
                    // Start Single Demo
                    <div
                      key={index}
                      className="col-lg-3 col-md-4 col-sm-6 p-4 rounded-0 posr"
                    >
                      <div
                        style={{ height: "350px" }}
                        className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card"
                      >
                        <div
                          style={{ height: "50%" }}
                          className="card-image w-100 mb-3"
                        >
                          <Link
                            to={`/courses/detailed/${d?.link}`}
                            className="video-bttn position-relative d-block"
                            style={{ height: "100%" }}
                          >
                            <img
                              src={serverUrl + "/files/" + d?.imageUrl?.src}
                              alt="course"
                              className="w-100"
                              style={{
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </Link>
                        </div>

                        <div className="card-body pt-0">
                          <div
                            className={`font-xsssss text-center fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2  ${d?.status}`}
                          >
                            {d?.category?.name}
                          </div>

                          <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                            <Link
                              to={`/courses/detailed/${d?.link}`}
                              className="text-dark text-grey-900"
                            >
                              {d?.name}
                            </Link>
                          </h4>
                          <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                            {d?.stages?.length} Stage
                          </h6>
                        </div>
                      </div>
                    </div>

                    // End Single Demo
                  ))}
                </div>
                <div className="row  rounded-6 m-0">
                  {/* <h1>Purchased</h1> */}
                  {data?.data?.purchased?.map((d, index) => (
                    // Start Single Demo
                    <div
                      key={index}
                      className="col-lg-3 col-md-4 col-sm-6 p-4 rounded-0 posr"
                    >
                      <div
                        style={{ height: "350px" }}
                        className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card"
                      >
                        <div
                          style={{ height: "50%" }}
                          className="card-image w-100 mb-3"
                        >
                          <Link
                            to={`/courses/detailed/${d?.link}`}
                            className="video-bttn position-relative d-block"
                            style={{ height: "100%" }}
                          >
                            <img
                              src={serverUrl + "/files/" + d?.imageUrl?.src}
                              alt="course"
                              className="w-100"
                              style={{
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </Link>
                        </div>

                        <div className="card-body pt-0">
                          <div
                            className={`font-xsssss text-center fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2  ${d?.status}`}
                          >
                            {d?.category?.name}
                          </div>

                          <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                            <Link
                              to={`/courses/detailed/${d?.link}`}
                              className="text-dark text-grey-900"
                            >
                              {d?.name}
                            </Link>
                          </h4>
                          <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                            {d?.stages?.length} Stage
                          </h6>
                        </div>
                      </div>
                    </div>

                    // End Single Demo
                  ))}
                </div>
                <div className="row  rounded-6 m-0">
                  {/* <h1>Enrolled</h1> */}
                  {data?.data?.enrolledCourses?.map((d, index) => (
                    // Start Single Demo
                    <div
                      key={index}
                      className="col-lg-3 col-md-4 col-sm-6 p-4 rounded-0 posr"
                    >
                      <div
                        style={{ height: "350px" }}
                        className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card"
                      >
                        <div
                          style={{ height: "50%" }}
                          className="card-image w-100 mb-3"
                        >
                          <Link
                            to={`/courses/detailed/${d?.link}`}
                            className="video-bttn position-relative d-block"
                            style={{ height: "100%" }}
                          >
                            <img
                              src={serverUrl + "/files/" + d?.imageUrl?.src}
                              alt="course"
                              className="w-100"
                              style={{
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </Link>
                        </div>

                        <div className="card-body pt-0">
                          <div
                            className={`font-xsssss text-center fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2  ${d?.status}`}
                          >
                            {d?.category?.name}
                          </div>

                          <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                            <Link
                              to={`/courses/detailed/${d?.link}`}
                              className="text-dark text-grey-900"
                            >
                              {d?.name}
                            </Link>
                          </h4>
                          <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                            {d?.stages?.length} Stage
                          </h6>
                        </div>
                      </div>
                    </div>

                    // End Single Demo
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
