import * as React from "react";
import { useSelector } from "react-redux";
import TeacherOnboarding from "./teacher";
import StudentOnboarding from "./student";

export default function Onboarding() {
  const user = useSelector((data) => data?.auth?.user);

  function getOnboardingPage() {
    switch (user.role) {
      case "teacher":
        return <TeacherOnboarding />;
      case "student":
        return <StudentOnboarding />;
      default:
        return null;
    }
  }

  return getOnboardingPage();
}
