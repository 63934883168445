import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import ListIcon from "@mui/icons-material/List";
import WorkIcon from "@mui/icons-material/Work";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

function Adminsidebar() {
  var elements = document.querySelectorAll(".has-droupdown > a");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  const user = useSelector((data) => data?.auth?.user);

  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark scroll-bar my-sidebar"
      id="accordionSidebar"
    >
      <div
        className="sidebar-brand d-flex align-items-start justify-content-start"
        href="/admin"
      >
        <span className="d-inline-block fredoka-font ls-3 fw-600 text-success font-xl logo-text mb-0 text-capitalize lh-1">
          <img width="100%" src="/assets/images/Logo.svg" alt="" />
        </span>
        <button className="ms-auto mt-2 d-lg-none" id="sidebarClose">
          <i className="ti-close text-white font-sm text-grey-100 "></i>
        </button>
      </div>

      <div className="sidebar-heading"></div>

      <li className="nav-item">
        {user.role === "teacher" && (
          <>
            <NavLink className="navi-link" to="/onboarding">
              <i className="font-sm feather-briefcase mr-3 text-grey-500"></i>
              Onboarding
            </NavLink>
            {/* <NavLink
              to="/settings"
              className="navi-link nav-content-bttn open-font h-auto pt-2 pb-2"
            >
              <i className="font-sm feather-settings mr-3 text-grey-500"></i>
              Settings
            </NavLink> */}
          </>
        )}
        {(user.role === "student" ||
          user.role === "admin" ||
          user.role === "teacher") && (
          <NavLink
            className="navi-link nav-content-bttn open-font h-auto pt-2 pb-2"
            to="/overview"
          >
            <i className="font-sm feather-grid mr-3 text-grey-500"></i>
            Dashboard
            <span className="badge badge-primary px-1 mb-px bg-highlight dark:bg-highlight-dark rounded text-link dark:text-link-dark uppercase font-bold tracking-wide text-xs whitespace-nowrap">
              BETA
            </span>
          </NavLink>
        )}
        {user.role === "student" && (
          <>
            {/* <NavLink
              className="navi-link nav-content-bttn open-font h-auto pt-2 pb-2"
              to="/overview"
            >
              <i className="font-sm feather-film mr-3 text-grey-500"></i>
              All Courses
            </NavLink> */}
            <NavLink
              className="navi-link nav-content-bttn open-font h-auto pt-2 pb-2"
              to="/my-courses"
            >
              <i className="font-sm feather-video mr-3 text-grey-500"></i>
              My courses
            </NavLink>
            <NavLink
              to="/settings"
              className="navi-link nav-content-bttn open-font h-auto pt-2 pb-2"
            >
              <i className="font-sm feather-settings mr-3 text-grey-500"></i>
              Settings
            </NavLink>
            <NavLink
              to="/schedule"
              className="navi-link nav-content-bttn open-font h-auto pt-2 pb-2"
            >
              <i className="font-sm feather-calendar mr-3 text-grey-500"></i>
              Schedule
            </NavLink>

            <div className="sidebar-card d-lg-flex mt-auto p-4">
              <p className="text-center mb-3 font-xsss text-grey-100 fw-500">
                Become a <b>pro</b> <br /> Get more <b>features</b>
              </p>
              <NavLink
                to="/settings/pricing-plans"
                className="bg-success text-white rounded-xl btn-cart w125 d-inline-block text-center font-xsssss p-3 fw-700 ls-3"
              >
                PRICING
              </NavLink>
            </div>
          </>
        )}
        {(user.role === "teacher" || user.role === "admin") && (
          <>
            <NavLink className="navi-link" to="/courses">
              <i className="font-sm feather-video mr-3 text-grey-500"></i>
              Courses
            </NavLink>
            <NavLink
              to="/settings"
              className="navi-link nav-content-bttn open-font h-auto pt-2 pb-2"
            >
              <i className="font-sm feather-settings mr-3 text-grey-500"></i>
              Settings
            </NavLink>
          </>
        )}
        {user.role === "admin" && (
          <>
            <NavLink className="navi-link" to="/users">
              <PersonIcon className="mr-2" />
              Users
            </NavLink>

            <NavLink className="navi-link" to="/blog">
              <ArticleIcon className="mr-2" />
              Blog
            </NavLink>
            <NavLink className="navi-link" to="/category">
              <ListIcon className="mr-2" />
              Categories
            </NavLink>
            <NavLink className="navi-link" to="/teacher-application-admin">
              <WorkIcon className="mr-2" />
              Teacher applications
            </NavLink>
            <NavLink className="navi-link" to="/teachers-without-app-admin">
              <PersonOutlineIcon className="mr-2" />
              Teachers without application
            </NavLink>
          </>
        )}
      </li>
    </ul>
  );
}

export default Adminsidebar;
