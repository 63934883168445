import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import ErrorMessage from "../../../../../../../components/shared/ErrorMessage";
import {
  UsePatchModule,
  UsePostModule,
  UseUpdateOnboardingForTeacher,
} from "../../../../../../../hooks";

export default function Rename({ data }) {
  const postModule = UsePostModule();
  const patchModule = UsePatchModule(data?.info?.renameModule?.moduleId);
  const updateOnboarding = UseUpdateOnboardingForTeacher();

  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data.info.type === "rename-module") {
      setValue("name", data?.info?.renameModule?.title);
    }
    return () => reset();
  }, [data]);

  const onSubmit = (d) => {
    if (data.info.type === "new-module") {
      postModule
        .mutateAsync({ ...d, stage: data.stage.selectedStage })
        .then(() => {
          if (!data?.onboarding?.data?.clickCreateModule) {
            updateOnboarding.mutateAsync({
              clickCreateModule: true,
            });
          }
          data?.stage?.refetch();
          data?.setInfo(data?.defaultValues);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      patchModule
        .mutateAsync(d)
        .then(() => {
          data?.stage?.refetch();
          data?.setInfo(data?.defaultValues);
        })
        .catch((err) => {
          alert(err);
          window.location.reload();
        });
    }
  };

  return (
    <>
      <h1>
        {data.info.type === "rename-module" ? "Edit module name" : "New module"}
      </h1>
      <form
        // onSubmit={data.module.handleSubmit((submitData) =>
        //   data.onSubmit(submitData, data.info.type)
        // )}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <input className="form-control form_control" {...field} />
          )}
        />

        <ErrorMessage error={errors?.name?.message} />

        <Button
          variant="contained"
          color="primary"
          className="mt-5 border-0"
          type="submit"
          disabled={!isValid}
        >
          {data.info.type === "rename-module" ? "Save" : "Create"}
        </Button>
      </form>
    </>
  );
}
