import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { UseGetTeachers } from "../../../hooks";
import { Avatar, Typography } from "@mui/material";

export default function TeachersList() {
  const { data } = UseGetTeachers();
  const serverUrl = process.env.REACT_APP_API_URL;

  return (
    <div className="container">
      <Typography component="h1" sx={{ fontSize: "1.5rem" }}>
        Teachers list:
      </Typography>
      <br />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell align="center">Avatar</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Surname</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((d, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="center">
                  <Avatar
                    alt={d?.name + " " + d?.surname}
                    src={serverUrl + "/files/" + d?.avatar?.src}
                  />
                </TableCell>
                <TableCell align="center">{d?.name || "-"}</TableCell>
                <TableCell align="center">{d?.surname || "-"}</TableCell>
                <TableCell align="center">{d?.email}</TableCell>
                <TableCell align="center">{d?.role}</TableCell>
                <TableCell align="center">{d?.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
