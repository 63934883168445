import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import styles from "./settings.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { UseGetUser, UsePatchUser, UsePostFile } from "../../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { authenticate } from "../../../../redux/auth/authSlice";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function BadgeAvatar() {
  const user = useSelector((data) => data?.auth?.user);
  const postFile = UsePostFile();
  const patchUser = UsePatchUser(user?._id);

  const [enabled, setEnabled] = React.useState(false);

  const { data } = UseGetUser(user?._id, undefined, enabled);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (data?.data) {
      dispatch(authenticate(data?.data));
    }
  }, [data?.data]);

  const handleUploadImage = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("file", e.target?.files[0], e.target?.files[0]?.name);

    postFile
      .mutateAsync(formData)
      .then((data) => {
        patchUser.mutateAsync({ avatar: data.data?._id }).then(() => {
          setEnabled(true);
        });
      })
      .catch((err) => console.log(err));
  };
  const serverUrl = process.env.REACT_APP_API_URL;

  return (
    <Stack direction="row" spacing={2}>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        <Box component="div" className={`rounded-circle ${styles.wrapper}`}>
          <label for="image" className={styles.add}>
            <AddIcon />
          </label>
          <Avatar
            sx={{ width: 100, height: 100 }}
            alt={user?.name + " " + user?.surname}
            src={serverUrl + "/files/" + user?.avatar?.src}
          />
          <input
            id="image"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={handleUploadImage}
          />
        </Box>
      </StyledBadge>
    </Stack>
  );
}
