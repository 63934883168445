import { Typography } from "@material-ui/core";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { UseGetCart } from "../../../hooks";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./components/PaymentForm";
import "./style.css";
import LoadingPage from "../../../components/shared/LoadingPage";

export default function Purchase() {
  const { id } = useParams();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const { data, isError, isLoading, refetch, isFetching } = UseGetCart(id);

  if (isLoading || isFetching) {
    return <LoadingPage />;
  }

  return (
    <Fragment>
      <div className="container">
        {/* <Breadcrumbs className="ml-2 mb-2" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to={`/cart/${id}`}>
                  Cart
                </Link>
                <Typography color="text.primary">Checkout</Typography>
              </Breadcrumbs> */}
        <Typography align="start" className="mb-5" variant="h4">
          Checkout
        </Typography>

        <div className="cart-container">
          <Elements stripe={stripePromise}>
            <PaymentForm
              refetch={refetch}
              isError={isError}
              data={data?.data}
            />
          </Elements>
        </div>
      </div>
    </Fragment>
  );
}
