import { Alert, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Dialog from "./Alert";

export default function Success() {
  const [alertOpen, setAlertOpen] = useState(false);
  useEffect(() => {
    setAlertOpen(true);
  }, []);
  return (
    <div className="container">
      <Typography variant="h4" className="my-4">
        Profile submitted
      </Typography>
      <Typography variant="h5" className="mb-3">
        Review in progress
      </Typography>
      <Alert severity="success">
        <Typography variant="h6">
          Thank you for completing registration!
        </Typography>
        <Typography variant="body1">
          We've received your application and are currently reviewing it. You
          will receive an email with the status of your application within 5
          business days.
        </Typography>
      </Alert>
      <Dialog alertOpen={alertOpen} setAlertOpen={setAlertOpen} />
    </div>
  );
}
