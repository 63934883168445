import { useEffect } from "react";
import { Button } from "react-bootstrap";

export default function InputPdf({ setAction, data }) {
  const handleUploadFile = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("file", e.target?.files[0], e.target?.files[0]?.name);
    formData.append("type", "pdf");
    formData.append("lessonId", data.info?.lesson?.id);
    data?.onSubmit(formData, "post-pdf");
  };

  return (
    <div>
      <div className="form-group m30">
        <div className="file-upload-wrapper" data-text="Select your file!">
          <input
            name="file-upload-field"
            type="file"
            className="file-upload-field"
            id="input-file"
            onChange={handleUploadFile}
            accept="application/pdf"
          />
          <label
            htmlFor="input-file"
            className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed theme-black-bg"
          >
            <i className="ti-cloud-down large-icon me-3 d-block"></i>
            <span className="js-fileName">Click to upload pdf file</span>
          </label>
        </div>
      </div>

      <Button
        onClick={() => setAction("type")}
        className="mt-2 ml-2 bg-danger border-0"
        type="submit"
      >
        Back
      </Button>
    </div>
  );
}
