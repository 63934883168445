import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { Button, FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { UseGetPurchasedCourses, UsePostCart } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function Accountinfo() {
  const { data } = UseGetPurchasedCourses();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const postCart = UsePostCart();
  const history = useHistory();
  useEffect(() => {
    data?.data && setSelectedCourse(data?.data[0]);
  }, [data?.data]);

  const schema = yup.object().shape({
    course: yup.object().shape({
      tariff: yup.string().required(),
      // amount: yup.number().required(),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (
      !data?.course ||
      getPlanDisableOrNot(selectedCourse?.type, data?.course?.tariff)
    ) {
      return alert("error");
    }

    postCart
      .mutateAsync({ ...data.course, course: selectedCourse?.course?.link })
      .then((d) => {
        return history.push(`/purchase/${d?.data?._id}`);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="middle-sidebar-bottom bg-lightblue theme-dark-bg">
      <div className="middle-sidebar-left">
        <div className="middle-wrap">
          <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
            <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-lg">
              <Link to="/settings" className="d-inline-block mt-2">
                <i className="ti-arrow-left font-sm text-white"></i>
              </Link>
              <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
                Pricing Plans
              </h4>
            </div>

            <div className="container mt-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Course</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCourse}
                  onChange={(data) => setSelectedCourse(data?.target?.value)}
                >
                  {data?.data?.map((d) => (
                    <MenuItem value={d}>{d?.course?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="card-body p-lg-5 p-4 w-100 border-0 ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="current-plan">
                  <h4>Current Plan</h4>
                  <h5>{selectedCourse?.type}</h5>
                </div>
                <div className="change-plan mt-4">
                  <h4>Change Plan</h4>

                  {selectedCourse?.course?.pro_advanced?.monthlyPrice ? (
                    <div>
                      <Controller
                        name="course"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            type="radio"
                            id="proAdvancedMonthly"
                            disabled={getPlanDisableOrNot(
                              selectedCourse?.type,
                              "pro_advanced-subscribe"
                            )}
                            name="pricing_plans"
                            onChange={() =>
                              onChange({
                                tariff: "pro_advanced-subscribe",
                                amount:
                                  selectedCourse?.course?.pro_advanced
                                    ?.monthlyPrice,
                              })
                            }
                            value="Pro Advanced Monthly Payment"
                          />
                        )}
                      />
                      <label
                        className="ml-2 cursor-pointer"
                        for="proAdvancedMonthly"
                      >
                        Pro Advanced Monthly Payment
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  {selectedCourse?.course?.pro_advanced?.price ? (
                    <div>
                      <Controller
                        name="course"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            type="radio"
                            id="proAdvancedFullUpfront"
                            disabled={getPlanDisableOrNot(
                              selectedCourse?.type,
                              "pro_advanced-purchase"
                            )}
                            name="pricing_plans"
                            onChange={() =>
                              onChange({
                                tariff: "pro_advanced-purchase",
                                amount:
                                  selectedCourse?.course?.pro_advanced?.price,
                              })
                            }
                            value="Pro Advanced Full Upfront"
                          />
                        )}
                      />
                      <label
                        className="ml-2 cursor-pointer"
                        for="proAdvancedFullUpfront"
                      >
                        Pro Advanced Full Upfront
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  {selectedCourse?.course?.pro_mentor?.monthlyPrice ? (
                    <div>
                      <Controller
                        name="course"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            type="radio"
                            id="proMentorSubscribe"
                            name="pricing_plans"
                            value="Pro Mentor Subscribe"
                            disabled={getPlanDisableOrNot(
                              selectedCourse?.type,
                              "pro_mentor-subscribe"
                            )}
                            onChange={() =>
                              onChange({
                                tariff: "pro_mentor-subscribe",
                                amount:
                                  selectedCourse?.course?.pro_mentor
                                    ?.monthlyPrice,
                              })
                            }
                          />
                        )}
                      />
                      <label
                        className="ml-2 cursor-pointer"
                        for="proMentorSubscribe"
                      >
                        Pro Mentor Monthly Payment
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  {selectedCourse?.course?.pro_mentor?.price ? (
                    <div>
                      <Controller
                        name="course"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            type="radio"
                            id="proMentorFullUpfront"
                            name="pricing_plans"
                            value="Pro Mentor Full Upfront"
                            disabled={getPlanDisableOrNot(
                              selectedCourse?.type,
                              "pro_mentor-purchase"
                            )}
                            onChange={() =>
                              onChange({
                                tariff: "pro_mentor-purchase",
                                amount:
                                  selectedCourse?.course?.pro_mentor?.price,
                              })
                            }
                          />
                        )}
                      />
                      <label
                        className="ml-2 cursor-pointer"
                        for="proMentorFullUpfront"
                      >
                        Pro Mentor Full Upfront
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  {selectedCourse?.course?.pro_live?.full_upfront?.status ? (
                    <div>
                      <Controller
                        name="course"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            type="radio"
                            id="proLiveFullUpfront"
                            name="pricing_plans"
                            value="pro_live-full_upfront"
                            disabled={getPlanDisableOrNot(
                              selectedCourse?.type,
                              "pro_live-full_upfront"
                            )}
                            onChange={() =>
                              onChange({
                                tariff: "pro_live-full_upfront",
                                amount:
                                  selectedCourse?.course?.pro_live?.full_upfront
                                    ?.priceForPurchase,
                              })
                            }
                          />
                        )}
                      />
                      <label
                        className="ml-2 cursor-pointer"
                        for="proLiveFullUpfront"
                      >
                        Pro Live Full Upfront
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  {selectedCourse?.course?.pro_live?.monthly?.status ? (
                    <div>
                      <Controller
                        name="course"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            type="radio"
                            id="proLiveMonthly"
                            name="pricing_plans"
                            value="pro_live-monthly"
                            disabled={getPlanDisableOrNot(
                              selectedCourse?.type,
                              "pro_live-monthly"
                            )}
                            onChange={() =>
                              onChange({
                                tariff: "pro_live-monthly",
                                amount:
                                  selectedCourse?.course?.pro_live?.monthly
                                    ?.priceForPurchase,
                              })
                            }
                          />
                        )}
                      />
                      <label
                        className="ml-2 cursor-pointer"
                        for="proLiveMonthly"
                      >
                        Pro Live Monthly
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* {selectedCourse?.course?.pro_live?.partial_monthly
                    ?.status && (
                    <div>
                      <Controller
                        name="course"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            type="radio"
                            id="proLivePartialMonthly"
                            name="pricing_plans"
                            value="pro_live-partial_monthly"
                            disabled={
                              selectedCourse?.type ===
                              "pro_live-partial_monthly"
                            }
                            onChange={() =>
                              onChange({
                                tariff: "pro_live-partial_monthly",
                                amount:
                                  selectedCourse?.course?.pro_live
                                    ?.partial_monthly?.priceForPurchase,
                              })
                            }
                          />
                        )}
                      />
                      <label
                        className="ml-2 cursor-pointer"
                        for="proLivePartialMonthly"
                      >
                        Pro Live Partial Monthly
                      </label>
                    </div>
                  )}

                  {selectedCourse?.course?.pro_live?.start_job?.status && (
                    <div>
                      <Controller
                        name="course"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            type="radio"
                            id="pro_live-start_job"
                            name="pricing_plans"
                            value="pro_live-start_job"
                            disabled={
                              selectedCourse?.type === "pro_live-start_job"
                            }
                            onChange={() =>
                              onChange({
                                tariff: "pro_live-start_job",
                                amount:
                                  selectedCourse?.course?.pro_live?.start_job
                                    ?.priceForPurchase,
                              })
                            }
                          />
                        )}
                      />
                      <label
                        className="ml-2 cursor-pointer"
                        for="pro_live-start_job"
                      >
                        Pro Live Start Job
                      </label>
                    </div>
                  )} */}
                </div>
                <div className="col-lg-12">
                  <Button
                    variant="contained"
                    type="submit"
                    className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-sidebar-right scroll-bar">
        <div className="middle-sidebar-right-content"></div>
      </div>
    </div>
  );
}

const plans = {
  "pro_advanced-subscribe": ["pro_advanced-subscribe"],
  "pro_advanced-purchase": ["pro_advanced-purchase", "pro_advanced-subscribe"],
  "pro_mentor-subscribe": ["pro_mentor-subscribe"],
  "pro_mentor-purchase": ["pro_mentor-purchase", "pro_mentor-subscribe"],
  "pro_live-monthly": ["pro_live-monthly"],
  "pro_live-full_upfront": ["pro_live-full_upfront", "pro_live-monthly"],
};

function getPlanDisableOrNot(plan, inputType) {
  return plans[plan]?.includes(inputType);
}
