import * as yup from "yup";

const live_plan = {
  status: yup.boolean().default(false),
  type: yup.string(),
  monthlyPayment: yup.number().when("status", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
  numberOfMonth: yup.number().when("status", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
  priceBeforeStart: yup.number().when("status", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
  priceAfterJob: yup.number().when("status", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
  priceBeforeEnd: yup.number().when("status", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
};

const live_plan_full_upfront = {
  status: yup.boolean().default(false),
  type: yup.string(),
  price: yup.number().when("status", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      ),
  }),
};

const live_plan_monthly = {
  status: yup.boolean().default(false),
  type: yup.string(),
  monthlyPayment: yup.number().when("status", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      ),
  }),
  numberOfMonth: yup.number().when("status", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      ),
  }),
};

const plan = {
  selected: yup.boolean().default(false),

  type: yup.string().when("selected", {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),

  price: yup.number().when("selected", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),

  monthlyPrice: yup.number().when("selected", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
  sessions: yup.number().when("selected", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
  student_count: yup.number().when("selected", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
};

const pro_advanced = {
  selected: yup.boolean().default(false),

  price: yup.number().when("selected", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
  monthlyPrice: yup.number().when("selected", {
    is: true,
    then: yup.number().required().typeError("Amount must be a number"),
    otherwise: yup.number(),
  }),
};

export const schema = yup.object().shape({
  isFree: yup.boolean(),
  pro_advanced: yup.object().shape(pro_advanced),
  pro_mentor: yup.object().shape(plan),
  pro_live: yup.object().shape({
    full_upfront: yup.object().shape(live_plan_full_upfront),
    monthly: yup.object().shape(live_plan_monthly),
    partial_monthly: yup.object().shape(live_plan),
    start_job: yup.object().shape(live_plan),
  }),
});
