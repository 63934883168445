import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GetAdminNotApprovedApplications } from "../../../hooks";
import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LoadingPage from "../../../components/shared/LoadingPage";

export default function Blog() {
  const { data, isFetching, isLoading } = GetAdminNotApprovedApplications();
  const history = useHistory();

  if (isLoading || isFetching) {
    return <LoadingPage />;
  }

  return (
    <div className="container">
      <Box
        component="div"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography component="h1" sx={{ fontSize: "1.5rem", fontWeight: 900 }}>
          Teacher applications
        </Typography>
      </Box>
      <br />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Surname</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.data?.map((d, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={() =>
                  history.push(`/teacher-application-admin/${d?._id}`)
                }
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>

                <TableCell align="center">
                  <Typography>{d?.user?.name}</Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography>{d?.user?.surname}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
