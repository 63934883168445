import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function PdfViewer({ fileUrl }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const serverUrl = process.env.REACT_APP_API_URL;

  return (
    <div
      style={{
        border: "1px solid rgba(0, 0, 0, 0.3)",
        height: "700px",
      }}
      className="viewer"
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <Viewer
          fileUrl={serverUrl + "/files" + fileUrl}
          plugins={[defaultLayoutPluginInstance]}
        ></Viewer>
      </Worker>
    </div>
  );
}

export default PdfViewer;
