import { useEffect, useState } from "react";

import {
  Breadcrumbs,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseDeleteUser, UsePatchUser } from "../../../hooks";
import Avatar from "./components/Avatar";
import { authenticate } from "../../../redux/auth/authSlice";
import Alert from "./components/Alert";
import { Link, useHistory } from "react-router-dom";

export default function Profile() {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const user = useSelector((data) => data?.auth?.user);
  const patchUser = UsePatchUser(user?._id);
  const deleteUser = UseDeleteUser(user?._id);
  const history = useHistory();

  const schema = yup.object().shape({
    name: yup.string(),
    surname: yup.string(),
  });

  const deleteFunc = () => {
    deleteUser.mutateAsync().then(() => {
      localStorage.removeItem("access_token");
      history.location.state = {
        redirectUrl: "/login",
      };
      history.push("/login");
    });
  };

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: user?.name || "",
      surname: user?.surname || "",
    },
  });

  useEffect(() => {
    setValue("name", user?.name);
    setValue("surname", user?.surname);
  }, []);

  const onSubmit = (data) => {
    patchUser.mutateAsync(data).then((data) => {
      dispatch(authenticate(data?.data));
    });
  };

  return (
    <>
      <div className="container px-3 py-4">
        <Breadcrumbs className="ml-2 mb-2" aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/settings">
            Settings
          </Link>

          <Typography color="text.primary">Profile settings</Typography>
        </Breadcrumbs>
        <div className="row">
          <div className="col-lg-12 mt-4">
            <i className="ml-auto feather-command font-sm text-grey-500">
              {" "}
              BETA
            </i>
            <br />

            <h2>
              <b>Profile:</b>
            </h2>
            <br />
            <div className="d-flex justify-content-center mb-4">
              <Avatar />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid spacing={3} container>
                <Grid item sm={12} md={6}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="First Name"
                        error={!!errors?.name}
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage error={errors?.name?.message} />
                </Grid>

                <Grid item sm={12} md={6}>
                  <Controller
                    name="surname"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Last Name"
                        error={!!errors?.surname}
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage error={errors?.surname?.message} />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Email"
                    value={user?.email}
                  />
                  <ErrorMessage error={errors?.surname?.message} />
                </Grid>
              </Grid>
              <br />
              <div className="d-flex justify-content-between align-items-center">
                <Button type="submit" variant="contained">
                  Submit
                </Button>

                <Button
                  className="bg-danger text-white"
                  onClick={() => setAlert(true)}
                  variant="contained"
                >
                  Delete Account
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Alert alert={alert} setAlert={setAlert} deleteFunc={deleteFunc} />
    </>
  );
}
