import axios from "axios";
import { Component } from "react";
import Cookies from "js-cookie";

import { LoadingIcon } from "../assets/loadingIcon";
import { bindActionCreators } from "@reduxjs/toolkit";
import { setUserData } from "../redux/auth/authSlice";
import { connect } from "react-redux";
import Signup from "../pages/auth/signup";

class Auth extends Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: false,
      loading: true,
    };
  }

  getAccessToken = () => {
    return `Bearer ${window.localStorage.getItem("access_token")}`;
  };

  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_URL + "/user/login/access", {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "access_token"
          )}`,
        },
      })
      .then((data) => {
        if (!data?.data?.user) {
          this.props.history.push("/login");
        }
        this.props.setUserData(data?.data?.user);
        this.setState({ isAuthenticated: true, loading: false });
      })
      .catch((err) => {
        this.setState({ isAuthenticated: false, loading: false });
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingIcon />
        </div>
      );
    }

    if (this.state.isAuthenticated) {
      return <>{this.props.children} </>;
    } else {
      const searchParams = new URLSearchParams(this.props.location.search);
      const course = searchParams.get("course");
      const type = searchParams.get("type");
      if (type && course) {
        Cookies.set("redirect", this.props.location.search);
      }

      return <Signup />;
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserData,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
