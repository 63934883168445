import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UsePostCart } from "../../../../../../hooks";
import ButtonComponent from "../button";
import ListItem from "../ListItem";

const features = [
  {
    item: "Advanced video lessons",
    disabled: false,
  },
  {
    item: "Exercises for practice",
    disabled: false,
  },
  {
    item: "Home work assignments",
    disabled: false,
  },
  {
    item: "Resources to download",
    disabled: false,
  },
  {
    item: "Live group lessons",
    disabled: false,
  },
];
const planNames = {
  monthly: { name: "pro_live-monthly", value: "monthlyPrice" },
  oneTime: { name: "pro_live-full_upfront", value: "price" },
};

export default function ProLive({ plan }) {
  const history = useHistory();
  const defaultValue =
    (plan?.price && planNames.oneTime.name) ||
    (plan?.monthlyPrice && planNames.monthly.name) ||
    null;

  const prices = useMemo(
    () => ({
      [planNames.oneTime.name]: plan && plan[planNames.oneTime.value],
      [planNames.monthly.name]: plan && plan[planNames.monthly.value],
    }),
    []
  );

  const { id } = useParams();

  const [type, setType] = useState(defaultValue);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const postCart = UsePostCart();

  const handleMutate = () => {
    const data = {
      course: id,
      tariff: type,
      amount: prices[type],
    };

    postCart
      .mutateAsync(data)
      .then((d) => {
        return history.push(`/purchase/${d?.data?._id}`);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Paper
      style={{ padding: "25px 15px", minHeight: "530px", position: "relative" }}
    >
      <Box variant="div" className="card-head">
        <Typography variant="h5" fontWeight={700}>
          Pro live group
        </Typography>

        <Typography color="#3434ff" variant="body1">
          Self-Learning + Live lessons
        </Typography>

        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Plan"
            onChange={handleChange}
            variant="filled"
          >
            <MenuItem
              disabled={!prices[planNames.oneTime.name]}
              value={planNames.oneTime.name}
            >
              One-time Full Upfront
            </MenuItem>
            <MenuItem
              disabled={!prices[planNames.monthly.name]}
              value={planNames.monthly.name}
            >
              Monthly Installment
            </MenuItem>
          </Select>
        </FormControl>

        <Typography color="#3434ff" variant="h4" fontWeight={700}>
          ${prices[type]}
        </Typography>
      </Box>

      <Box variant="div" className="card-body">
        {features.map((f) => {
          return <ListItem disabled={f.disabled} item={f.item} />;
        })}
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: "20px",
          width: "90%",
        }}
      >
        <ButtonComponent onClick={handleMutate} disabled={!type}>
          Buy now
        </ButtonComponent>
      </Box>
    </Paper>
  );
}
