import React from "react";
import { useSelector } from "react-redux";

import OverviewAdmin from "./admin";
import OverviewStudent from "./student";
import OverviewTeacher from "./teacher";

export default function Overview() {
  const user = useSelector((data) => data?.auth?.user);
  switch (user?.role) {
    case "student":
      return <OverviewStudent />;

    case "admin":
      return <OverviewAdmin />;
    case "teacher":
      return <OverviewTeacher />;
  }
}
