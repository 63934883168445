import React from "react";
import Todo from "./Todo";

export default function NewCourse({ data }) {
  return (
    <div style={{ height: "220px" }} className="mt-5">
      <Todo text="Go to Courses page" number="1" isDone={data?.goCourse} />
      <Todo
        text="Click New Course button"
        number="2"
        isDone={data?.clickNewCourse}
      />
      <Todo
        text="Fill out the required fields of the form"
        number="3"
        isDone={data?.clickNewCourseButton}
      />
      <Todo
        text=" Click the Create button"
        number="4"
        isDone={data?.clickNewCourseButton}
      />
    </div>
  );
}
