import styles from "./timer.module.scss";

function getTime(time) {
  if (time < 10) {
    return `0${time}`;
  }
  return time;
}
export default function Time({ time }) {
  return (
    <div className={styles.time}>
      <div className={styles.top}>
        <div className={styles.left}></div>
        <div className={styles.right}></div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.left}></div>
        <div className={styles.right}></div>
      </div>
      <span>{getTime(time)}</span>
    </div>
  );
}
