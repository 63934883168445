import { yupResolver } from "@hookform/resolvers/yup";
import {
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import ErrorMessage from "../../../../components/shared/ErrorMessage";
import { UseCreateCourseLink } from "../../../../hooks";

const defaultValue = { open: false, id: null };

function UseCreateLinkForCourse({ refetch }) {
  const [modal, setModal] = useState(defaultValue);
  const closeModal = () => {
    setModal(defaultValue);
  };
  const openModal = (id) => {
    setModal({
      open: true,
      id,
    });
  };

  const schema = yup.object().shape({
    link: yup.string().required(),
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      link: "",
    },
  });

  const createCourseLink = UseCreateCourseLink(modal.id);

  function onSubmit(data) {
    createCourseLink
      .mutateAsync({ link: data.link })
      .then((d) => {
        closeModal();
        refetch();
      })
      .catch(() =>
        setError("link", { message: "This link is already taken!" })
      );
  }

  const component = (
    <Dialog className="dialog" open={modal.open} fullWidth maxWidth="sm">
      <AppBar
        position="static"
        style={{ backgroundColor: "#222e3c" }}
        elevation={0}
      >
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="white">
            <label style={{ cursor: "pointer" }} htmlFor="link">
              Please create link for course!
            </label>
          </Typography>
        </Toolbar>
      </AppBar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <FormControl className="mb-4" fullWidth>
            <Controller
              control={control}
              name="link"
              render={({ field }) => (
                <div className="d-flex align-items-end">
                  <label
                    style={{ cursor: "pointer", margin: 0 }}
                    htmlFor="link"
                  >
                    https://codekaplan.com/courses/
                  </label>
                  <input
                    id="link"
                    {...field}
                    style={{
                      border: "none",
                      backgroundColor: "#eee",
                      width: "100%",
                    }}
                  />
                </div>
              )}
            />
            <ErrorMessage error={errors?.link?.message} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <div className="pl-16">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setValue("link", "");
                closeModal();
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </div>

          <div className="pl-16">
            <Button
              // onClick={() => console.log(errors)}
              type="submit"
              variant="contained"
            >
              Confirm
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
  return [component, openModal];
}

export { UseCreateLinkForCourse };
