import React from "react";

const BadRequest = () => {
  return (
    <div>
      <h1>Your account already verified!</h1>
      <a href="/login">back to login</a>
    </div>
  );
};
export default BadRequest;
